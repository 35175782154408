import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import timeReducer from "./timeReducer";

const pujaInitialState = "Notification";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "selectedPujas",
    "userProfile",
    "token",
    "selectedProducts",
    "offlineAddCustomer",
    "email",
    "time",
  ],
};

const selectedPujasReducer = (state = pujaInitialState, action) => {
  switch (action.type) {
    case "UPDATE_SELECTED_PUJAS":
      return action.payload;
    case "LOGOUT_USER":
      return pujaInitialState;
    default:
      return state;
  }
};

const initialProducts = [];

const selectedProductsReducer = (state = initialProducts, action) => {
  switch (action.type) {
    case "UPDATE_SELECTED_PRODUCTS":
      // Check if product with the same skuId already exists
      const productExists = state.find(
        (product) => product?.skuId === action.payload.skuId
      );
      console.log(productExists);

      // If the product exists, update it; otherwise, add the new product
      if (productExists) {
        return state?.map((product) =>
          product.skuId === action.payload.skuId
            ? { ...product, ...action.payload }
            : product
        );
      } else {
        return [...state, action.payload];
      }
    case "RESET_SELECTED_PRODUCTS":
      return initialProducts;
    case "LOGOUT_USER":
      return initialProducts;
    case "UPDATE_PRODUCT_DISCOUNT":
      return state.map((product, idx) =>
        idx === action.payload.index
          ? {
              ...product,
              discount: action.payload.discount,
              final_price: action.payload.finalPrice,
            }
          : product
      );
    case "REMOVE_SELECTED_PRODUCT":
      return state.filter((product) => product.skuId !== action.payload);

    default:
      return state;
  }
};

const initialState = {};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_PROFILE":
      return action.payload;
    case "LOGOUT_USER":
      return initialState;
    default:
      return state;
  }
};

const OfflineAddCustomer = {};

const OfflineAddCustomerReducer = (state = OfflineAddCustomer, action) => {
  switch (action.type) {
    case "OFFLINE_ADD_CUSTOMER":
      return action.payload;
    case "LOGOUT_USER":
      return OfflineAddCustomer;
    default:
      return state;
  }
};

const tokenInitialState = {
  token: null,
};

const tokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "UPDATE_TOKEN":
      return { ...state, token: action.payload };
    case "LOGOUT_USER":
      return { token: null }; // Clear token on logout
    default:
      return state;
  }
};
const initialLocationState = {
  selectedCity: localStorage.getItem("selectedCity") || null,
  city: localStorage.getItem("city") || null,
};

const cityReducer = (state = initialLocationState, action) => {
  switch (action.type) {
    case "UPDATE_SELECTED_CITY":
      return {
        ...state,
        selectedCity: action.payload.selectedCity,
        city: action.payload.city,
      };
    default:
      return state;
  }
};

const initialStatelanguage = {
  language: localStorage.getItem("language") || "en",
};

const languageReducer = (state = initialStatelanguage, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

const emailInitialState = "";

const emailReducer = (state = emailInitialState, action) => {
  switch (action.type) {
    case "UPDATE_EMAIL":
      return action.payload;
    case "LOGOUT_USER":
      return emailInitialState;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  selectedProducts: selectedProductsReducer,
  selectedPujas: selectedPujasReducer,
  userProfile: userProfileReducer,
  token: tokenReducer,
  location: cityReducer,
  language: languageReducer,
  offlineAddCustomer: OfflineAddCustomerReducer,
  email: emailReducer,
  time: timeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
