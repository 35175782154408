import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import CategoryImage from "../Categories/CategoryImage.png";
import { Avatar } from "antd";

const COLORS = ["#00C49F", "#0088FE", "#FFBB28", "#FF8042"];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const StockManagementCards = ({
  productsDetailsData,
  topSellingProducts,
  stockStatusData,
}) => {
  console.log(topSellingProducts);
  console.log(productsDetailsData);
  console.log(stockStatusData);

  const formattedData = topSellingProducts?.map((product) => ({
    categoryName: product.categoryName,
    percent: parseFloat(product.percentage) / 100,
  }));

  return (
    <div className='stock-management-cards'>
      <div className='card-product-details'>
        <h3 className='stockM-Head'>Product Details</h3>
        <div className='list-of-products'>
          <div className='StockMout-of-stock d-flex'>
            <p>Out of stock products</p>{" "}
            <p>{productsDetailsData?.outOfStockCount || 0}</p>
          </div>
          <div className='StockM-totalCategories d-flex'>
            Total Categories{" "}
            <span>{productsDetailsData?.categoriesCount || 0}</span>
          </div>
          <div className='stockM-totalProducts d-flex'>
            Total Products{" "}
            <span>{productsDetailsData?.productsCount || 0}</span>
          </div>
        </div>
      </div>
      <div className='StockMcard-top-selling'>
        <h3 className='StockMcard-head'>Top Selling Categories/Products</h3>
        <div className='stockM-pie-container'>
          {formattedData?.length > 0 ? (
            <>
              <ResponsiveContainer width='50%' height={160}>
                <PieChart className='stockM-PieChart'>
                  <Pie
                    data={formattedData}
                    cx='50%'
                    cy='50%'
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={"90%"}
                    fill='#8884d8'
                    dataKey='percent'>
                    {formattedData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                        className='StockM-PieData'
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div className='stockM-legend'>
                {formattedData?.map((entry, index) => (
                  <div key={`legend-${index}`} className='stockM-legend-item'>
                    <span
                      className='stockM-legend-color'
                      style={{
                        backgroundColor: COLORS[index % COLORS.length],
                      }}></span>
                    <span className='stockM-legend-name'>
                      {entry.categoryName}
                    </span>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div
              className=''
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}>
              <h6>No data found</h6>
            </div>
          )}
        </div>
      </div>
      <div className='StockM-emergency-require-items'>
        <h3>Emergency Require items</h3>
        <div className='require-items-container'>
          {stockStatusData?.length > 0 &&
            stockStatusData?.map((item, index) => (
              <div className='require-item'>
                <div className='left-section'>
                  <div className='image-container'>
                    {item?.productImage ? (
                      <Avatar
                        src={item?.productImage}
                        size={40}
                        shape='square'
                      />
                    ) : (
                      <Avatar
                        shape='square'
                        size={40}
                        style={{
                          backgroundColor: "#6750A4",
                          fontWeight: 600,
                          fontSize: 17,
                        }}>
                        {item?.productName?.length > 0 &&
                          item?.productName[0]?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    )}
                  </div>
                  <p>{item?.productName ? item?.productName : "-"}</p>
                </div>
                <p>
                  {item?.stockStatus === "Low in Stock" ? (
                    <span className='low-stock-status'>
                      {item?.stockStatus}
                    </span>
                  ) : (
                    <span className='StockM-status'>{item?.stockStatus}</span>
                  )}
                </p>
              </div>
            ))}
        </div>
        {stockStatusData?.length > 0 ? (
          ""
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60%",
            }}>
            <p>No data found</p>
          </div>
        )}
        {/* <a href='#' className='StockM-see-more-btn'>
          See more
        </a> */}
      </div>
    </div>
  );
};
//StockManagementCards

export default StockManagementCards;
