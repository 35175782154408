import React, { useEffect, useState } from "react";
import "./BookingHistory.css";
import BookingProfile from "./BookingProfile";
import HistoryTable from "./HistoryTable";
import BookingProfileCard from "./BookingProfileCard";
import { useLocation } from "react-router-dom";
import { postAuthApiData } from "../../api/apiService";
import { message, Spin } from "antd";
import useLogout from "../../utils/authUtils";

const BookingHistory = () => {
  const location = useLocation();
  console.log(location.state, "Hi");
  const id = location?.state?.id;
  const details = location?.state?.item;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [shopKeeperData, setShopKeeperData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState("All");
  const [checkedFilter, setCheckedFilter] = useState("");
  const logout = useLogout();

  useEffect(() => {
    fetchSingleShopKeeper();
  }, []);

  const fetchSingleShopKeeper = async () => {
    const Endpoint = "shopkeeperHistory";
    try {
      setLoading(true);
      const userData = { shopkeeperId: id };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Single ShopKeeper Details fetched successful:", data);
      setLoading(postLoading);
      if (data?.responseCode === 200) {
        console.log(data.message);
        setShopKeeperData(data?.data?.orders);
      } else if (data?.responseCode === 400) {
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Single ShopKeeper Details fetched failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const filterShopkeepersBySearchInput = (shopkeepers, searchInput) => {
    if (!searchInput) return shopkeepers;

    const lowerCaseSearchInput = searchInput
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, ""); // Remove special characters

    return shopkeepers.filter((shopkeeper) => {
      const normalizedOrderId = shopkeeper?.bookingId
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, "");

      const orderIdMatch = normalizedOrderId?.includes(lowerCaseSearchInput); // Match order ID

      const priceMatch = String(shopkeeper?.price).includes(searchInput);

      const packetsMatch = String(shopkeeper?.numberOfProducts).includes(
        searchInput
      );

      const paymentStatusMatch = shopkeeper?.paymentStatus
        ?.toLowerCase()
        .includes(lowerCaseSearchInput);

      const orderStatusMatch = shopkeeper?.orderStatus
        ?.toLowerCase()
        .includes(lowerCaseSearchInput);

      return (
        orderIdMatch ||
        priceMatch ||
        paymentStatusMatch ||
        orderStatusMatch ||
        packetsMatch
      );
    });
  };

  const filterAndSortShopkeepers = () => {
    let filteredData = shopKeeperData?.filter((shopkeeper) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Active") {
        return shopkeeper?.status === "active";
      } else if (filter === "Inactive") {
        return shopkeeper?.status === "inactive";
      }
    });

    filteredData = filterShopkeepersBySearchInput(filteredData, searchInput);

    if (checkedFilter === "quantity") {
      filteredData = filteredData?.sort((a, b) => a.quantity - b.quantity);
    } else if (checkedFilter === "paid") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.paymentStatus === "paid"
      );
    } else if (checkedFilter === "credit") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.paymentStatus === "credit"
      );
    } else if (checkedFilter === "Inprogress") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "InProgress"
      );
    } else if (checkedFilter === "Packed") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Packing Completed"
      );
    } else if (checkedFilter === "Delivered") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Delivered"
      );
    } else if (checkedFilter === "Damaged") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Damaged"
      );
    } else if (checkedFilter === "Return") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Return"
      );
    }
    console.log(filteredData);

    return filteredData;
  };

  const filteredShopkeeper = filterAndSortShopkeepers();
  console.log(filteredShopkeeper);
  return (
    <Spin spinning={loading}>
      <div>
        <BookingProfile details={details} />
        <BookingProfileCard
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          checkedFilter={checkedFilter}
          setCheckedFilter={setCheckedFilter}
        />
        <HistoryTable
          shopKeeperData={filteredShopkeeper}
          fetchSingleShopKeeper={fetchSingleShopKeeper}
        />
      </div>
    </Spin>
  );
};

export default BookingHistory;
