// src/redux/timeReducer.js
const initialState = {
    formattedLoginTime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    formattedLoginDate: new Date().toLocaleDateString([], {  year: 'numeric', month: 'long', day: 'numeric' }),
  };
  
  const timeReducer = (state = initialState, action) => {
    switch (action.type) {
      case "UPDATE_TIME":
        return {
          ...state,
          formattedLoginTime: action.payload.time,
          formattedLoginDate: action.payload.date,
        };
      default:
        return state;
    }
  };
  
  export default timeReducer;
  