import React, { useState } from "react";
import { Button, Form, Input, Spin, message, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import "./NewPassword.css";
import { putAuthApiData } from "../../api/apiService"; // Ensure the correct path to the API utility
import { useDispatch, useSelector } from "react-redux";
import { updateEmail } from "../../redux/action";
import useLogout from "../../utils/authUtils";

const NewPassword = () => {
  const [loading, setLoading] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useSelector((state) => state.email);
  const logout = useLogout();
  console.log(email);

  const onFinish = async (values) => {
    console.log(values);
    setLoading(true);
    const Endpoint = "changePassword";
    const userData = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      email: email,
    };
    try {
      const { data, loading: postLoading } = await putAuthApiData(
        Endpoint,
        userData
      );
      console.log("Change Password fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data?.message);
        message.success(data?.message);
        dispatch(updateEmail(""));
        navigate("/");
      } else if (data.responseCode === 400) {
        message.warning(data?.message);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Error posting data:", error);
      message.error(error.error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    const passwordCriteria = [
      { regex: /[a-z]/, message: "at least one lowercase letter" },
      { regex: /[A-Z]/, message: "at least one uppercase letter" },
      { regex: /[0-9]/, message: "at least one digit" },
      { regex: /[\W_]/, message: "at least one special character" },
    ];
    for (const criterion of passwordCriteria) {
      if (!criterion.regex.test(value)) {
        return Promise.reject(`Password must contain ${criterion.message}`);
      }
    }
    return Promise.resolve();
  };

  return (
    <Spin spinning={loading}>
      <div className='new-password-container'>
        <div className='new-password-content'>
          <div className='new-left-section'>
            <div>
              <img
                src='assets/images/login-image.png'
                alt='Login illustration'
              />
            </div>
            <h3 className='white-color'>Welcome to JPF</h3>
          </div>
          <div className='new-right-section'>
            <h3>New Password</h3>
            <div className='new-password_input'>
              <Form
                form={form}
                name='normal_login'
                layout='vertical'
                onFinish={onFinish}>
                <Form.Item
                  name='oldPassword'
                  label='Temporary Password'
                  rules={[
                    {
                      required: true,
                      message: "Please enter your temporary password",
                    },
                  ]}>
                  <Input.Password size='large' placeholder='Old Password' />
                </Form.Item>
                <Form.Item
                  name='newPassword'
                  label='New Password'
                  rules={[
                    {
                      required: true,
                      message: "Please enter your new password",
                    },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters",
                    },
                    {
                      max: 32,
                      message: "Password must be at most 32 characters",
                    },
                    { validator: validatePassword },
                  ]}>
                  <Input.Password size='large' placeholder='New Password' />
                </Form.Item>
                <Form.Item>
                  <button
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    className='new-next-btn'>
                    <p style={{ color: "white" }}>Submit</p>
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={activeSuccess}
        onCancel={() => setActiveSuccess(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='active-success-container'>
          <div className='active-success-content'>
            <div>
              <img src='assets/images/success_image.png' alt='Success' />
            </div>
            <h5>
              New password created <br />
              Successfully
            </h5>
            <p className='new_password_loginback'>
              Click here to <a onClick={() => navigate("/")}> login </a>
            </p>
          </div>
        </div>
      </Modal>
    </Spin>
  );
};

export default NewPassword;
