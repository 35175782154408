import React, { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { Avatar, Modal, Switch, Pagination, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import CategoryIamge from "../Categories/CategoryImage.png";
import CustomerEdit from "./CustomerEdit";
import { postAuthApiData } from "../../api/apiService";
import { message } from "antd/es";
import useLogout from "../../utils/authUtils";

const CustomerListBody = ({ customers, fetchCustomerList }) => {
  const [editModal, setEditModal] = useState(false);
  const [swicthAction, setSwitchAction] = useState(false);
  const [customerActive, setcustomerActive] = useState(false);
  const [customerDeactive, setcustomerDeactive] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [deactiveSuccess, setDeactiveSuccess] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const logout = useLogout();
  const pageSize = 10;

  const onChange = (item) => {
    console.log(item);
    setSelectedItem(item);
    if (item?.status === "active") {
      setcustomerActive(true);
    } else {
      setcustomerDeactive(true);
    }
  };

  const closeModal = () => {
    setEditModal(false);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [customers]);

  const toggleStatus = async () => {
    const Endpoint = "toggleCustomerStatus";
    const status = selectedItem?.status;
    // console.log(id);
    try {
      setLoading(true);
      const userData = {
        customerId: selectedItem?._id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("customer Status Toggling successfull:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        // message.success(data.message);
        fetchCustomerList();
        setcustomerActive(false);
        setcustomerDeactive(false);
        if (status === "active") {
          setDeactiveSuccess(true);
        } else if (status === "inactive") {
          setActiveSuccess(true);
        }
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("customer Status Toggling failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(customers);
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = customers?.slice(startIndex, endIndex);

  return (
    <div className='customerlistBody-content'>
      <div className='customerlistBody-List-table'>
        <table className='customerlistBody_table'>
          <thead className='customerlistBody_table-head'>
            <tr>
              <th className='customerlistBody_serial-no'>S.No</th>
              <th className='customerlistBody_name'>Name of the Customer</th>
              <th className='customerlistBody_email'>E-mail</th>
              <th className='customerlistBody_contact'>Contact</th>
              <th className='customerlistBody_address'>Address</th>
              <th className='customerlistBody_status'>Status</th>
              <th className='customerlistBody_booking-history'>
                Booking History
              </th>
              <th className='customerlistBody_action'>Action</th>
            </tr>
          </thead>
          {paginatedData?.length !== 0
            ? paginatedData.map((customer, index) => (
                <tbody className='customerlistBody_table-body'>
                  <tr
                    key={customer?._id}
                    className={swicthAction ? "" : "body-color"}>
                    <td>{startIndex + index + 1}</td>
                    <td>
                      <div className='customer_exacutive-section capital'>
                        {customer?.image ? (
                          <Avatar src={customer?.image} size={40} />
                        ) : (
                          <Avatar
                            shape='circle'
                            size={40}
                            style={{
                              backgroundColor: "#6750A4",
                              fontWeight: 600,
                              fontSize: 17,
                            }}>
                            {customer?.name?.length > 0 &&
                              customer?.name[0]?.charAt(0)?.toUpperCase()}
                          </Avatar>
                        )}
                        {customer?.name || "-"}
                      </div>
                    </td>
                    <td>{customer?.email || "-"}</td>
                    <td>{customer?.phone || "-"}</td>
                    <td className='customer_address capital'>
                      {customer?.address || "-"}
                    </td>
                    <td>
                      <Switch
                        className='customerlistBody_antSwitch'
                        checked={customer?.status === "active" ? true : false}
                        onChange={() => onChange(customer)}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <button
                        className='customerlistBody_view-button'
                        onClick={() =>
                          navigate("/customer-history", {
                            state: { id: customer?._id, item: customer },
                          })
                        }>
                        View
                      </button>
                    </td>
                    <td>
                      <div>
                        <span>
                          <MdOutlineEdit
                            className='customerlistBody_edit-icon'
                            style={{ cursor: "pointer" }}
                            size={20}
                            onClick={() => {
                              if (customer?.status === "active") {
                                setSelectedItem(customer);
                                setEditModal(true);
                              }
                            }}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))
            : ""}
        </table>
        {paginatedData?.length === 0 ? (
          <div className='no-data-message'>
            <p>No data found</p>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className='Pagination-container'>
        {customers?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={customers?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
      <Modal
        visible={editModal}
        onCancel={closeModal}
        closeIcon={false}
        centered
        footer={null}
        className='custom-modal'>
        <CustomerEdit
          closeModal={closeModal}
          fetchCustomerList={fetchCustomerList}
          selectedItem={selectedItem}
        />
      </Modal>

      <Modal
        visible={customerActive}
        onCancel={() => setcustomerActive(false)}
        centered
        footer={null}
        className='custom-modal'>
        <Spin spinning={loading}>
          <div className='role-active-container'>
            <div className='role-active-content'>
              <h5 className='head-text'>
                Do you want to{" "}
                <span className='Secondry-color'>Deactivate</span> this
                customer?
              </h5>
              <div className='role-active-actions'>
                <button
                  className='role-active-no'
                  onClick={() => setcustomerActive(false)}>
                  <p>No</p>
                </button>
                <button
                  className='role-active-delete'
                  onClick={() => {
                    toggleStatus();
                  }}>
                  <p>Yes</p>
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={customerDeactive}
        onCancel={() => setcustomerDeactive(false)}
        centered
        footer={null}
        className='custom-modal'>
        <Spin spinning={loading}>
          <div className='role-active-container'>
            <div className='role-active-content'>
              <h5>
                Are you want to <span className='primary-color'>Active</span>{" "}
                this customer
              </h5>
              <div className='role-active-actions'>
                <button
                  className='role-active-no'
                  onClick={() => setcustomerDeactive(false)}>
                  <p>No</p>
                </button>
                <button
                  className='role-active-delete'
                  onClick={() => {
                    toggleStatus();
                  }}>
                  <p>Yes</p>
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={activeSuccess}
        onCancel={() => setActiveSuccess(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='active-success-container'>
          <div className='active-success-content'>
            <div>
              <img src='assets/images/success_image.png' alt='' />
            </div>
            <h5>
              customer Activated <br />
              Successfully
            </h5>
          </div>
        </div>
      </Modal>
      <Modal
        visible={deactiveSuccess}
        onCancel={() => setDeactiveSuccess(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='active-success-container'>
          <div className='active-success-content'>
            <div>
              <img src='assets/images/success_image.png' alt='' />
            </div>
            <h5>
              customer Deactivated <br />
              Successfully
            </h5>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomerListBody;
