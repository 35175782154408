import React, { useEffect, useState } from "react";
import "./OrderManagement.css";
import OrderManagementcards from "./OrderManagementcards";
import OrderManagementBody from "./OrderManagementBody";
import OrderMListBody from "./OrderMListBody";
import { getApiData, postAuthApiData } from "../../api/apiService";
import { Spin, message } from "antd";
import useLogout from "../../utils/authUtils";

const OrderManagement = () => {
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [ordersCount, setOrdersCount] = useState({});
  const [ordersList, setOrdersList] = useState([]);
  const [dispatchedCount, setDispatchedCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [returnsCount, setReturnsCount] = useState(0);
  const [damagedCount, setDamagedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filterDate, setFilterDate] = useState([]);
  const logout = useLogout();

  // const fetchOrdersCount = async () => {
  //   const Endpoint = "ordersCount";
  //   try {
  //     setLoading(true);
  //     const { data, loading: postLoading } = await getApiData(Endpoint, true);
  //     console.log("ordersCount fetched successful:", data);
  //     setLoading(postLoading);
  //     if (data.responseCode === 200) {
  //       console.log(data.message);
  //       setOrdersCount(data?.data);
  //     } else if (data.responseCode === 400) {
  //       console.log(data);
  //       console.log(data.message);
  //     }
  //   } catch (error) {
  //     console.log("ordersCount fetching failed:", error);
  //     message.error(error);
  //     setLoading(false);
  //     setError(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchOrdersManagementsList = async (date) => {
    const Endpoint = "getListOfOrderManagement";
    try {
      setLoading(true);
      const userData = {
        startDate: date?.length > 0 && date[0],
        endDate: date?.length > 0 && date[1],
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("ordersManagement List fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setOrdersList(data?.data);
        const disptchedProducts = data?.data.filter(
          (product) => product.orderStatus === "Packing Completed"
        );
        const deliveredProducts = data?.data.filter(
          (product) => product.orderStatus === "Delivered"
        );
        const returnProducts = data?.data.filter(
          (product) => product.orderStatus === "Return"
        );
        const damagedProducts = data?.data.filter(
          (product) => product.orderStatus === "Damaged"
        );
        setTotalCount(data?.data?.length);
        setDamagedCount(damagedProducts?.length);
        setDeliveredCount(deliveredProducts?.length);
        setDispatchedCount(disptchedProducts?.length);
        setReturnsCount(returnProducts?.length);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("ordersManagement List fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchOrdersCount();
    fetchOrdersManagementsList(filterDate);
  }, []);

  console.log(damagedCount, returnsCount, totalCount, dispatchedCount);

  const filterCustomersBySearchInput = (customers, searchInput) => {
    if (!searchInput) return customers;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return customers.filter(
      (customer) =>
        customer.customerName?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        customer.paymentMethod?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        customer.bookingId?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        customer.orderStatus?.toLowerCase()?.includes(lowerCaseSearchInput)
    );
  };

  const filteredCustomers = filterCustomersBySearchInput(
    ordersList?.filter((customer) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Dispatched") {
        return customer.orderStatus === "Packing Completed";
      } else if (filter === "Delivered") {
        return customer.orderStatus === "Delivered";
      } else if (filter === "Returns") {
        return customer.orderStatus === "Return";
      }
    }),
    searchInput
  );
  useEffect(() => {
    const total = filteredCustomers.length;
    const damagedProducts = filteredCustomers.filter(
      (customer) => customer.orderStatus === "Damaged"
    );
    const deliveredProducts = filteredCustomers.filter(
      (customer) => customer.orderStatus === "Delivered"
    );
    const dispatchedProducts = filteredCustomers.filter(
      (customer) => customer.orderStatus === "Packing Completed"
    );
    const returnProducts = filteredCustomers.filter(
      (customer) => customer.orderStatus === "Return"
    );

    setTotalCount(total);
    setDamagedCount(damagedProducts.length);
    setDeliveredCount(deliveredProducts.length);
    setDispatchedCount(dispatchedProducts.length);
    setReturnsCount(returnProducts.length);
  }, [searchInput]);

  return (
    <Spin spinning={loading}>
      <div>
        <OrderManagementcards
          ordersList={ordersList}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
          fetchOrdersManagementsList={fetchOrdersManagementsList}
        />
      </div>
      <div>
        <OrderManagementBody
          filter={filter}
          setFilter={setFilter}
          data={ordersList}
          returnsCount={returnsCount}
          dispatchedCount={dispatchedCount}
          deliveredCount={deliveredCount}
          searchInput={searchInput}
          totalCount={totalCount}
          setSearchInput={setSearchInput}
          ordersList={filteredCustomers}
          setDamagedCount={setDamagedCount}
          setDeliveredCount={setDeliveredCount}
          setDispatchedCount={setDispatchedCount}
          setOrdersCount={setOrdersCount}
          setTotalCount={setTotalCount}
          setReturnsCount={setReturnsCount}
        />
      </div>
      <div>
        <OrderMListBody
          ordersList={filteredCustomers}
          fetchOrdersManagementsList={fetchOrdersManagementsList}
        />
      </div>
    </Spin>
  );
};

export default OrderManagement;
