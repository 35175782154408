import React, { useState, useEffect } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { VscCheck, VscCircleFilled } from "react-icons/vsc";
import { Modal, Pagination } from "antd";
import moment from "moment";

const CustomerHistoryTable = ({ customerData, fetchCustomer }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const pageSize = 10;

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = customerData?.slice(startIndex, endIndex);

  console.log(customerData);
  console.log(paginatedData);

  useEffect(() => {
    setCurrentPage(1);
  }, [customerData]);

  const getBackgroundColor = (status) => {
    switch (status) {
      case "Delivered":
        return "#14B8A6";
      case "Packing Completed":
        return "#14B8A6";
      case "InProgress":
        return "#FFC727";
      case "Return":
        return "#EF4444";
      case "Damaged":
        return "#EF4444";
      default:
        return "white";
    }
  };

  const handleInvoice = async () => {
    await setInvoiceModal(true);
  };

  return (
    <>
      <div className='CustomerHistoryTable_div'>
        <table className='CustomerHistoryTable_table'>
          <thead className='CustomerHistoryTable_thead'>
            <tr className='CustomerHistoryTable_tableHead-row'>
              <th className='CustomerHistoryTable_Serial-No'>S.No</th>
              <th className='CustomerHistoryTable_Date'>Date</th>
              <th className='CustomerHistoryTable_BookingId'>Booking ID</th>
              {/* <th className='CustomerHistoryTable_Quantity'>Quantity</th> */}
              <th className='CustomerHistoryTable_NumPackets'>
                Number of Packets
              </th>
              <th className='CustomerHistoryTable_Price'>Price</th>
              <th className='CustomerHistoryTable_payment-Status'>
                Payment Status
              </th>
              <th className='CustomerHistoryTable_Order-Status'>
                Order Status
              </th>
              <th className='CustomerHistoryTable_Invoice'>Invoice</th>
            </tr>
          </thead>
          <tbody className='CustomerHistoryTable_tbody'>
            {paginatedData?.length > 0
              ? paginatedData?.map((item, index) => (
                  <tr
                    className='CustomerHistoryTable_tableData-row'
                    key={index}>
                    <td>{startIndex + index + 1}</td>
                    <td>
                      {item?.Date
                        ? moment(item.Date).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                    <td>{item?.bookingId}</td>
                    {/* <td>{item?.quantity && item?.quantity?.toFixed(2)}</td> */}
                    <td>
                      {item?.quantity !== 0
                        ? `${item?.quantity?.toFixed(1)} Kg [${
                            item?.numberOfProducts
                          } Pkt]`
                        : `${item?.numberOfProducts} Pkt`}
                    </td>
                    <td>{item?.price?.toFixed(2)}</td>
                    <td
                      className={
                        item?.paymentStatus === "paid" ? "paid" : "credit"
                      }>
                      <div className='CustomerPaymentStatus capital'>
                        {item?.paymentStatus}
                        {item?.paymentStatus === "paid" && (
                          <VscCheck
                            className='CustomerHistoryTable_Paid-Icon'
                            size={12}
                          />
                        )}
                        {item?.paymentStatus === "credit" && (
                          <VscCheck
                            className='CustomerHistoryTable_Paid-Icon'
                            size={12}
                          />
                        )}
                      </div>
                    </td>
                    <td className='CustomerHistoryTable_OrderStatus'>
                      <div
                        className='capital order-status'
                        style={{
                          backgroundColor: getBackgroundColor(
                            item?.orderStatus
                          ),
                        }}>
                        {item?.orderStatus}
                      </div>
                    </td>
                    <td>
                      <IoEyeOutline
                        size={18}
                        onClick={() => {
                          setInvoiceUrl(item?.invoice);
                          handleInvoice();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {Array.isArray(customerData) && customerData.length === 0 ? (
          <div className='no-data-message'>
            <h6>No data found</h6>
          </div>
        ) : (
          ""
        )}

        <Modal
          visible={invoiceModal}
          onCancel={() => setInvoiceModal(false)}
          closeIcon={false}
          centered
          footer={null}>
          <div className='invoice-container'>
            <div>
              <iframe src={invoiceUrl} width='100%' height='550px' />
            </div>
            <div style={{ textAlign: "end" }}>
              <button
                onClick={() => {
                  setInvoiceModal(false);
                  setInvoiceUrl("");
                }}
                className='close-btn'>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div className='CustomerHistoryTable_div-Pagination-container'>
        {Array.isArray(customerData) && customerData.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={customerData.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </>
  );
};

export default CustomerHistoryTable;
