import React, { useEffect, useState } from "react";
import RoleCard from "./RoleCard";
import RoleListBody from "./RoleListBody";
import "../Roles/Role.css";
import { getApiData } from "../../api/apiService";
import { message, Spin } from "antd";
import useLogout from "../../utils/authUtils";

const Roles = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("All");
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [featuresData, setFeaturesData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const logout = useLogout();

  const fetchFeaturesData = async () => {
    const Endpoint = "ListOfFeatures";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("Features data fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setFeaturesData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Features data fetching failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchRolesData = async () => {
    const Endpoint = "listOfRoles";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("Features data fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        setRolesData(data?.data);
        console.log(data.message);
        const activeRole = data?.data.filter(
          (Role) => Role.status === "active"
        );
        const inactiveRole = data?.data.filter(
          (Role) => Role.status === "inactive"
        );
        setTotalCount(data?.data?.length);
        setActiveCount(activeRole.length);
        setInactiveCount(inactiveRole.length);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Features data fetching failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRolesData();
    fetchFeaturesData();
  }, []);

  const filterRolesBySearchInput = (roles, searchInput) => {
    if (!searchInput) return roles;
    return roles.filter((role) =>
      role.roleName.toLowerCase().includes(searchInput.toLowerCase())
    );
  };

  const filteredRole = filterRolesBySearchInput(
    rolesData?.filter((Role) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Active") {
        return Role.status === "active";
      } else if (filter === "Inactive") {
        return Role.status === "inactive";
      }
    }),
    searchInput
  );

  useEffect(() => {
    const activeRole = filteredRole?.filter((Role) => Role.status === "active");
    const inactiveRole = filteredRole?.filter(
      (Role) => Role.status === "inactive"
    );
    setTotalCount(filteredRole?.length);
    setActiveCount(activeRole.length);
    setInactiveCount(inactiveRole.length);
  }, [searchInput]);

  console.log(searchInput);

  return (
    <Spin spinning={loading}>
      <div>
        <RoleCard
          featuresData={featuresData}
          fetchRolesData={fetchRolesData}
          filter={filter}
          rolesData={filteredRole}
          setFilter={setFilter}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          totalCount={totalCount}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      </div>
      <div>
        <RoleListBody
          rolesData={filteredRole}
          fetchRolesData={fetchRolesData}
          featuresData={featuresData}
        />
      </div>
    </Spin>
  );
};

export default Roles;
