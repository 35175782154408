import React, { useEffect, useState } from "react";
import "../OfflineSales/OfflineSales.css";
import { FaPlus } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getApiData, postAuthApiData } from "../../api/apiService";
import { message } from "antd";
import OnlineSalesPayment from "./OnlineSalesPayment";
import useLogout from "../../utils/authUtils";

const OrderSummary = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [summaryData, setSummaryData] = useState({});
  const logout = useLogout();

  const navigate = useNavigate();

  const fetchOfflineOrderSummary = async () => {
    const id = localStorage.getItem("onlineOrderBookingId");
    const Endpoint = "getOnlineOrderSummary";
    try {
      setLoading(true);
      const userData = {
        newOrderBookingId: id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Offline Sales List fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setSummaryData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Offline Sales List fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(summaryData);

  useEffect(() => {
    fetchOfflineOrderSummary();
  }, []);

  return (
    <div className='Offlineordersummary-container'>
      <div className='Offlineorder-summary'>
        <div className='Offlineorder-summary-label'>
          <p>Order Summary</p>
        </div>
        <div className='Offlineorder-customer-details'>
          <span className='Offlineorder-customer-name'>
            {summaryData?.shopkeeperName}
          </span>
          <span className='Offlineorder-customer-phone'>
            {summaryData?.shopkeeperContact}
          </span>
        </div>
        <div className='Offlineorder-amount-details'>
          <div className='Offline_order_product'>
            <span className='Offlineorder-totalpro-label'>Total Products</span>
            <span className='Offlineorder-totalprod-value'>
              {summaryData?.total_products} Products
            </span>
          </div>
          <div className='Offline_total-amount'>
            <span className='Offlineorder-totalamt-lable'>Total Amount</span>
            <span className='Offlineorder-totalamt-value'>
              &#8377; {summaryData?.total_amount} /-
            </span>
          </div>
          <div className='Offline_order_discount'>
            <span className='Offlineorder-totaldiscount-label'>
              Total Discount
            </span>
            <span className='Offlineorder-totaldiscount-value'>
              - &#8377;{" "}
              {(summaryData?.total_amount - summaryData?.sub_total).toFixed(2)}
            </span>
          </div>
          <div className='Offline_order_subtotal'>
            <span className='Offlineorder-subtotal-label'>Subtotal</span>
            <span className='Offlineorder-subtotal-value'>
              &#8377; {summaryData?.sub_total} /-
            </span>
          </div>
        </div>
        <div className='Offlineorder-total-pay'>
          <span className='Offlineorder-total-payable-label'>
            Total Payable Amount:
          </span>
          <span className='Offlineorder-total-payable-value'>
            &#8377; {summaryData?.sub_total} /-
          </span>
        </div>
        <div className='Offline_order_add_more' onClick={() => navigate(-1)}>
          <button className='Offline_orderAddmorebutn'>
            <FaPlus size={10} />
            Add more product
          </button>
        </div>
      </div>
      <div className='Offline_order-vertical-line'></div>
      <div className='payment-section'>
        <OnlineSalesPayment
          summaryData={summaryData}
          loading={loading}
          setLoading={setLoading}
          error={error}
          setError={setError}
        />
      </div>
    </div>
  );
};

export default OrderSummary;
