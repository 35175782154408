import React, { useState } from "react";
import "../OnlineSales/OnlineSales.css";
import { Avatar, Modal, Spin } from "antd";
import { Input, message } from "antd";
import cardimage from "../OnlineSales/Images/OnlineCard.png";
import cardimage1 from "../OnlineSales/Images/OnlineCard2.png";
import cardimage2 from "../OnlineSales/Images/OnlineCard3.png";
import cardimage3 from "../OnlineSales/Images/OnlineCard4.png";
import cardimage4 from "../OnlineSales/Images/OnlineCard5.png";
import cardimage5 from "../OnlineSales/Images/onlineCard6.png";
import upiImage from "../OnlineSales/Images/OnlineUpi.png";
import upiImage1 from "../OnlineSales/Images/OnlineUpi1.png";
import upiImage2 from "../OnlineSales/Images/OnlineUpi2.png";
import upiImage3 from "../OnlineSales/Images/OnlineUpi3.png";
import upiImage4 from "../OnlineSales/Images/OnlineUpi4.png";
import upiImage5 from "../OnlineSales/Images/OnlineUpi5.png";
import Image from "../Categories/Image.png";
import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postAuthApiData, putAuthApiData } from "../../api/apiService";
import { OfflineAddCustomer, resetSelectedProducts } from "../../redux/action";
import SalesSuccessPopUp from "./SalesSuccessPopUp";
import useLogout from "../../utils/authUtils";
import { CloudUploadOutlined } from "@ant-design/icons";
import { ImCross } from "react-icons/im";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";
import { useDropzone } from "react-dropzone";

const OfflineSalesCreditPayment = ({
  summaryData,
  loading,
  setLoading,
  error,
  setError,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [categoryImage, setCategoryImage] = useState("");
  const [enteredAmount, setEnteredAmount] = useState();
  const [balanceAmount, setBalanceAmount] = useState();
  const logout = useLogout();
  const [file, setFile] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState("BHIMUPI");
  const [successPopUp, setSuccessPopUp] = useState(false);

  const handleImageSelection = (type) => {
    setSelectedImage(type);
  };

  const payment_method = localStorage.getItem("paymentMethod");

  const fetchGenerateInvoice = async (bookingData) => {
    const Endpoint = "generateInvoice";
    try {
      setLoading(true);
      const userData = {
        orderbookingId: bookingData?.orderBookingId,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Generate invoice fetched successfully:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        navigate("/offlineSales");
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("products Info data fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPayment = async () => {
    const Endpoint = "OfflineConfirmPayment";
    if (balanceAmount > 0 && enteredAmount > 0) {
      try {
        setLoading(true);
        const id = localStorage.getItem("orderBookingId");
        const userData = {
          newOrderBookingId: id,
          paidAmount: enteredAmount,
          paymentMethod: paymentMethod,
          dueAmount: balanceAmount,
        };
        if (paymentMethod === "Cheque") {
          userData.chequeImageUrl = categoryImage;
        }
        if (paymentMethod === "UPI") {
          userData.paymentMethodType = selectedImage;
        }
        const { data, loading: postLoading } = await putAuthApiData(
          Endpoint,
          userData
        );
        console.log("Confirm Payment successful:", data);
        setLoading(postLoading);
        if (data?.responseCode === 200) {
          console.log(data.message);
          setSuccessPopUp(true);
          // message.success(data?.message);
          localStorage.removeItem("orderBookingId");
          dispatch(resetSelectedProducts());
          fetchGenerateInvoice(data?.data);
          dispatch(OfflineAddCustomer({}));
        } else if (data?.responseCode === 400) {
          console.log(data.message);
          message.warning(data?.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } catch (error) {
        console.log("Confirm Payment failed:", error);
        message.error(error);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    } else {
      message.warning("Please enter the amount");
    }
  };

  const handleAmountChange = (e) => {
    const valueAsNumber = parseFloat(e.target.value);
    const balance = summaryData?.sub_total - valueAsNumber;
    console.log(balance);
    if (!isNaN(valueAsNumber) && !isNaN(balance)) {
      if (summaryData?.sub_total > valueAsNumber) {
        const formattedValue = parseFloat(valueAsNumber.toFixed(2));
        const formattedBalance = parseFloat(balance.toFixed(2));
        setEnteredAmount(formattedValue);
        setBalanceAmount(formattedBalance);
      } else {
        message.warning("Entering amount is more than the payable");
      }
    } else {
      setEnteredAmount();
      setBalanceAmount();
    }
  };
  const handlePaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        if (file.size > 5242880) {
          message.error("File size exceeds the maximum limit of 5MB.");
          return;
        }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });

  return (
    <Spin spinning={loading}>
      <div className='offlineCredit_payment'>
        <div className='offlineSales_Credit-pay'>
          <h5>Credit Pay</h5>
          <p>Please select a payment method to complete the sale</p>
          <div className='offlineCredit_payment-methods'>
            <button
              className={`payment-method ${
                paymentMethod === "Cash" ? "active" : ""
              }`}
              onClick={() => handlePaymentMethod("Cash")}>
              Cash
            </button>
            <button
              className={`payment-method ${
                paymentMethod === "Card" ? "active" : ""
              }`}
              onClick={() => handlePaymentMethod("Card")}>
              Card
            </button>
            <button
              className={`payment-method ${
                paymentMethod === "UPI" ? "active" : ""
              }`}
              onClick={() => handlePaymentMethod("UPI")}>
              Online Payment
            </button>
            <button
              className={`payment-method ${
                paymentMethod === "Cheque" ? "active" : ""
              }`}
              onClick={() => handlePaymentMethod("Cheque")}>
              Cheque
            </button>
          </div>

          <div className='offCreditAmountDetails'>
            <lable className='offCreditamount_lable'>
              Enter Amount
              <Input
                type='text'
                placeholder='0000'
                value={enteredAmount}
                onChange={handleAmountChange}
              />
            </lable>
            <lable className='offCreditBalance_lable'>
              Enter Balance Amount
              <Input
                type='text'
                placeholder='0000'
                value={balanceAmount}
                disabled
              />
            </lable>
          </div>

          {/* {paymentMethod === "Card" && (
          <div className='offlineCredit_card-details'>
            <div className='offlineCredit_Select_Card'>
              <div className='offlineCredit_Select_CardLable'>Select card</div>
              <div className='offlineCredit_cardimage'>
                <img
                  className='CardImages'
                  src={cardimage3}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages1'
                  src={cardimage4}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages2'
                  src={cardimage2}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages3'
                  src={cardimage1}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages4'
                  src={cardimage5}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages5'
                  src={cardimage}
                  shape='square'
                  size={70}
                />
              </div>
            </div>

            <h5 className='offlineCredit_card-details-header'>
              Payment Details
            </h5>
            <span className='headerCredit-text-offline'>
              Please fill out the form to complete Payment
            </span>
            <div className='offlineCardCredit_details'>
              <div className='offlineCardCredit-Details-Row'>
                <label className='offlinecardCredit-label'>
                  Card Holder Name
                  <Input
                    type='text'
                    id='Card Holder Name'
                    placeholder='Enter Name'
                    value={cardDetails.CardHolderName}
                    onChange={handleCardInputChange}
                  />
                </label>
                <label className='cardnumberCredit-label'>
                  Card Number
                  <Input
                    type='Number'
                    id='Number'
                    placeholder='Enter Number'
                    value={cardDetails.CardNumber}
                    onChange={handleCardInputChange}
                  />
                </label>
              </div>
              <div className='offlineCreditDetails_sec_row'>
                <label className='cardexpiry-label'>
                  Expiry Date
                  <Input
                    type='text'
                    id='expiryDate'
                    placeholder='MM/YY'
                    value={cardDetails.expiryDate}
                    onChange={handleCardInputChange}
                  />
                </label>
                <label className='Creditcardcvv-label'>
                  Enter CVV
                  <Input
                    type='text'
                    id='cvv'
                    placeholder='CVV'
                    value={cardDetails.cvv}
                    onChange={handleCardInputChange}
                  />
                </label>
              </div>
            </div>
          </div>
        )} */}
          {paymentMethod === "UPI" && (
            <div className='offlineCredit_online-payment-details'>
              <label className='offlineCredit-payment-label'>Select UPI</label>
              <div className='offlineCredit_cardUpiimage'>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "BHIMUPI" ? "selectedImage" : ""
                    }`}
                    src={upiImage5}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("BHIMUPI")}
                  />
                </div>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Phonepe" ? "selectedImage" : ""
                    }`}
                    src={upiImage4}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Phonepe")}
                  />
                </div>
                <div>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Gpay" ? "selectedImage" : ""
                    }`}
                    src={upiImage2}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Gpay")}
                  />
                </div>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Paytm" ? "selectedImage" : ""
                    }`}
                    src={upiImage3}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Paytm")}
                  />
                </div>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Paypal" ? "selectedImage" : ""
                    }`}
                    src={upiImage1}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Paypal")}
                  />
                </div>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Cred" ? "selectedImage" : ""
                    }`}
                    src={upiImage}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Cred")}
                  />
                </div>
              </div>
              {/* <h5 className='Creditupidetail_header'>Add Upi Details</h5>
            <span className='Creditheader-text-offline'>
              Please fill out the form to complete Payment
            </span>
            <div className='offlineCreditUpiDetails'>
              <label className='Creditonline-Upi-label'>
                Enter UPI ID
                <Input
                  type='text'
                  id='upiId'
                  placeholder='Your UPI ID'
                  // value={onlinePaymentDetails.upiId}
                  // onChange={handleOnlinePaymentInputChange}
                />
              </label>
              <label className='Credit-mobileNum-label'>
                Enter Mobile Number
                <Input
                  type='text'
                  id='mobileNumber'
                  placeholder='Your Mobile Number'
                  // value={onlinePaymentDetails.mobileNumber}
                  // onChange={handleOnlinePaymentInputChange}
                />
              </label>
            </div> */}
            </div>
          )}
          {paymentMethod === "Cheque" && (
            <div {...getRootProps()} class='upload-container'>
              <input {...getInputProps()} />
              {file && categoryImage ? (
                <div className='uploaded-image-section'>
                  <div className='fileUpload-image-div'>
                    <img src={file.preview || categoryImage} alt={file.name} />
                  </div>
                  <ImCross
                    className='text-md font-light cursor-pointer'
                    onClick={() => {
                      setFile(null);
                      setCategoryImage("");
                    }}
                  />
                </div>
              ) : (
                <CloudUploadOutlined className='cloud-icon' />
              )}
              <div className='upload-text-section'>
                <p class='upload-text'>
                  Browse/drag to {file && categoryImage ? "change" : "upload"}{" "}
                  File
                </p>
                <p class='file-size-text'>File Size must be below 5 Mb</p>
              </div>
            </div>
          )}

          <div className='offlineCredit_payment-actions'>
            <button className='offlineCredit_cancelButton'>Cancel</button>
            <button
              className='offlineCredit_confirmButton'
              onClick={handleConfirmPayment}>
              Confirm Payment
            </button>
          </div>
        </div>
        <Modal
          visible={successPopUp}
          onCancel={() => setSuccessPopUp(false)}
          centered
          footer={null}
          className='custom-modal'>
          <SalesSuccessPopUp />
        </Modal>
      </div>
    </Spin>
  );
};

export default OfflineSalesCreditPayment;
