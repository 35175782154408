import React, { useEffect, useState } from "react";
import "./Notifications.css";
import { Avatar, Input, message, Spin } from "antd";
import { SearchOutlined, TransactionOutlined } from "@ant-design/icons";
import { getApiData } from "../../api/apiService";
import { IoMdLogIn, IoMdAlert, IoMdInformationCircle } from "react-icons/io";
import {
  FaIndianRupeeSign,
  FaRegCreditCard,
  FaRegUser,
  FaRupeeSign,
  FaTruck,
} from "react-icons/fa6";
import { MdAddShoppingCart, MdPublishedWithChanges } from "react-icons/md";
import { SiStatuspal } from "react-icons/si";
import { GiReturnArrow } from "react-icons/gi";

const Notifications = () => {
  const [filter, setFilter] = useState("All");
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiData, setApiData] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const notificationTypeToIconMap = {
    employeLogin: IoMdLogIn,
    alert: IoMdAlert,
    info: IoMdInformationCircle,
    orderBooking: FaTruck,
    payment: FaIndianRupeeSign,
    "Customer created": FaRegUser,
    "Product Restock": MdAddShoppingCart,
    "Paying Insatallment ": FaRegCreditCard,
    OrderStatus: SiStatuspal,
    "Product Return": GiReturnArrow,
    "order status changed ": MdPublishedWithChanges,
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };
  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    const Endpoint = "getAllNotifications";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        setApiData(data?.data);
        setNotifications(data?.data?.notifications);
      } else if (data.responseCode === 400) {
        message.error(data.message);
      }
    } catch (error) {
      console.log("Failed to fetch notifications:", error);
      setError(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const filteredNotifications =
    notifications?.length > 0
      ? notifications?.filter(
          (pandit) =>
            pandit?.message
              ?.toLowerCase()
              ?.includes(searchInput?.toLowerCase()) ||
            pandit?.notificationType
              ?.toLowerCase()
              ?.includes(searchInput?.toLowerCase())
        )
      : [];

  return (
    <Spin spinning={loading}>
      <div className='notification-container'>
        <div className='notification-content'>
          <div className='chats-section'>
            <div className='Filters'>
              <div>
                <Input
                  type='search'
                  placeholder='Search Notification'
                  suffix={<SearchOutlined />}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>
            <div className='chats-list'>
              {filteredNotifications?.map((notification) => {
                const IconComponent =
                  notificationTypeToIconMap[notification.notificationType] ||
                  IoMdLogIn;
                return (
                  <div className='chat-card' key={notification._id}>
                    <div className='chat-container'>
                      <div>
                        <Avatar
                          icon={<IconComponent className='notification-icon' />}
                          size={45}
                          style={{ backgroundColor: "lightgray" }}
                        />
                      </div>
                      <div className='content-card'>
                        <div className='name-date'>
                          <p>{notification.notificationType}</p>
                          <p>
                            {new Date(notification.createdAt).toLocaleString()}
                          </p>
                        </div>
                        <div>
                          <p>{notification.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {filteredNotifications?.length === 0 && (
              <div className='no-data-message'>
                <h6>No results found for your search</h6>
              </div>
            )}
            <div></div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Notifications;
