import React, { useEffect, useState } from "react";
import OnlineSalesCard from "./OnlineSalesCard";
import "../OnlineSales/OnlineSales.css";
import OnlineListBody from "./OnlineListBody";
import { getApiData, postAuthApiData } from "../../api/apiService";
import { Spin, message } from "antd";
import useLogout from "../../utils/authUtils";

const OnlineSales = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [onlineSalesData, setOnlineSalesData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState("All");
  const [checkedFilter, setCheckedFilter] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const logout = useLogout();

  const fetchOfflineSalesList = async (date) => {
    const Endpoint = "WebGetOnlineBookingList";
    try {
      setLoading(true);
      const userData = {
        startDate: date?.length > 0 && date[0],
        endDate: date?.length > 0 && date[1],
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Offline Sales List fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setOnlineSalesData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Offline Sales List fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const filterShopkeepersBySearchInput = (shopkeepers, searchInput) => {
    if (!searchInput) return shopkeepers;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return shopkeepers.filter(
      (shopkeeper) =>
        shopkeeper?.customerName
          ?.toLowerCase()
          ?.includes(lowerCaseSearchInput) ||
        shopkeeper?.customerContact?.includes(searchInput) ||
        shopkeeper?.orderId?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        shopkeeper?.executiveName
          ?.toLowerCase()
          ?.includes(lowerCaseSearchInput) ||
        shopkeeper?.shopkeeperName
          ?.toLowerCase()
          ?.includes(lowerCaseSearchInput)
    );
  };

  const filterAndSortShopkeepers = () => {
    let filteredData = onlineSalesData?.filter((shopkeeper) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Active") {
        return shopkeeper?.status === "active";
      } else if (filter === "Inactive") {
        return shopkeeper?.status === "inactive";
      }
    });

    filteredData = filterShopkeepersBySearchInput(filteredData, searchInput);

    if (checkedFilter === "quantity") {
      filteredData = filteredData.sort(
        (a, b) => a.total_quantity - b.total_quantity
      );
    }
    if (checkedFilter === "price") {
      filteredData = filteredData.sort((a, b) => a.total_price - b.total_price);
    } else if (checkedFilter === "paid") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.paymentStatus === "spotpay"
      );
    } else if (checkedFilter === "credit") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.paymentStatus === "creditpay"
      );
    } else if (checkedFilter === "Inprogress") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "InProgress"
      );
    } else if (checkedFilter === "Delivered") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Delivered"
      );
    } else if (checkedFilter === "Packed") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Packing Completed"
      );
    } else if (checkedFilter === "Return") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Return"
      );
    } else if (checkedFilter === "Damaged") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Damaged"
      );
    }

    return filteredData;
  };

  const filteredShopkeeper = filterAndSortShopkeepers();
  useEffect(() => {
    fetchOfflineSalesList(filterDate);
  }, []);

  return (
    <Spin spinning={loading}>
      <div>
        <OnlineSalesCard
          onlineSalesData={filteredShopkeeper}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          checkedFilter={checkedFilter}
          setCheckedFilter={setCheckedFilter}
          fetchOfflineSalesList={fetchOfflineSalesList}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
        />
        <OnlineListBody
          onlineSalesData={filteredShopkeeper}
          fetchOfflineSalesList={fetchOfflineSalesList}
        />
      </div>
    </Spin>
  );
};

export default OnlineSales;
