import { type } from "@testing-library/user-event/dist/type";

export const updatingSelectedPujas = (selectedPujas) => {
  return {
    type: "UPDATE_SELECTED_PUJAS",
    payload: selectedPujas,
  };
};

export const UpdatingSelectedProducts = (selectedProducts) => {
  return {
    type: "UPDATE_SELECTED_PRODUCTS",
    payload: selectedProducts,
  };
};

export const removeSelectedProduct = (skuId) => {
  return {
    type: "REMOVE_SELECTED_PRODUCT",
    payload: skuId, // This is the id of the product you want to remove
  };
};

export const OfflineAddCustomer = (storedData) => {
  return {
    type: "OFFLINE_ADD_CUSTOMER",
    payload: storedData,
  };
};

export const updatingUserProfile = (profileData) => {
  return {
    type: "UPDATE_USER_PROFILE",
    payload: profileData,
  };
};

export const logoutUser = () => ({
  type: "LOGOUT_USER",
});

export const resetSelectedProducts = () => ({
  type: "RESET_SELECTED_PRODUCTS",
});

export const updateProductDiscount = (index, discount, finalPrice) => ({
  type: "UPDATE_PRODUCT_DISCOUNT",
  payload: { index, discount, finalPrice },
});

export const updateToken = (token) => ({
  type: "UPDATE_TOKEN",
  payload: token,
});

export const updateSelectedCity = (selectedCity, city) => ({
  type: "UPDATE_SELECTED_CITY",
  payload: { selectedCity, city },
});
export const setLanguage = (language) => {
  localStorage.setItem("language", language);

  console.log(language);
  return {
    type: "SET_LANGUAGE",
    payload: language,
  };
};

export const updateEmail = (email) => ({
  type: "UPDATE_EMAIL",
  payload: email,
});



// actions/timeActions.js
// src/redux/actions/timeActions.js
export const updateTime = () => {
  const now = new Date();
  return {
    type: "UPDATE_TIME",
    payload: {
      time: now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      date: now.toLocaleDateString([], {  year: 'numeric', month: 'long', day: 'numeric' })
    }
  };
};

