import React, { useEffect, useState } from "react";
import {
  Avatar,
  Switch,
  Modal,
  Form,
  Input,
  Checkbox,
  message,
  Spin,
  Pagination,
} from "antd";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineModeEdit, MdDeleteOutline } from "react-icons/md";
import EditRole from "./EditRole";
import AddActivity from "./AddActvity";
import { deleteApiData, postAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";
import moment from "moment";

const RoleListBody = ({ rolesData, fetchRolesData, featuresData }) => {
  const [actionModal, setActionModal] = useState(false);
  const [addActivity, setAddActivity] = useState(false);
  const [deleteRole, setDeleteRole] = useState(false);
  const [deleteSuccessModal, setSuccessDeleteModal] = useState(false);
  const [swicthAction, setSwitchAction] = useState(false);
  const [roleActive, setRoleActive] = useState(false);
  const [roleDeactive, setRoleDeactive] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [deactiveSuccess, setDeactiveSuccess] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const logout = useLogout();

  const pageSize = 10;

  const onChange = (item) => {
    console.log(item);
    setSelectedItem(item);
    if (item?.status === "active") {
      setRoleActive(true);
    } else {
      setRoleDeactive(true);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [rolesData]);

  const toggleStatus = async () => {
    const Endpoint = "roleStatus";
    const status = selectedItem?.status;
    try {
      setLoading(true);
      const userData = {
        roleId: selectedItem?._id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Role Status Toggling successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        fetchRolesData();
        setRoleActive(false);
        setRoleDeactive(false);
        if (status === "active") {
          setDeactiveSuccess(true);
        } else if (status === "inactive") {
          setActiveSuccess(true);
        }
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Role Status Toggling failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    setActionModal(false);
    setAddActivity(false);
    setDeleteRole(false);
  };
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = rolesData?.slice(startIndex, endIndex);

  return (
    <div className='rolelistBody-container'>
      <table className='rolelistBody_table'>
        <thead>
          <tr className='rolelistBody_table_head'>
            {/* <th></th> */}
            <th className='rolelistBody_S.no'>S.No</th>
            <th className='rolelistBody_Name'>Role</th>
            <th className='rolelistBody_Data-Created'>Date Created</th>
            <th className='rolelistBody_Status'>Status</th>
            <th className='rolelistBody_Actions'>Actions</th>
            {/* <th className='rolelistBody_Delete'>Delete</th> */}
          </tr>
        </thead>

        {paginatedData?.length > 0
          ? paginatedData?.map((role, index) => (
              <tbody className='rolelistBody_table_body'>
                <tr key={role._id} className={swicthAction ? "" : "body-color"}>
                  <td>{startIndex + index + 1}</td>
                  <td className='capital'>{role?.roleName}</td>
                  <td>
                    {" "}
                    {role?.date
                      ? moment(role?.date).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  <td>
                    <Switch
                      className='rolelistBody_Switch'
                      checked={role?.status === "active" ? true : false}
                      onChange={() => onChange(role)}
                    />
                  </td>
                  <td>
                    <span
                      className='rolistBody_Edit'
                      onClick={() => {
                        if (role?.status === "active") {
                          setRoleName(role?.roleName);
                          setSelectedItem(role);
                          setActionModal(true);
                          setCheckedFeatures(role?.features);
                        }
                      }}>
                      <MdOutlineModeEdit style={{ fontSize: "18px" }} />
                    </span>
                  </td>
                </tr>
              </tbody>
            ))
          : ""}
      </table>
      {paginatedData?.length > 0 ? (
        ""
      ) : (
        <div className='no-data-message'>
          <h6>No data found</h6>
        </div>
      )}
      <div className='Pagination-container'>
        {rolesData?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={rolesData?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
      <Modal
        visible={actionModal}
        onCancel={() => setActionModal(false)}
        centered
        footer={null}
        className='custom-modal'>
        <EditRole
          close={close}
          setAddActivity={setAddActivity}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          checkedFeatures={checkedFeatures}
          setCheckedFeatures={setCheckedFeatures}
          fetchRolesData={fetchRolesData}
          roleName={roleName}
          setRoleName={setRoleName}
        />
      </Modal>
      <Modal
        visible={addActivity}
        onCancel={() => setAddActivity(false)}
        centered
        footer={null}
        className='custom-modal'>
        <AddActivity
          close={close}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          featuresData={featuresData}
          checkedFeatures={checkedFeatures}
          setCheckedFeatures={setCheckedFeatures}
          setActionModal={setActionModal}
          roleName={roleName}
          setRoleName={setRoleName}
        />
      </Modal>
      {/* Delete Role Modal */}
      {/* <Modal
        visible={deleteRole}
        onCancel={() => setDeleteRole(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='delete-role-container'>
          <div className='delete-role-content'>
            <h5>
              Do you want to delete this {selectedItem?.roleName} Role from Role
              Management?
            </h5>
            <div className='delete-role-actions'>
              <button
                className='delete-role-no'
                onClick={() => {
                  setDeleteRole(false);
                }}>
                <p>No</p>
              </button>
              <button
                className='delete-role-delete'
                onClick={() => {
                  // setDeleteRole(false);
                  // setSuccessDeleteModal(true);
                  DeleteRole();
                }}>
                <p>Delete</p>
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
      {/* <Modal
        visible={deleteSuccessModal}
        onCancel={() => setSuccessDeleteModal(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='delete-role-container'>
          <div className='delete-role-content'>
            <div className='delete-success-content'>
              <div>
                <img src='assets/images/success_image.png' alt='' />
              </div>
              <h4>
                {selectedItem?.roleName} Role Deleted <br /> Successfully
              </h4>
            </div>
            <div className='delete-role-success-actions'>
              <button
                className='delete-role-sucess-ok'
                onClick={() => setSuccessDeleteModal(false)}>
                <p>Ok</p>
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
      <Modal
        visible={roleActive}
        onCancel={() => setRoleActive(false)}
        centered
        footer={null}
        className='custom-modal'>
        <Spin spinning={loading}>
          <div className='role-active-container'>
            <div className='role-active-content'>
              <h5 className='head-text'>
                Do you want to{" "}
                <span className='Secondry-color'>Deactivate</span> this Role?
              </h5>
              <div className='role-active-actions'>
                <button
                  className='role-active-no'
                  onClick={() => setRoleActive(false)}>
                  <p>No</p>
                </button>
                <button
                  className='role-active-delete'
                  onClick={() => {
                    toggleStatus();
                  }}>
                  <p>Yes</p>
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={roleDeactive}
        onCancel={() => setRoleDeactive(false)}
        centered
        footer={null}
        className='custom-modal'>
        <Spin spinning={loading}>
          <div className='role-active-container'>
            <div className='role-active-content'>
              <h5>
                Are you want to <span className='primary-color'>Active</span>{" "}
                this role
              </h5>
              <div className='role-active-actions'>
                <button
                  className='role-active-no'
                  onClick={() => setRoleDeactive(false)}>
                  <p>No</p>
                </button>
                <button
                  className='role-active-delete'
                  onClick={() => {
                    toggleStatus();
                  }}>
                  <p>Yes</p>
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={activeSuccess}
        onCancel={() => setActiveSuccess(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='active-success-container'>
          <div className='active-success-content'>
            <div>
              <img src='assets/images/success_image.png' alt='' />
            </div>
            <h5>
              Role Activated <br />
              Successfully
            </h5>
          </div>
        </div>
      </Modal>
      <Modal
        visible={deactiveSuccess}
        onCancel={() => setDeactiveSuccess(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='active-success-container'>
          <div className='active-success-content'>
            <div>
              <img src='assets/images/success_image.png' alt='' />
            </div>
            <h5>
              Role Deactivated <br />
              Successfully
            </h5>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RoleListBody;
