import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Input, Menu } from "antd";
import { BsFilter } from "react-icons/bs";
import { MdOutlineSort } from "react-icons/md";

const CustomerBookingProfileCard = ({
  searchInput,
  setSearchInput,
  checkedFilter,
  setCheckedFilter,
}) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const handleFilterClick = () => {
    console.log("Filtering...");
  };
  const handleSortClick = () => {
    console.log("Sort List");
  };
  const handleFilterChange = (e) => {
    if (e.target.checked === true) {
      setCheckedFilter(e.target.name);
    } else {
      setCheckedFilter("");
    }
    console.log(e);
    setFilterVisible(false);
  };

  const filterMenu = (
    <Menu>
      <Menu.Item key='1'>
        <Checkbox
          name='quantity'
          checked={checkedFilter === "quantity"}
          onChange={handleFilterChange}>
          Quantity
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='2'>
        <Checkbox
          name='paid'
          checked={checkedFilter === "paid"}
          onChange={handleFilterChange}>
          Paid
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='3'>
        <Checkbox
          name='credit'
          checked={checkedFilter === "credit"}
          onChange={handleFilterChange}>
          Credit
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='4'>
        <Checkbox
          name='Inprogress'
          checked={checkedFilter === "Inprogress"}
          onChange={handleFilterChange}>
          In Progress
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='5'>
        <Checkbox
          name='Packed'
          checked={checkedFilter === "Packed"}
          onChange={handleFilterChange}>
          Packing Completed
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='6'>
        <Checkbox
          name='Delivered'
          checked={checkedFilter === "Delivered"}
          onChange={handleFilterChange}>
          Delivered
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='7'>
        <Checkbox
          name='Damaged'
          checked={checkedFilter === "Damaged"}
          onChange={handleFilterChange}>
          Damaged
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='7'>
        <Checkbox
          name='Return'
          checked={checkedFilter === "Return"}
          onChange={handleFilterChange}>
          Returned
        </Checkbox>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className='BookingProfileCard-container'>
      <div className='BookingProfileCard-content'>
        <div className='BookingProfile_Left_Contant'>
          <Input
            type='search'
            placeholder='Search Booking'
            className='search-input'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            suffix={<SearchOutlined className='search-icon' />}
          />

          <div className='customer-history-filter'>
            <Dropdown
              overlay={filterMenu}
              trigger={["click"]}
              visible={filterVisible}
              onVisibleChange={setFilterVisible}>
              <button className='history-filter-button capital'>
                <span> {checkedFilter ? checkedFilter : "Filter"} </span>
                <BsFilter size={17} className='OfflineSalesCard_filter-icon' />
              </button>
            </Dropdown>
          </div>
          {/* <div className='BookingProfile_sort_b'>
            <button
              className='BookingProfile_sort-button'
              onClick={handleSortClick}>
              <span> Sort </span>
              <MdOutlineSort size={17} className='BookingProfile_sort-icon' />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CustomerBookingProfileCard;
