import { Form, Input, Checkbox, Col, Row, message } from "antd";
import { ImCancelCircle } from "react-icons/im";
import React, { useState } from "react";
import { postAuthApiData, putAuthApiData } from "../../api/apiService";
import { IoMdAdd } from "react-icons/io";
import useLogout from "../../utils/authUtils";

const AddRoleReview = ({
  close,
  setAddRoleModal,
  checkedActivities,
  setCheckedActivities,
  roleName,
  fetchRolesData,
  setAddAddActivity,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const logout = useLogout();

  console.log(checkedActivities);

  const onFinish = async (values) => {
    console.log(values);

    const Endpoint = "addRole";
    try {
      setLoading(true);
      const filteredActivities = checkedActivities.map((activity) => ({
        featureId: activity.featureId,
      }));
      const userData = { roleName: roleName, features: filteredActivities };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Category created successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        close();
        message.success(data?.message);
        fetchRolesData();
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
        message.error(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Role creation failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (itemToRemove) => {
    const updatedFeatures = checkedActivities?.filter(
      (item) => item !== itemToRemove
    );
    // const updatedSelectedItem = { features: updatedFeatures };
    // setSelectedItem(updatedSelectedItem);
    console.log(updatedFeatures);
    setCheckedActivities(updatedFeatures);
  };

  return (
    <div className='Add-role-review-container'>
      <div className='Add-role-content'>
        <Form
          form={form}
          name='normal_login'
          className='edit-profile-form'
          layout='vertical'
          onFinish={onFinish}>
          <Form.Item name='role' label='Role Name'>
            <h6 className='role-name'>{roleName}</h6>
          </Form.Item>
          <Form.Item
            name='selectedActivites'
            label={`Selected Activities (${checkedActivities?.length})`}>
            <div
              style={{
                width: "100%",
                marginTop: "10px",
              }}>
              <Row style={{ marginBottom: "10px" }} gutter={8}>
                {checkedActivities?.length > 0
                  ? checkedActivities.map((item) => (
                      <Col
                        span={8}
                        style={{ marginBottom: "12px" }}
                        key={item?._id}>
                        <div className='activity-section'>
                          <p className='activity-name'>{item?.featureName}</p>
                          <ImCancelCircle
                            onClick={() => handleRemove(item)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Col>
                    ))
                  : ""}
              </Row>
            </div>
          </Form.Item>
          <div className='add-role-footer'>
            <div
              className='Add-activity'
              onClick={() => {
                close();
                setAddAddActivity(true);
              }}>
              <IoMdAdd />
              <p>Add Actvity</p>
            </div>
            <div className='add-role-submit'>
              <div className='add-role-cancel-button' onClick={close}>
                <p>Cancel</p>
              </div>
              <button
                type='primary'
                htmlType='submit'
                className='add-role-submit-button'>
                <p>Submit</p>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddRoleReview;
