import React, { useState } from "react";
import { Button, Form, Input, Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import "./Forgot.css";
import { postAuthApiData } from "../../api/apiService";
import { useDispatch, useSelector } from "react-redux";
import { updateEmail } from "../../redux/action";
import useLogout from "../../utils/authUtils";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const givenEmail = useSelector((state) => state.email);
  const logout = useLogout();

  const onFinish = async (values) => {
    setLoading(true);
    const Endpoint = "forgotPassword";
    try {
      setLoading(true);
      const formattedEmail = values?.email?.toLowerCase();
      const userData = {
        email: formattedEmail,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Forgot Password fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setSubmitted(true);
        dispatch(updateEmail(values.email));
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Forgot Password fetched failed:", error);
      message.error("Something went wrong please try later");
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className='forgot-password-container'>
        <div className='forgot-password-content'>
          <div className='forgot-left-section'>
            <div>
              <img
                src='assets/images/login-image.png'
                alt='Login illustration'
              />
            </div>
            <h3 className='white-color'>Welcome to JPF</h3>
          </div>
          <div className='forgot-right-section'>
            {givenEmail ? ( // Conditional rendering based on submission
              <>
                <h2>Forgot Password?</h2>
                <p>We have sent you an email to reset new password</p>
                <p>
                  Check your registered email{" "}
                  {givenEmail.replace(/(.{3})(.*)(?=@)/, "$1********")}
                </p>
                <div style={{ width: "60%" }}>
                  <button
                    className='change-next-btn'
                    onClick={() => navigate("/newpassword")}>
                    <p style={{ color: "white" }}>Next</p>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className='head-title'>
                  <h3>Forgot Password?</h3>
                  <p>No worries! Please provide your registered email here</p>
                </div>
                <div className='forget_right_emailform'>
                  <Form form={form} onFinish={onFinish}>
                    <label>
                      <div
                        className='forgot_emailaddress'
                        style={{ marginBottom: "8px" }}>
                        Email Address
                      </div>
                    </label>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email address",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}>
                      <Input
                        size='large'
                        placeholder='Enter your registered email'
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item className='forget_next_btn'>
                      <button
                        type='primary'
                        htmlType='submit'
                        loading={loading}>
                        <p className='forgot_submit-btn'>Next</p>
                      </button>
                    </Form.Item>
                  </Form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ForgotPassword;
