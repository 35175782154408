import { message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../redux/action";

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("User is Logging out");

  const logout = () => {
    localStorage.removeItem("jpf_token");
    localStorage.removeItem("orderBookingId");
    localStorage.removeItem("onlineOrderBookingId");
    dispatch(logoutUser());
    navigate("/");
    message.success("User logged-out successfully");
  };

  return logout;
};

export default useLogout;
