import { Form, Input, Modal, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import Image from "../Categories/Image.png";
import { putAuthApiData } from "../../api/apiService";
import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import useLogout from "../../utils/authUtils";
import { ImCross } from "react-icons/im";
import { CloudUploadOutlined } from "@ant-design/icons";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";
import { useDropzone } from "react-dropzone";

const EditEmployee = ({
  close,
  selectedItem,
  fetchEmployeeList,
  rolesData,
}) => {
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const logout = useLogout();
  const [file, setFile] = useState(null);

  const onFinish = async (values) => {
    console.log(values);
    const Endpoint = "editEmployee";
    try {
      setLoading(true);
      const formattedEmail = values?.email?.toLowerCase();
      const userData = {
        employeId: selectedItem?._id,
        username: values.username,
        email: formattedEmail || "",
        // phoneNumber: values.phoneNumber,
        role_Id: values.role,
        image: categoryImage,
      };
      const { data, loading: postLoading } = await putAuthApiData(
        Endpoint,
        userData
      );
      console.log("Employee Updated successful:", data);
      setLoading(postLoading);
      if (data?.responseCode === 200) {
        console.log(data.message);
        // message.success(data?.message);
        close();
        fetchEmployeeList();
        setSuccessModal(true);
        form.resetFields();
      } else if (data?.responseCode === 400) {
        message.warning(data?.message);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Employee Updating failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      role: selectedItem?.role_Id,
      username: selectedItem?.username,
      // phoneNumber: selectedItem?.phoneNumber,
      email: selectedItem?.email,
    });
    setCategoryImage(selectedItem?.image);
    setFile(selectedItem?.image);
  }, [selectedItem]);

  const handleRoleChange = (value) => {
    console.log(value);
  };
  console.log(selectedItem);

  let employeeWarningShown = false; // Flag to track if the warning has been shown

  const validateEmployee = (_, value) => {
    // Check if the value contains invalid characters (anything other than letters and spaces)
    if (value && !/^[a-zA-Z\s]+$/.test(value)) {
      if (!employeeWarningShown) {
        message.warning("Employee must contain only letters and spaces");
        employeeWarningShown = true; // Set the flag after showing the warning
      }
      return Promise.reject(
        new Error("Employee must contain only letters and spaces")
      );
    }

    // Reset the flag if the input becomes valid
    employeeWarningShown = false;

    // Check if the length is less than 5 characters
    if (value && value.length < 5) {
      return Promise.reject(
        new Error("Employee must be at least 5 characters")
      );
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(
        new Error("Employee must be at most 16 characters")
      );
    }

    return Promise.resolve();
  };

  const handleEmployeeChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 17);
    form.setFieldsValue({ username: cleanedValue });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        if (file.size > 5242880) {
          message.error("File size exceeds the maximum limit of 5MB.");
          return;
        }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });

  return (
    <Spin spinning={loading}>
      <div className='AddEmploy-container'>
        <div className='AddEmploy-content'>
          <Form
            form={form}
            name='normal_login'
            className='edit-profile-form'
            layout='vertical'
            onFinish={onFinish}>
            <Form.Item
              name='role'
              label='Select Employee Role'
              // initialValue={selectedItem?.role_Id[0]}
              rules={[
                {
                  required: true,
                  message: "Please enter employee role",
                },
              ]}>
              <Select
                placeholder='Select Employee Role'
                allowClear
                defaultValue='Manager'
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleRoleChange}>
                {rolesData
                  ?.filter((item) => item?.status === "active")
                  ?.map(({ _id, roleName }) => (
                    <Select.Option key={_id} value={_id}>
                      {roleName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='username'
              label='Employee Name'
              // initialValue={selectedItem?.username}
              rules={[
                {
                  required: true,
                  message: "Please enter employee name",
                },
                { validator: validateEmployee },
              ]}>
              <Input
                type='text'
                placeholder='Enter Employee Name'
                onChange={handleEmployeeChange}
              />
            </Form.Item>

            {/* <Form.Item
              name='phoneNumber'
              label='Phone Number'
              // initialValue={selectedItem?.phoneNumber}
              rules={[
                {
                  type: "text",
                  message: "The input is not valid!",
                },
                { validator: validatePhoneNumber },

                {
                  required: true,
                  message: "Please input  Phone number!",
                },
              ]}>
              <Input type='text' placeholder='Enter Phone number' />
            </Form.Item> */}
            <Form.Item
              name='email'
              label='Email'
              // initialValue={selectedItem?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}>
              <Input type='email' placeholder='Enter Email' />
            </Form.Item>
            <div {...getRootProps()} class='upload-container'>
              <input {...getInputProps()} />
              {file && categoryImage ? (
                <div className='uploaded-image-section'>
                  <div className='fileUpload-image-div'>
                    <img src={file.preview || categoryImage} alt={file.name} />
                  </div>
                  <ImCross
                    className='text-md font-light cursor-pointer'
                    onClick={() => {
                      setFile(null);
                      setCategoryImage("");
                    }}
                  />
                </div>
              ) : (
                <CloudUploadOutlined className='cloud-icon' />
              )}
              <div className='upload-text-section'>
                <p class='upload-text'>
                  Browse/drag to {file && categoryImage ? "change" : "upload"}{" "}
                  File
                </p>
                <p class='file-size-text'>File Size must be below 5 Mb</p>
              </div>
            </div>
            <Form.Item>
              <div className='add-Employ-submit'>
                <div className='add-Employ-cancel-button' onClick={close}>
                  <p>Cancel</p>
                </div>
                <button
                  type='primary'
                  htmlType='submit'
                  className='add-Employ-submit-button'>
                  <p>Submit</p>
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className='custom-modal'>
          <div className='Employ-success-container'>
            <div className='Employ-success-content'>
              <div>
                <img src='assets/images/success_image.png' alt='' />
              </div>
              <h5>
                Employee Details Updated <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default EditEmployee;
