import React, { useState, useEffect } from "react";
import { Button, Form, Input, Spin, Upload, message } from "antd";
import Image from "../Categories/Image.png";
import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import { putAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";
import { useDropzone } from "react-dropzone";
import { CloudUploadOutlined } from "@ant-design/icons";
import { ImCross } from "react-icons/im";
import moment from "moment";

const OnlineSaleCreditPopup = ({
  installmentsData,
  orderBookingId,
  setCreditPayModal,
  fetchOfflineSalesList,
}) => {
  const [isPaid, setIsPaid] = useState(false);
  const [form] = Form.useForm();
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [categoryImage, setCategoryImage] = useState("");
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [balanceAmount, setBalanceAmount] = useState();
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const logout = useLogout();
  const [file, setFile] = useState(null);

  console.log(installmentsData);

  const handlePaymentChange = (e) => {
    const value = parseFloat(e.target.value);
    const balance = parseFloat(installmentsData?.dueAmount - value);
    if (value > installmentsData?.dueAmount) {
      setAmount(0);
      setBalanceAmount(0);
      message.warning("Entering amount is exceeding due amount");
      form.setFieldValue("balance", 0);
    } else if (value <= installmentsData?.dueAmount && value > 0) {
      setAmount(value);
      setBalanceAmount(parseFloat(balance.toFixed(2)));
      form.setFieldValue("balance", balance);
      console.log("value is greater than 0 and less than due");
    } else if (e.target.value === "") {
      setAmount(0);
      setBalanceAmount(0);
      form.setFieldValue("balance", 0);
    }
  };

  console.log(amount, balanceAmount);

  const onFinish = async (values) => {
    console.log(values);
    if (amount > 0 && amount <= installmentsData?.dueAmount) {
      const Endpoint = "addOnlineInstallment";
      try {
        setLoading(true);
        const userData = {
          orderBookingId: orderBookingId,
          amount: amount,
          dueAmount: balanceAmount,
          paymentMethod: paymentMethod,
          paymentMethodType: "creditpay",
        };
        if (paymentMethod === "Cheque") {
          userData.chequeImage = categoryImage;
        }
        const { data, loading: postLoading } = await putAuthApiData(
          Endpoint,
          userData
        );
        console.log("Installment Updated successful:", data);
        setLoading(postLoading);
        if (data?.responseCode === 200) {
          console.log(data.message);
          form.resetFields();
          setCreditPayModal(false);
          setCategoryImage("");
          setFile(null);
          fetchOfflineSalesList();
          setBalanceAmount(0);
          setAmount();
          message.success(data?.message);
        } else if (data?.responseCode === 400) {
          message.warning(data?.message);
          console.log(data?.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } catch (error) {
        console.log("Installment Updated failed:", error);
        message.error(error);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    } else {
      message.warning("Please enter valid amount");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        if (file.size > 5242880) {
          message.error("File size exceeds the maximum limit of 5MB.");
          return;
        }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });
  return (
    <Spin spinning={loading}>
      <div className='credit-pay-popup'>
        <div>
          <h5>Credit</h5>
        </div>
        <p className='credit-pay_model_Header'>
          Total Payable: ₹{installmentsData?.totalAmount?.toFixed(2)}
        </p>
        <table className='credit-pay_model_table'>
          <thead className='credit-pay_model_table_head'>
            <tr>
              <th>Installments</th>
              <th>Date</th>
              <th style={{ textAlign: "center" }}>Amount</th>
            </tr>
          </thead>
          <tbody className='credit-pay_model_table_body'>
            {installmentsData?.installmentData?.map((installment, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {installment?.date
                    ? moment(installment.date).format("DD-MM-YYYY")
                    : "--"}
                </td>
                <td style={{ textAlign: "center" }}>
                  ₹ {installment.amount || "0000"}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan='2' className='balance'>
                Balance
              </td>
              <td className='balance-amount' style={{ textAlign: "center" }}>
                ₹ {installmentsData?.dueAmount?.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
        {installmentsData?.dueAmount > 0 ? (
          <Form
            form={form}
            name='normal_login'
            initialValues={{
              remember: true,
            }}
            layout='vertical'
            onFinish={onFinish}>
            <div className='pay-balance-section'>
              <h5>Pay Balance</h5>
              <div className='Pay-balance-inputs-section'>
                <div className='balance-inputs'>
                  <Form.Item
                    name='amount'
                    label='Enter Amount'
                    rules={[
                      {
                        required: true,
                        message: "Please enter Amount",
                      },
                    ]}>
                    <Input
                      placeholder='0000'
                      value={amount}
                      onChange={handlePaymentChange}
                      className='balance_enter-amount'
                    />
                  </Form.Item>
                </div>
                <div className='balance-inputs'>
                  <Form.Item
                    name='balance'
                    label='Balance Amount'
                    value={balanceAmount}
                    rules={[
                      {
                        required: false,
                        message: "Please enter Balance amount",
                      },
                    ]}>
                    <Input
                      placeholder='000'
                      defaultValue={balanceAmount}
                      disabled
                      className='balance-input'
                    />
                  </Form.Item>
                </div>
              </div>

              <div className='payment-buttons'>
                <Button
                  className={`cash-button ${
                    paymentMethod === "Cash" ? "active" : ""
                  }`}
                  onClick={() => setPaymentMethod("Cash")}>
                  Cash
                </Button>
                <Button
                  className={`cheque-button ${
                    paymentMethod === "Cheque" ? "active" : ""
                  }`}
                  onClick={() => setPaymentMethod("Cheque")}>
                  Cheque
                </Button>
              </div>

              {paymentMethod === "Cheque" && (
                <div {...getRootProps()} class='upload-container'>
                  <input {...getInputProps()} />
                  {file && categoryImage ? (
                    <div className='uploaded-image-section'>
                      <div className='fileUpload-image-div'>
                        <img
                          src={file.preview || categoryImage}
                          alt={file.name}
                        />
                      </div>
                      <ImCross
                        className='text-md font-light cursor-pointer'
                        onClick={() => {
                          setFile(null);
                          setCategoryImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <CloudUploadOutlined className='cloud-icon' />
                  )}
                  <div className='upload-text-section'>
                    <p class='upload-text'>
                      Browse/drag to{" "}
                      {file && categoryImage ? "change" : "upload"} File
                    </p>
                    <p class='file-size-text'>File Size must be below 5 Mb</p>
                  </div>
                </div>
              )}
            </div>
            <div className='credit-pay_model_footer'>
              <Button
                className='model_footer_btn'
                type='primary'
                htmlType='submit'>
                Pay Now
              </Button>
            </div>
          </Form>
        ) : (
          ""
        )}
      </div>
    </Spin>
  );
};

export default OnlineSaleCreditPopup;
