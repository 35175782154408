import { Form, Input, Modal, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import Image from "../Categories/Image.png";
import { postAuthApiData } from "../../api/apiService";
import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import useLogout from "../../utils/authUtils";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";
import { useDropzone } from "react-dropzone";
import { ImCross } from "react-icons/im";
import { CloudUploadOutlined } from "@ant-design/icons";

const AddEmployee = ({ close, rolesData, fetchEmployeeList }) => {
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const logout = useLogout();
  const [file, setFile] = useState(null);

  useEffect(() => {
    form.resetFields();
    setCategoryImage("");
    setFile(null);
  }, [close]);

  const onFinish = async (values) => {
    console.log(values);
    const Endpoint = "addEmploye";
    try {
      setLoading(true);
      const formattedEmail = values?.email?.toLowerCase();
      const userData = {
        username: values.username,
        email: formattedEmail,
        phoneNumber: values.phoneNumber,
        password: values.password,
        role_Id: values.role,
        image: categoryImage,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Employee Added successful:", data);
      setLoading(postLoading);
      console.log(data.message);
      if (data?.responseCode === 200) {
        close();
        form.resetFields();
        fetchEmployeeList();
        setCategoryImage("");
        setFile(null);
        setSuccessModal(true);
      } else if (data?.responseCode === 400) {
        message.warning(data?.message);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Employee Adding failed:", error);
      // Check for specific error conditions and show appropriate messages
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes("duplicate email")) {
          message.error(
            "This email is already associated with an existing employee."
          );
        } else if (errorMessage.includes("duplicate role")) {
          message.error("An employee with this role already exists.");
        } else {
          message.error(
            "User already exists with this phone number or email. Please try another"
          );
        }
      } else {
        message.error(
          "User already exists with this phone number or email. Please try another"
        );
      }
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(rolesData);

  const handleRoleChange = async (value) => {
    console.log(value);
  };

  let hasShownPhoneWarning = false; // to track if the warning has been shown

  const validatePhoneNumber = (_, value) => {
    if (value && /[^\d]/.test(value)) {
      if (!hasShownPhoneWarning) {
        message.warning("Phone number must contain only numbers");
        hasShownPhoneWarning = true; // set the flag to true after showing the warning
      }
      return Promise.reject(
        new Error("Phone number must contain only numbers")
      );
    }

    if (value && !/^[9876]/.test(value)) {
      if (!hasShownPhoneWarning) {
        message.warning("Phone number must start with 9, 8, 7, or 6");
        hasShownPhoneWarning = true;
      }
      return Promise.reject(
        new Error("Phone number must start with 9, 8, 7, or 6")
      );
    }

    if (value && value.replace(/\D/g, "").length !== 10) {
      hasShownPhoneWarning = false; // reset the flag if the input is valid or incomplete
      return Promise.reject("Phone number must be 10 digits");
    }

    // Reset the warning flag if the input is valid (only numbers and exactly 10 digits)
    hasShownPhoneWarning = false;
    return Promise.resolve();
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
    if (cleanedValue && /^[012345]/.test(cleanedValue)) {
      form.setFieldsValue({ phoneNumber: cleanedValue.slice(1) });
    } else {
      // Allow clearing the input
      form.setFieldsValue({ phoneNumber: cleanedValue });
    }
  };

  let employeeWarningShown = false; // Flag to track if the warning has been shown

  const validateEmployee = (_, value) => {
    // Check if the value contains invalid characters (anything other than letters and spaces)
    if (value && !/^[a-zA-Z\s]+$/.test(value)) {
      if (!employeeWarningShown) {
        message.warning("Employee must contain only letters and spaces");
        employeeWarningShown = true; // Set the flag after showing the warning
      }
      return Promise.reject(
        new Error("Employee must contain only letters and spaces")
      );
    }

    // Reset the flag if the input becomes valid
    employeeWarningShown = false;

    // Check if the length is less than 5 characters
    if (value && value.length < 5) {
      return Promise.reject(
        new Error("Employee must be at least 5 characters")
      );
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(
        new Error("Employee must be at most 16 characters")
      );
    }

    return Promise.resolve();
  };

  const handleEmployeeChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 17);
    form.setFieldsValue({ username: cleanedValue });
  };

  const validatePassword = (_, value) => {
    const passwordCriteria = [
      { regex: /[a-z]/, message: "at least one lowercase letter" },
      { regex: /[A-Z]/, message: "at least one uppercase letter" },
      { regex: /[0-9]/, message: "at least one digit" },
      { regex: /[\W_]/, message: "at least one special character" },
    ];
    for (const criterion of passwordCriteria) {
      if (!criterion.regex.test(value)) {
        return Promise.reject(`Password must contain ${criterion.message}`);
      }
    }
    return Promise.resolve();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        if (file.size > 5242880) {
          message.error("File size exceeds the maximum limit of 5MB.");
          return;
        }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });

  return (
    <Spin spinning={loading}>
      <div className='AddEmploy-container'>
        <div className='AddEmploy-content'>
          <Form
            form={form}
            name='normal_login'
            className='edit-profile-form'
            layout='vertical'
            onFinish={onFinish}>
            <Form.Item
              name='role'
              label='Select Employee Role'
              rules={[
                {
                  required: true,
                  message: "Please enter employee role",
                },
              ]}>
              <Select
                placeholder='Select Employee Role'
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleRoleChange}>
                {rolesData
                  ?.filter((item) => item?.status === "active")
                  ?.map(({ _id, roleName }) => (
                    <Select.Option key={_id} value={_id}>
                      {roleName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='username'
              label='Employee Name'
              rules={[
                {
                  required: true,
                  message: "Please enter employee name",
                },
                { validator: validateEmployee },
              ]}>
              <Input
                type='text'
                placeholder='Enter Employee Name'
                onChange={handleEmployeeChange}
              />
            </Form.Item>
            <Form.Item
              name='password'
              label='Password'
              rules={[
                {
                  required: true,
                  message: "Please enter password",
                },
                {
                  min: 8,
                  message: "Password must be at least 8 characters",
                },
                {
                  max: 32,
                  message: "Password must be at most 32 characters",
                },
                { validator: validatePassword },
              ]}>
              <Input.Password type='password' placeholder='Enter Password' />
            </Form.Item>
            <Form.Item
              name='phoneNumber'
              label='Phone Number'
              rules={[
                {
                  type: "text",
                  message: "The input is not valid!",
                },
                { validator: validatePhoneNumber },

                {
                  required: true,
                  message: "Please enter Phone number!",
                },
              ]}>
              <Input
                type='text'
                placeholder='Enter Phone number'
                onChange={handlePhoneChange}
              />
            </Form.Item>
            <Form.Item
              name='email'
              label='Email'
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}>
              <Input type='email' placeholder='Enter Employee Email' />
            </Form.Item>

            <div {...getRootProps()} class='upload-container'>
              <input {...getInputProps()} />
              {file && categoryImage ? (
                <div className='uploaded-image-section'>
                  <div className='fileUpload-image-div'>
                    <img src={file.preview || categoryImage} alt={file.name} />
                  </div>
                  <ImCross
                    className='text-md font-light cursor-pointer'
                    onClick={() => {
                      setFile(null);
                      setCategoryImage("");
                    }}
                  />
                </div>
              ) : (
                <CloudUploadOutlined className='cloud-icon' />
              )}
              <div className='upload-text-section'>
                <p class='upload-text'>
                  Browse/drag to {file && categoryImage ? "change" : "upload"}{" "}
                  File
                </p>
                <p class='file-size-text'>File Size must be below 5 Mb</p>
              </div>
            </div>
            <Form.Item>
              <div className='add-Employ-submit'>
                <div className='add-Employ-cancel-button' onClick={close}>
                  <p>Cancel</p>
                </div>
                <button
                  type='primary'
                  htmlType='submit'
                  className='add-Employ-submit-button'>
                  <p>Submit</p>
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className='custom-modal'>
          <div className='Employ-success-container'>
            <div className='Employ-success-content'>
              <div>
                <img src='assets/images/success_image.png' alt='' />
              </div>
              <h5>
                Employee Added <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default AddEmployee;
