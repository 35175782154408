import React, { useEffect, useState } from "react";
import { Avatar, Pagination, Select, Button, Modal } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import moment from "moment";
// import 'antd/dist/antd.css';

const { Option } = Select;

const OrderMListBody = ({ ordersList, fetchOrdersManagementsList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceModal, setInvoiceModal] = useState(false);
  const pageSize = 10;
  console.log(ordersList);

  const handleInvoice = async () => {
    await setInvoiceModal(true);
  };

  // useEffect(() => {
  //   console.log("Calling useEffect");
  //   const disptchedProducts = ordersList?.filter(
  //     (product) => product.orderStatus === "Packing Completed"
  //   );
  //   const deliveredProducts = ordersList?.filter(
  //     (product) => product.orderStatus === "Delivered"
  //   );
  //   const returnProducts = ordersList?.filter(
  //     (product) => product.orderStatus === "Return"
  //   );
  //   const damagedProducts = ordersList?.filter(
  //     (product) => product.orderStatus === "Damaged"
  //   );
  //   setTotalCount(ordersList?.length);
  //   setDamagedCount(damagedProducts?.length);
  //   setDeliveredCount(deliveredProducts?.length);
  //   setDispatchedCount(disptchedProducts?.length);
  //   setReturnsCount(returnProducts?.length);
  // }, [ordersList]);

  const getBackgroundColor = (status) => {
    switch (status) {
      case "Delivered":
        return "#14B8A6";
      case "Packing Completed":
        return "#14B8A6";
      case "InProgress":
        return "#FFC727";
      case "Return":
        return "#EF4444";
      case "Damaged":
        return "#EF4444";
      default:
        return "lightgray";
    }
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [ordersList]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = ordersList?.slice(startIndex, endIndex);

  return (
    <>
      <div className='OrderM_List_Container'>
        <table className='OrderM_table_Main'>
          <thead className='OrderM_Table_head'>
            <tr className='OrderM_tableH-row'>
              <th className='th1'>S.No</th>
              <th className='th2'>Order ID</th>
              <th className='th3'>Customer</th>
              <th className='th4'>Date</th>
              <th className='th5'>Price</th>

              <th className='th6'>Units/Packets</th>
              <th className='th7'>Payment Mode</th>
              <th className='th8'>Status</th>
              <th className='th9'>Invoice</th>
            </tr>
          </thead>
          <tbody className='OrderM_tableB_row'>
            {paginatedData?.length > 0
              ? paginatedData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{item?.bookingId}</td>
                      {/* <td style={{ maxWidth: "150px" }}>
                  <div className='OrderM_product-name capital'>
                    {item?.productImage ? (
                      <Avatar
                        src={item?.productImage}
                        size={40}
                        className='image'
                      />
                    ) : (
                      <Avatar
                        shape='circle'
                        className='image'
                        size={40}
                        style={{
                          backgroundColor: "#6750A4",
                          fontWeight: 600,
                          fontSize: 17,
                        }}>
                        {item?.productName?.length > 0 &&
                          item?.productName[0]?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    )}
                    <div>{item?.productName}</div>
                  </div>
                </td> */}
                      <td className='capital'>{item?.customerName}</td>
                      <td>
                        {item?.date
                          ? moment(item.date).format("DD-MM-YYYY")
                          : "--"}
                      </td>
                      <td>{item?.totalInfoPrice?.toFixed(2)}</td>
                      <td>
                        {item?.totalQuantityOfProducts !== 0
                          ? `${item?.totalQuantityOfProducts?.toFixed(1)} Kg [${
                              item?.totalPackets
                            } Pkt]`
                          : `${item?.totalPackets} Pkt`}
                      </td>
                      {/* <td>{`${item?.totalPackets} Pkt`}</td> */}
                      <td className='capital'>{item?.paymentMethod || "-"}</td>
                      <td>
                        <div
                          className='order-status capital'
                          style={{
                            backgroundColor: getBackgroundColor(
                              item?.orderStatus
                            ),
                          }}>
                          {item?.orderStatus}
                        </div>
                      </td>
                      <td className='OrderM_view'>
                        <Button
                          type='OrderM_view-btn'
                          onClick={() => {
                            setInvoiceUrl(item?.invoiceUrl);
                            handleInvoice();
                          }}
                          style={{ cursor: "pointer" }}>
                          View
                        </Button>
                      </td>
                      {/* <td className='OrderM_Action'>
                  <MoreOutlined size={44} />
                </td> */}
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
        {paginatedData?.length === 0 && (
          <div className='no-data-message'>
            <h6>No data found</h6>
          </div>
        )}

        <Modal
          visible={invoiceModal}
          onCancel={() => setInvoiceModal(false)}
          closeIcon={false}
          centered
          footer={null}>
          <div className='invoice-container'>
            <div>
              <iframe src={invoiceUrl} width='100%' height='550px' />
            </div>
            <div style={{ textAlign: "end" }}>
              <button
                onClick={() => {
                  setInvoiceModal(false);
                  setInvoiceUrl("");
                }}
                className='close-btn'>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div className='OrderM_Pagination'>
        {ordersList?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={ordersList?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </>
  );
};

export default OrderMListBody;
