import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, message, Spin } from "antd";
import UploadImage from "../Categories/Image.png";
import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import Image from "../Categories/Image.png";
import { postAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";
import { useDropzone } from "react-dropzone";
import { ImCross } from "react-icons/im";
import { CloudUploadOutlined } from "@ant-design/icons";
import LocationSearch from "../../utils/location";

const AddCustomer = ({
  closeModal,
  fetchCustomerList,
  showModal,
  setShowModal,
}) => {
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [categoryImage, setCategoryImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const logout = useLogout();
  const [file, setFile] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [area, setArea] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    form.resetFields();
    setCategoryImage("");
    setFile(null);
  }, [showModal, setShowModal, closeModal]);

  const handleStateChange = (value) => {
    console.log(value);
  };

  const handleCountryChange = (value) => {
    console.log(value);
  };

  const handleCityChange = (value) => {
    console.log(value);
  };

  useEffect(() => {
    form.setFieldValue("address", address);
  }, [address]);

  const onFinish = async (values) => {
    console.log(values);
    console.log(address);
    console.log(typeof latitude, latitude, typeof longitude, longitude);

    const Endpoint = "addCustomer";
    try {
      setLoading(true);
      const formattedEmail = values?.email?.toLowerCase();
      const userData = {
        name: values.customer_name,
        email: formattedEmail,
        phone: values.phone_number,
        address: values?.address || address || "",
        city: values?.city || "",
        country: values?.country || "",
        state: values?.state || "",
        pincode: values?.pincode || "",
        image: categoryImage,
        location: {
          type: "Point",
          coordinates: [latitude, longitude],
        },
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Customer Added successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        fetchCustomerList();
        setCategoryImage("");
        setFile(null);
        closeModal();
        form.resetFields();
        setAddress("");
        setLatitude(null);
        setLongitude(null);
        message.success(data?.message);
      } else if (data.responseCode === 400) {
        message.error(data.message);
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Customer Adding failed:", error);
      message.error(error);
      setLoading(false);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        if (file.size > 5242880) {
          message.error("File size exceeds the maximum limit of 5MB.");
          return;
        }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });

  let hasShownPhoneWarning = false; // to track if the warning has been shown

  const validatePhoneNumber = (_, value) => {
    if (value && /[^\d]/.test(value)) {
      if (!hasShownPhoneWarning) {
        message.warning("Phone number must contain only numbers");
        hasShownPhoneWarning = true; // set the flag to true after showing the warning
      }
      return Promise.reject(
        new Error("Phone number must contain only numbers")
      );
    }

    if (value && !/^[9876]/.test(value)) {
      if (!hasShownPhoneWarning) {
        message.warning("Phone number must start with 9, 8, 7, or 6");
        hasShownPhoneWarning = true;
      }
      return Promise.reject(
        new Error("Phone number must start with 9, 8, 7, or 6")
      );
    }

    if (value && value.replace(/\D/g, "").length !== 10) {
      hasShownPhoneWarning = false; // reset the flag if the input is valid or incomplete
      return Promise.reject("Phone number must be 10 digits");
    }

    // Reset the warning flag if the input is valid (only numbers and exactly 10 digits)
    hasShownPhoneWarning = false;
    return Promise.resolve();
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;

    // Clean the input to remove non-numeric characters and limit to 10 digits
    const cleanedValue = value.replace(/\D/g, "").slice(0, 10);

    // Check if the first digit is allowed (9, 8, 7, or 6)
    if (cleanedValue && /^[012345]/.test(cleanedValue)) {
      form.setFieldsValue({ phone_number: cleanedValue.slice(1) });
    } else {
      // Allow clearing the input
      form.setFieldsValue({ phone_number: cleanedValue });
    }
  };

  // const handleNumericInput = (e) => {
  //   const charCode = e.charCode;
  //   if (charCode < 48 || charCode > 57) {
  //     e.preventDefault();
  //     message.warning("Only numbers are allowed");
  //   }
  // };
  let pincodeWarningShown = false; // Flag to track if the warning has been shown

  const validatePinCodeNumber = (_, value) => {
    // Check for invalid characters (non-digits)
    if (value && /[^\d]/.test(value)) {
      if (!pincodeWarningShown) {
        message.warning("Pincode must contain only numbers");
        pincodeWarningShown = true; // Set the flag after showing the warning
      }
      return Promise.reject(new Error("Pincode must contain only numbers"));
    }

    // Reset the flag if the input is valid
    pincodeWarningShown = false;

    // Check if the length is exactly 6 digits
    if (value && value.replace(/\D/g, "").length !== 6) {
      return Promise.reject(new Error("Pincode must be 6 digits"));
    }

    return Promise.resolve();
  };

  const handlePinCodeChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/\D/g, "").slice(0, 6);
    form.setFieldsValue({ pincode: cleanedValue });
  };
  let nameWarningShown = false; // Flag to track if the warning has been shown

  const validateName = (_, value) => {
    // Check if the value contains invalid characters (anything other than letters and spaces)
    if (value && !/^[a-zA-Z\s]+$/.test(value)) {
      if (!nameWarningShown) {
        message.warning("Name must contain only letters and spaces");
        nameWarningShown = true; // Set the flag after showing the warning
      }
      return Promise.reject(
        new Error("Name must contain only letters and spaces")
      );
    }

    // Reset the flag if the input becomes valid
    nameWarningShown = false;

    // Check if the length is less than 5 characters
    if (value && value.length < 5) {
      return Promise.reject(new Error("Name must be at least 5 characters"));
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(new Error("Name must be at most 16 characters"));
    }

    return Promise.resolve();
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 17);
    form.setFieldsValue({ customer_name: cleanedValue });
  };

  const validateAddress = (_, value) => {
    if (value && value.length < 5) {
      // message.warning("Name must be at least 5 characters");
      return Promise.reject(new Error("Address must be at least 5 characters"));
    }

    if (value && value.length > 16) {
      // message.warning("Name must be at most 16 characters");
      return Promise.reject(new Error("Address must be at most 16 characters"));
    }

    return Promise.resolve();
  };

  const handleAddressChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.slice(0, 17);
    form.setFieldsValue({ address: cleanedValue });
  };

  return (
    <Spin spinning={loading}>
      <div className='Shopkeeper-add-container'>
        <div className='Shopkeeper-add-form'>
          <Form
            form={form}
            name='normal_login'
            initialValues={{
              remember: true,
            }}
            layout='vertical'
            onFinish={onFinish}>
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <Form.Item
                  name='customer_name'
                  label='Customer Name'
                  rules={[
                    {
                      required: true,
                      message: "Please enter customer name!",
                    },
                    { validator: validateName },
                  ]}>
                  <Input
                    placeholder='Customer Name'
                    onChange={handleNameChange}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <Form.Item
                  name='email'
                  label='Email'
                  rules={[
                    {
                      required: false,
                      message: "Please enter Email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}>
                  <Input placeholder='Email' type='email' allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <Form.Item
                  name='phone_number'
                  label='Phone Number'
                  rules={[
                    {
                      required: true,
                      message: "Please enter Phone number",
                    },
                    { validator: validatePhoneNumber },
                  ]}>
                  <Input
                    type='tel'
                    allowClear
                    onChange={handlePhoneChange}
                    placeholder='Phone Number'
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={10}>
              <Col xs={12} sm={12} lg={12}>
                <Form.Item
                  name='country'
                  label='Select Country'
                  rules={[
                    {
                      required: false,
                      message: "Please Select Country",
                    },
                  ]}>
                  <Select
                    placeholder='Select Country'
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleCountryChange}>
                    <Select.Option value='India'>India</Select.Option>
                    <Select.Option value='Australia'>Australia</Select.Option>
                    <Select.Option value='America'>America</Select.Option>
                    <Select.Option value='Pakistan'>Pakistan</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <Form.Item
                  name='state'
                  label='Select State'
                  rules={[
                    {
                      required: false,
                      message: "Please select state",
                    },
                  ]}>
                  <Select
                    placeholder='Select State'
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleStateChange}>
                    <Select.Option value='Andhra Pradesh'>
                      Andhra Pradesh
                    </Select.Option>
                    <Select.Option value='Telangana'>Telangana</Select.Option>
                    <Select.Option value='Odissa'>Odissa</Select.Option>
                    <Select.Option value='West Bengal'>
                      West Bengal
                    </Select.Option>
                    <Select.Option value='Karnataka'>Karnataka</Select.Option>
                    <Select.Option value='Kerala'>Kerala</Select.Option>
                    <Select.Option value='Rajasthan'>Rajasthan</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col xs={12} sm={12} lg={12}>
                <Form.Item
                  name='city'
                  label='Select City'
                  rules={[
                    {
                      required: false,
                      message: "Please Select City",
                    },
                  ]}>
                  <Select
                    placeholder='Select City'
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleCityChange}>
                    <Select.Option value='Hyderabad'>Hyderabad</Select.Option>
                    <Select.Option value='Vijayawada'>Vijayawada</Select.Option>
                    <Select.Option value='Bhimavaram'>Bhimavaram</Select.Option>
                    <Select.Option value='Kadapa'>Kadapa</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <Form.Item
                  name='pincode'
                  label='Pin Code'
                  rules={[
                    {
                      required: false,
                      message: "Please enter pin code",
                    },
                    { validator: validatePinCodeNumber },
                  ]}>
                  <Input
                    type='text'
                    // onKeyPress={handleNumericInput}
                    allowClear
                    onChange={handlePinCodeChange}
                    placeholder='Enter Pincode'
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <Form.Item
                  name='address'
                  label='Address'
                  rules={[
                    {
                      required: true,
                      message: "Please enter address",
                    },
                    // { validator: validateAddress },
                  ]}>
                  <LocationSearch
                    value={address}
                    onChange={(value) => {
                      setAddress(value);
                    }}
                    initialValue={address}
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                    setArea={setArea}
                    setAddress={setAddress}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div {...getRootProps()} class='upload-container'>
              <input {...getInputProps()} />
              {file && categoryImage ? (
                <div className='uploaded-image-section'>
                  <div className='fileUpload-image-div'>
                    <img src={file.preview || categoryImage} alt={file.name} />
                  </div>
                  <ImCross
                    className='text-md font-light cursor-pointer'
                    onClick={() => {
                      setFile(null);
                      setCategoryImage("");
                    }}
                  />
                </div>
              ) : (
                <CloudUploadOutlined className='cloud-icon' />
              )}
              <div className='upload-text-section'>
                <p class='upload-text'>
                  Browse/drag to {file && categoryImage ? "change" : "upload"}{" "}
                  File
                </p>
                <p class='file-size-text'>File Size must be below 5 Mb</p>
              </div>
            </div>
            <div className='action-buttons'>
              <div className='cancel-btn' onClick={closeModal}>
                Cancel
              </div>
              <button className='update-btn' type='primary' htmlType='submit'>
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
// AddCustomer
export default AddCustomer;
