import { Spin, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { postAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";

const SKUContent = ({
  skuProductId,
  handleSkuEdit,
  setSKUModalVisible,
  setSkuProductId,
  setAddSkuData,
  skuData,
  setSkuData,
  fetchProductBySkuId,
  loading,
  setLoading,
}) => {
  const [openInputs, setOpenInputs] = useState(Array(3).fill(false));
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const logout = useLogout();

  console.log(skuData);

  const onChange3 = (id) => {
    toggleStatus(id);
  };

  const toggleStatus = async (id) => {
    const Endpoint = "toggleSKUStatus";
    try {
      setLoading(true);
      const userData = { skuId: id };
      const { data } = await postAuthApiData(Endpoint, userData);
      if (data.responseCode === 200) {
        message.success(data.message);
        fetchProductBySkuId(); // Refresh the SKU list
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.message);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className='SKU-modal-container'>
        <div className='SKU-modal-header'>
          <h6>SKU's</h6>
          <div
            className='SKu-modal-add'
            onClick={() => {
              setAddSkuData(skuData);
              setSKUModalVisible(false);
            }}
            style={{ cursor: "pointer" }}>
            <p>+</p>
            <p>Add SKU</p>
          </div>
        </div>
        <div className='SKU-modal-table'>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                {/* {skuData[0]?.productStream === "Qty" ? ( */}
                {/* <th>Quantity</th> */}
                {/* ) : ( */}
                <th>Pieces in a packet /Quantity</th>
                {/* )} */}
                <th>Piece Price</th>
                <th>B.Price</th>
                <th>S.Price</th>
                <th>Packets</th>
                <th>Tax(%)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {skuData.map((item, index) => (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  {item?.productStream === "Qty" ? (
                    <td stye={{ textAlign: "center" }}>
                      {item?.quantityValue} {item?.quantityType}
                    </td>
                  ) : (
                    <td stye={{ textAlign: "center" }}>
                      {`${item?.piecesInaPack} pcs`}
                    </td>
                  )}
                  {item?.productStream === "Pouches" ? (
                    <td>
                      <div className='flex'>
                        <span>{item?.singlePiecePrice}</span>
                        <MdOutlineEdit
                          onClick={() => {
                            handleSkuEdit(item);
                            setSKUModalVisible(false);
                            setSkuProductId("");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </td>
                  ) : (
                    <td> - </td>
                  )}

                  <td>
                    <div className='flex'>
                      <span>{item.basePrice}</span>
                      <MdOutlineEdit
                        onClick={() => {
                          handleSkuEdit(item);
                          setSKUModalVisible(false);
                          setSkuProductId("");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='flex'>
                      <span>{item.salePrice}</span>
                      <MdOutlineEdit
                        onClick={() => {
                          handleSkuEdit(item);
                          setSKUModalVisible(false);
                          setSkuProductId("");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span>{item.packets}</span>
                  </td>
                  <td>
                    <div className='flex'>
                      <span>{item?.tax || 0}</span>
                      <MdOutlineEdit
                        onClick={() => {
                          handleSkuEdit(item);
                          setSKUModalVisible(false);
                          setSkuProductId("");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Switch
                      checked={item.status === "active"}
                      onChange={() => toggleStatus(item._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Spin>
  );
};

export default SKUContent;
