import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Input, Form, Table, Select, message, Spin, Space } from "antd";
import Image from "../Categories/Image.png";
import {
  getApiData,
  postAuthApiData,
  putAuthApiData,
} from "../../api/apiService";
// import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import useLogout from "../../utils/authUtils";
import { useDropzone } from "react-dropzone";
import { CloudUploadOutlined } from "@ant-design/icons";
import { ImCross } from "react-icons/im";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";

const AddProduct = forwardRef(
  (
    {
      fetchProductList,
      productId,
      setLoading,
      setError,
      setProductId,
      skuEditData,
      setSkuEditData,
      addSkuData,
      setAddSkuData,
      setSkuProductId,
    },
    ref
  ) => {
    const { TextArea } = Input;
    const [categoryImage, setCategoryImage] = useState("");
    const [skuList, setSkuList] = useState([]);
    const [quantityValue, setQuantityValue] = useState("");
    const [priceValue, setPriceValue] = useState("");
    const [basePrice, setBasePrice] = useState("");
    const [singlePiecePrice, setSinglePiecePrice] = useState("");
    const [packetsCount, setPacketsCount] = useState("");
    const [form] = Form.useForm();
    const [visibleInputs, setVisibleInputs] = useState(true);
    const [categoryData, setCategoryData] = useState([]);
    const [quantityType, setQuantityType] = useState("gram");
    const [skuId, setSkuId] = useState("");
    const [addSkuProductId, setAddSkuProductId] = useState("");
    const [selectedStream, setSelectedStream] = useState("Qty");
    const [isPacketsInputDisabled, setPacketsInputDisabled] = useState(false);
    const [isKgInputDisabled, setKgInputDisabled] = useState(false);
    const [kgValue, setKgValue] = useState("");
    const [taxPercent, setTaxPercent] = useState(null);
    const [taxValue, setTaxValue] = useState(null);
    const [subTotal, setSubTotal] = useState(null);
    const logout = useLogout();
    const skuListRef = useRef(skuList);
    const [file, setFile] = useState(null);

    useImperativeHandle(ref, () => ({
      handleClear,
    }));

    useEffect(() => {
      skuListRef.current = skuList;
      console.log("Calling useEffect for skuList");
    }, [skuList]);

    console.log(skuListRef);

    const fetchCategoryList = async () => {
      const Endpoint = "listOfCategories";
      try {
        setLoading(true);
        const { data, loading: postLoading } = await getApiData(Endpoint, true);
        console.log("Category Details fetched successful:", data);
        setLoading(postLoading);
        if (data.responseCode === 200) {
          console.log(data.message);
          setCategoryData(data?.data);
        } else if (data.responseCode === 400) {
          console.log(data);
          console.log(data.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } catch (error) {
        console.log("Category Details fetching failed:", error);
        message.error(error?.error?.error?.message);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchCategoryList();
    }, []);

    console.log(skuEditData);
    useEffect(() => {
      if (Object.keys(skuEditData).length > 0) {
        form.resetFields();
        setSkuList([]);
        setCategoryImage("");
        setFile(null);
        setPacketsCount("");
        setPriceValue("");
        setQuantityType("gram");
        setQuantityValue("");
        setAddSkuData([]);
        setProductId("");
        setPacketsCount(skuEditData?.packets);
        setQuantityType(skuEditData?.quantityType);
        setQuantityValue(
          skuEditData?.quantityValue > 0
            ? skuEditData?.quantityValue
            : skuEditData?.piecesInaPack
        );
        setPriceValue(skuEditData?.salePrice);
        setBasePrice(skuEditData?.basePrice);
        setSinglePiecePrice(skuEditData?.singlePiecePrice);
        fetchProductById(skuEditData?.productId);
        setSkuId(skuEditData?._id);
        setSelectedStream(skuEditData?.productStream);
        setTaxPercent(skuEditData?.tax);
      }
    }, [skuEditData]);

    const onFinish = async (values) => {
      console.log(values);
      console.log(skuList);
      if (productId) {
        try {
          const Endpoint = "updateProduct";
          setLoading(true);
          const userData = {
            productId: productId,
            productName: values.name,
            productImage: categoryImage,
            description: values.description,
            categoryId: values.category,
          };
          const { data, loading: postLoading } = await putAuthApiData(
            Endpoint,
            userData
          );
          console.log("Product Updated successful:", data);
          setLoading(postLoading);
          if (data?.responseCode === 200) {
            console.log(data.message);
            message.success(data?.message);
            form.resetFields();
            setCategoryImage("");
            setFile(null);
            fetchProductList();
            setSelectedStream("Qty");
            setProductId("");
          } else if (data?.responseCode === 400) {
            message.error(data?.message);
            console.log(data?.message);
          } else if (data.responseCode === 401) {
            message.error(data?.message);
            logout();
          }
        } catch (error) {
          console.log("Product Updated failed:", error);
          message.error(error?.error?.error?.message);
          setLoading(false);
          setError(error);
        } finally {
          setLoading(false);
        }
      } else if (Object.keys(skuEditData).length > 0) {
        if (priceValue) {
          const price = parseFloat(priceValue);
          const bPrice = parseFloat(basePrice);
          const pieceOfPrice = parseFloat(singlePiecePrice);
          try {
            const Endpoint = "updateSku";
            setLoading(true);
            const userData = {
              skuId: skuId,
              salePrice: price,
              basePrice: bPrice,
              tax: taxPercent ? taxPercent : 0,
            };
            if (skuEditData?.productStream === "Pouches") {
              userData.singlePiecePrice = pieceOfPrice;
            }
            const { data, loading: postLoading } = await putAuthApiData(
              Endpoint,
              userData
            );
            console.log("Sku Updated successful:", data);
            setLoading(postLoading);
            if (data?.responseCode === 200) {
              console.log(data.message);
              message.success(data?.message);
              form.resetFields();
              fetchProductList();
              setSkuEditData({});
              setProductId("");
              setQuantityValue("");
              setPriceValue("");
              setBasePrice("");
              setKgValue("");
              setSelectedStream("Qty");
              setSkuProductId("");
              setPacketsCount("");
              setQuantityType("gram");
              setCategoryImage("");
              setTaxPercent(null);
              setTaxValue(null);
              setSubTotal(null);
              setFile(null);
            } else if (data?.responseCode === 400) {
              message.warning(data?.message);
              console.log(data?.message);
            } else if (data.responseCode === 401) {
              message.error(data?.message);
              logout();
            }
          } catch (error) {
            console.log("Sku Updated failed:", error);
            message.error(error?.error?.error?.message);
            setLoading(false);
            setError(error);
          } finally {
            setLoading(false);
          }
        } else {
          message.warning("Please enter the Price and packets inputs");
        }
      } else if (addSkuData?.length > 0) {
        if (selectedStream === "Qty") {
          if (
            quantityType &&
            quantityValue &&
            priceValue &&
            packetsCount &&
            basePrice
          ) {
            const packets = parseInt(packetsCount, 10);
            const salePrie = parseFloat(priceValue);
            const baseprice = parseFloat(basePrice);
            const newSku = {
              quantityType,
              quantityValue,
              salePrie,
              packets,
              basePrice: baseprice,
            };
            setSkuList([...skuList, newSku]);
            setQuantityValue("");
            setPriceValue("");
            setPacketsCount("");
            setBasePrice("");
            setQuantityType("gram");
          }
        } else if (selectedStream === "Pouches") {
          if (quantityValue && priceValue && packetsCount && basePrice) {
            const packets = parseInt(packetsCount, 10);
            const salePrie = parseFloat(priceValue);
            const baseprice = parseFloat(basePrice);
            const newSku = {
              piecesInaPack: quantityValue,
              salePrie,
              packets,
              basePrice: baseprice,
            };
            setSkuList([...skuList, newSku]);
            setQuantityValue("");
            setPriceValue("");
            setBasePrice("");
            // setSelectedStream("Qty");
            setPacketsCount("");
            setQuantityType("gram");
          }
        }
        if (skuList?.length > 0) {
          console.log(skuList);
          const filteredSkus = skuList?.filter(
            (sku) => !sku.hasOwnProperty("productId")
          );
          console.log(filteredSkus);
          try {
            const Endpoint = "addSkuData";
            setLoading(true);
            const userData = {
              productId: addSkuProductId,
              skus: filteredSkus,
              productStream: selectedStream,
            };
            const { data, loading: postLoading } = await postAuthApiData(
              Endpoint,
              userData
            );
            console.log("Product Sku  Added successful:", data);
            setLoading(postLoading);
            if (data.responseCode === 200) {
              console.log(data.message);
              message.success(data?.message);
              form.resetFields();
              setSkuList([]);
              setAddSkuData([]);
              setSkuProductId("");
              setAddSkuProductId("");
              setVisibleInputs(true);
              setSelectedStream("Qty");
              setCategoryImage("");
              setFile(null);
              fetchProductList();
            } else if (data.responseCode === 400) {
              console.log(data);
              console.log(data.message);
            } else if (data.responseCode === 401) {
              message.error(data?.message);
              logout();
            }
          } catch (error) {
            console.log("Product Sku Added failed:", error);
            message.error(error?.error?.error?.message);
            setLoading(false);
            setError(error);
          } finally {
            setLoading(false);
          }
        }
      } else {
        if (selectedStream === "Qty") {
          if (
            quantityType &&
            quantityValue &&
            priceValue &&
            packetsCount &&
            basePrice
          ) {
            const packets = parseInt(packetsCount, 10);
            const salePrice = parseFloat(priceValue);
            const baseprice = parseFloat(basePrice);
            const newSku = {
              quantityType,
              quantityValue,
              salePrice,
              basePrice: baseprice,
              packets,
              productStream: selectedStream,
              tax: taxPercent ? taxPercent : 0,
            };
            setSkuList([...skuList, newSku]);
            skuListRef.current = [...skuListRef.current, newSku];
            setQuantityValue("");
            setBasePrice("");
            setPriceValue("");
            setPacketsCount("");
            setQuantityType("gram");
            setKgValue("");
            setKgInputDisabled(false);
            setTaxPercent(null);
            setTaxValue(null);
            setSubTotal(null);
            setPacketsInputDisabled(false);
          }
        } else if (selectedStream === "Pouches") {
          if (quantityValue && priceValue && packetsCount && basePrice) {
            const packets = parseInt(packetsCount, 10);
            const salePrice = parseFloat(priceValue);
            const baseprice = parseFloat(basePrice);
            const piecePrice = parseFloat(singlePiecePrice);
            const newSku = {
              piecesInaPack: quantityValue,
              salePrice,
              packets,
              basePrice: baseprice,
              productStream: selectedStream,
              singlePiecePrice: piecePrice,
              tax: taxPercent ? taxPercent : 0,
            };
            setSkuList([...skuList, newSku]);
            skuListRef.current = [...skuListRef.current, newSku];
            setQuantityValue("");
            setPriceValue("");
            setBasePrice("");
            setPacketsCount("");
            setQuantityType("gram");
            setKgValue("");
            setPacketsInputDisabled(false);
            setKgInputDisabled(false);
            setTaxPercent(null);
            setTaxValue(null);
            setSubTotal(null);
            setSinglePiecePrice("");
          }
        }

        if (skuListRef.current.length > 0) {
          try {
            const Endpoint = "addProduct";
            setLoading(true);
            const userData = {
              productName: values.name,
              productImage: categoryImage,
              description: values.description,
              categoryId: values.category,
              skus: skuListRef.current,
              // productStream: selectedStream,
            };
            const { data, loading: postLoading } = await postAuthApiData(
              Endpoint,
              userData
            );
            console.log("Product Added successful:", data);
            setLoading(postLoading);
            if (data.responseCode === 200) {
              console.log(data.message);
              message.success(data?.message);
              form.resetFields();
              setSkuList([]);
              setCategoryImage("");
              setFile(null);
              setSelectedStream("Qty");
              setVisibleInputs(true);
              fetchProductList();
              setKgValue("");
              setPacketsInputDisabled(false);
              setKgInputDisabled(false);
              setSinglePiecePrice("");
              setPacketsCount("");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              setVisibleInputs(true);
            } else if (data.responseCode === 400) {
              console.log(data);
              console.log(data.message);
              message.error(data?.message);
            } else if (data.responseCode === 401) {
              message.error(data?.message);
              logout();
            }
          } catch (error) {
            console.log("Product Added failed:", error);
            message.error(error?.error?.error?.message);
            setLoading(false);
            setError(error);
          } finally {
            setLoading(false);
          }
        } else {
          message.error("Please enter the sku details");
        }
      }
    };
    console.log(skuList);

    const handleAddSku = () => {
      console.log(quantityType, quantityValue, priceValue, packetsCount);
      if (
        quantityType &&
        quantityValue &&
        priceValue &&
        packetsCount &&
        basePrice
      ) {
        const packets = parseInt(packetsCount, 10);
        const price = parseFloat(priceValue);
        const baseprice = parseFloat(basePrice);
        const newSku = {
          quantityType,
          quantityValue,
          salePrice: price,
          packets,
          basePrice: baseprice,
          productStream: selectedStream,
          tax: taxPercent ? taxPercent : 0,
          // skuStream: selectedStream,
        };
        console.log(newSku);
        setSkuList([...skuList, newSku]);
        skuListRef.current = [...skuListRef.current, newSku];
        setQuantityValue("");
        setPriceValue("");
        setPacketsCount("");
        setBasePrice("");
        setQuantityType("gram");
        setKgValue("");
        setKgInputDisabled(false);
        setPacketsInputDisabled(false);
        setVisibleInputs(!visibleInputs);
        setTaxPercent(null);
        setTaxValue(null);
        setSubTotal(null);
      } else {
        setVisibleInputs(!visibleInputs);
      }
    };
    const handlePiecesAddSku = () => {
      console.log("Stream", selectedStream);
      console.log(quantityValue, priceValue, packetsCount, singlePiecePrice);
      if (
        quantityValue &&
        priceValue &&
        packetsCount &&
        basePrice &&
        singlePiecePrice
      ) {
        const packets = parseInt(packetsCount, 10);
        const price = parseFloat(priceValue);
        const baseprice = parseFloat(basePrice);
        const piecePrice = parseFloat(singlePiecePrice);

        const newSku = {
          piecesInaPack: quantityValue,
          salePrice: price,
          packets: packets,
          basePrice: baseprice,
          singlePiecePrice: piecePrice,
          productStream: selectedStream,
          tax: taxPercent ? taxPercent : 0,
          // skuStream: "Pouches",
        };
        setSkuList([...skuList, newSku]);
        skuListRef.current = [...skuListRef.current, newSku];
        setQuantityValue("");
        setPriceValue("");
        setSinglePiecePrice("");
        setPacketsCount("");
        setBasePrice("");
        setQuantityType("gram");
        setKgValue("");
        setKgInputDisabled(false);
        setPacketsInputDisabled(false);
        setTaxPercent(null);
        setTaxValue(null);
        setSubTotal(null);
        setVisibleInputs(!visibleInputs);
      } else {
        setVisibleInputs(!visibleInputs);
      }
    };
    console.log(skuList);

    const handleCategorySelect = (value) => {
      console.log(value);
    };

    const options = [
      {
        value: "gram",
        label: "gram",
      },
      {
        value: "kg",
        label: "kg",
      },
    ];

    const streamOptions = [
      {
        value: "Qty",
        label: "Qty",
      },
      {
        value: "Pouches",
        label: "Pouches",
      },
    ];

    const handleSelectChange = (value) => {
      console.log(value);
      setQuantityType(value);
    };
    const handleStreamChange = (value) => {
      console.log(value);
      setSelectedStream(value);
      setTaxPercent(null);
      setTaxValue(null);
      setSubTotal(null);
      setPacketsCount("");
      setPriceValue("");
      setQuantityValue("");
      setBasePrice("");
      setPacketsInputDisabled(false);
      setKgValue("");
      setKgInputDisabled(false);
      setVisibleInputs(true);
      setSinglePiecePrice("");
    };

    console.log(productId);
    console.log(addSkuData);

    useEffect(() => {
      if (productId) {
        form.resetFields();
        setSkuList([]);
        setCategoryImage("");
        setFile(null);
        setPacketsCount("");
        setPriceValue("");
        setQuantityType("gram");
        setQuantityValue("");
        setAddSkuData([]);
        setSkuEditData({});
        setSkuId("");
        setVisibleInputs(true);
        fetchProductById(productId);
      }
    }, [productId]);

    useEffect(() => {
      if (addSkuData?.length > 0) {
        form.resetFields();
        setSkuList([]);
        setCategoryImage("");
        setFile(null);
        setPacketsCount("");
        setPriceValue("");
        setQuantityType("gram");
        setQuantityValue("");
        setSkuEditData({});
        setSkuId("");
        setVisibleInputs(true);
        setProductId("");
        const id = addSkuData?.length > 0 && addSkuData[0]?.productId;
        const list = addSkuData?.length > 0 && addSkuData;
        setAddSkuProductId(id);
        setSkuList(list);
        fetchProductById(id);
        setSelectedStream(
          addSkuData?.length > 0 && addSkuData[0]?.productStream
        );
      }
    }, [addSkuData]);

    const fetchProductById = async (id) => {
      const Endpoint = "getSingleProductById";
      try {
        setLoading(true);
        const userData = {
          productId: id,
        };
        const { data, loading: postLoading } = await postAuthApiData(
          Endpoint,
          userData
        );
        console.log("Product By Id fetched successful:", data);
        setLoading(postLoading);
        if (data.responseCode === 200) {
          console.log(data.message);
          form.setFieldsValue({
            category: data?.data && data?.data[0]?.categoryId,
            description: data?.data && data?.data[0]?.description,
            name: data?.data && data?.data[0]?.productName,
          });
          setCategoryImage(data?.data && data?.data[0]?.productImage);
          setFile(data?.data && data?.data[0]?.productImage);
        } else if (data.responseCode === 400) {
          console.log(data);
          console.log(data.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } catch (error) {
        console.log("Product By Id fetched failed:", error);
        message.error(error?.error?.error?.message);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    console.log(selectedStream);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: async (acceptedFiles, rejectedFiles) => {
        // Manually filter file types to allow only .jpg, .png, and .jpeg
        const validExtensions = [".png", ".jpg", ".jpeg"];

        acceptedFiles.forEach(async (file) => {
          const fileExtension = file.name
            .slice(file.name.lastIndexOf("."))
            .toLowerCase();

          // Validate file extension
          if (!validExtensions.includes(fileExtension)) {
            message.error(
              "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
            );
            return;
          }

          // Check file size (must be <= 5MB)
          if (file.size > 5242880) {
            message.error("File size exceeds the maximum limit of 5MB.");
            return;
          }

          // If file is valid, store the latest file and preview it
          const preview = URL.createObjectURL(file);
          setFile(
            Object.assign(file, {
              preview: preview,
            })
          );

          try {
            // Handle file upload and get the returned URL
            const uploadedFileUrl = await handleFileUpload(file);

            // Set the uploaded file URL as category image
            console.log(uploadedFileUrl);

            setCategoryImage(uploadedFileUrl);
          } catch (error) {
            message.error("File upload failed. Please try again.");
          }
        });
      },
      maxFiles: 1, // Allow only one file at a time
    });
    const handleClear = () => {
      form.resetFields();
      setSkuList([]);
      setCategoryImage("");
      setFile(null);
      setPacketsCount("");
      setPriceValue("");
      setQuantityType("gram");
      setQuantityValue("");
      setAddSkuData([]);
      setSkuEditData({});
      setSelectedStream("Qty");
      setBasePrice("");
      setPacketsInputDisabled(false);
      setKgValue("");
      setKgInputDisabled(false);
      setSkuId("");
      setVisibleInputs(true);
      setTaxPercent(null);
      setTaxValue(null);
      setSubTotal(null);
      setProductId("");
      setSinglePiecePrice("");
    };

    useEffect(() => {
      if (packetsCount) {
        const packets = parseFloat(packetsCount);
        const calculatedKg =
          quantityType === "gram"
            ? (packets * quantityValue) / 1000
            : packets * quantityValue;
        setKgValue(calculatedKg);
      }
    }, [packetsCount, quantityValue, quantityType]);

    const handlePacketsChange = (e) => {
      const value = e.target.value;
      setPacketsCount(value);
      console.log(value);
      setKgInputDisabled(value !== "");
      if (value === "") {
        setKgValue("");
        setPacketsCount("");
        setKgInputDisabled(false);
      } else {
        const packets = parseFloat(value);
        const calculatedKg =
          quantityType === "gram"
            ? (packets * quantityValue) / 1000
            : packets * quantityValue;
        setKgValue(calculatedKg);
      }
    };

    const handleKgChange = (e) => {
      const value = e.target.value;
      setKgValue(value);
      setPacketsInputDisabled(value !== "");
      if (value === "") {
        setPacketsCount("");
        setPacketsInputDisabled(false);
      } else {
        const kgs = value;
        setKgValue(kgs);

        const calculatedPackets =
          quantityType === "gram"
            ? (kgs * 1000) / quantityValue
            : kgs / quantityValue;

        setPacketsCount(calculatedPackets);
      }
    };

    const handleNumericInput = (e) => {
      const charCode = e.charCode;
      const inputValue = e.target.value;
      if (
        (charCode >= 48 && charCode <= 57) || // digits 0-9
        (charCode === 46 && inputValue.indexOf(".") === -1) // decimal point
      ) {
        return;
      } else {
        e.preventDefault();
        message.warning("Only numbers are allowed");
      }
    };

    let productWarningShown = false; // Flag to track if the warning has been shown

    const validateProduct = (_, value) => {
      // Check if the product name starts with a letter
      if (value && !/^[a-zA-Z]/.test(value)) {
        if (!productWarningShown) {
          message.warning("Product must start with a letter");
          productWarningShown = true; // Set flag after showing the warning
        }
        return Promise.reject(new Error("Product must start with a letter"));
      }

      productWarningShown = false;

      if (value && !/^[a-zA-Z][a-zA-Z0-9\s]*$/.test(value)) {
        if (!productWarningShown) {
          message.warning(
            "Product must contain only letters, numbers, and spaces"
          );
          productWarningShown = true; // Set flag after showing the warning
        }
        return Promise.reject(
          new Error("Product must contain only letters, numbers, and spaces")
        );
      }

      // Reset the warning flag if input becomes valid
      productWarningShown = false;

      // Check if the length is less than 5 characters
      if (value && value.length < 2) {
        return Promise.reject(
          new Error("Product must be at least 2 characters")
        );
      }

      // Check if the length is more than 16 characters
      if (value && value.length > 16) {
        return Promise.reject(
          new Error("Product must be at most 16 characters")
        );
      }

      return Promise.resolve();
    };
    const handleProductChange = (e) => {
      const { value } = e.target;
      const cleanedValue = value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 17);

      // Prevent product name from starting with a number or space
      if (/^[0-9\s]/.test(cleanedValue)) {
        form.setFieldsValue({ name: cleanedValue.slice(1) });
      } else {
        form.setFieldsValue({ name: cleanedValue });
      }
    };

    useEffect(() => {
      if (priceValue && taxPercent !== null) {
        const price = parseFloat(priceValue);
        const taxRate = parseFloat(taxPercent);

        if (!isNaN(price) && !isNaN(taxRate)) {
          // Calculate tax and subtotal
          const taxValue = price * (taxRate / 100);
          const subTotal = price - taxValue;
          setSubTotal(subTotal?.toFixed(2));
          setTaxValue(taxValue?.toFixed(2));
          console.log("Price:", price);
          console.log("Tax Percent:", taxPercent);
          console.log("Tax Value:", taxValue);
          console.log("Subtotal:", subTotal);
        } else {
          console.log("Invalid input values:", { priceValue, taxPercent });
          setSubTotal(null);
          setTaxValue(null);
        }
      } else {
        console.log("Invalid input values:", { priceValue, taxPercent });
        setSubTotal(null);
        setTaxValue(null);
      }
    }, [priceValue, taxPercent]);

    return (
      <div className='addcategory-content'>
        <div>
          <div className='clear-section'>
            <button onClick={handleClear} className='clear-btn'>
              Clear
            </button>
          </div>
          <Form
            form={form}
            className='addcategory-form'
            layout='vertical'
            autoComplete='off'
            onFinish={onFinish}>
            <Form.Item
              name='category'
              label='Select Category'
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}>
              <Select
                placeholder='Select Category'
                allowClear
                showSearch
                disabled={
                  Object.keys(skuEditData).length > 0 || addSkuData?.length > 0
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleCategorySelect}>
                {categoryData
                  ?.filter((item) => item.status === "active")
                  .map((item, index) => (
                    <Select.Option key={item?._id} value={item._id}>
                      {item?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='name'
              label='Add Product'
              rules={[
                {
                  required: true,
                  message: "Please enter name of the product !",
                },
                { validator: validateProduct },
              ]}>
              <Input
                placeholder='Name of the Product'
                onChange={handleProductChange}
                disabled={
                  Object?.keys(skuEditData)?.length > 0 ||
                  addSkuData?.length > 0
                }
              />
            </Form.Item>
            {productId ? (
              ""
            ) : (
              <div className='Sku-top'>
                <lable>SKU's</lable>
                <div>
                  <Select
                    value={selectedStream}
                    options={streamOptions}
                    onChange={(value) => handleStreamChange(value)}
                    style={{ minWidth: "100px" }}
                    // disabled={
                    //   skuList?.length > 0 || Object.keys(skuEditData).length > 0
                    // }
                  />
                </div>
              </div>
            )}
            {skuList.length > 0 && (
              <div className='sku-table'>
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      {/* {selectedStream === "Qty" ? ( */}
                      <th>Quantity / Pieces</th>
                      {/* ) : ( */}
                      {/* <th>Pieces in a pack</th> */}
                      {/* )} */}
                      {/* {selectedStream === "Pouches" && */}
                      <th>Piece Price</th>
                      {/* } */}
                      <th>B.Price</th>
                      <th>S.Price</th>
                      <th>Tax</th>
                      <th>Packets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skuList.map((sku, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <tr>
                          <tr>
                            {sku.quantityType === "gram" ||
                            sku.quantityType === "kg" ? (
                              <td>
                                {sku.quantityValue} {sku.quantityType}
                              </td>
                            ) : sku.piecesInaPack > 0 ? (
                              <td>{sku.piecesInaPack}</td>
                            ) : null}
                          </tr>
                        </tr>
                        {/* <td>{sku.piecesInaPack}</td> */}
                        {/* {selectedStream === "Pouches" && ( */}
                        <th>{sku.singlePiecePrice || "-"}</th>
                        {/* )} */}
                        <td>{sku.basePrice}</td>
                        <td>{sku.salePrice}</td>
                        <td>{sku?.tax}</td>
                        <td style={{ color: "#2563EB" }}>{sku.packets}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {productId ? (
              ""
            ) : (
              <>
                {selectedStream === "Qty" && visibleInputs && (
                  <div className='sku-inputs'>
                    <Space.Compact>
                      <Input
                        placeholder='Quantity'
                        value={quantityValue}
                        onKeyPress={handleNumericInput}
                        onChange={(e) => setQuantityValue(e.target.value)}
                        disabled={Object.keys(skuEditData).length > 0}
                      />
                      <Select
                        defaultValue={quantityType}
                        options={options}
                        onChange={handleSelectChange}
                        disabled={Object.keys(skuEditData).length > 0}
                      />
                    </Space.Compact>
                    <Input
                      placeholder='Base Price'
                      value={basePrice}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => setBasePrice(e.target.value)}
                      suffix='Rs'
                    />
                    <Input
                      placeholder='Sale Price'
                      value={priceValue}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => setPriceValue(e.target.value)}
                      suffix='Rs'
                    />
                    <Select
                      value={taxPercent}
                      placeholder='Select Tax'
                      onChange={(value) => setTaxPercent(value)}>
                      <Option value={0}>0%</Option>
                      <Option value={5}>5%</Option>
                      <Option value={12}>12%</Option>
                      <Option value={18}>18%</Option>
                    </Select>
                    {/* 
          <Input
            placeholder='Number of packets'
            value={packetsCount}
            onChange={(e) => setPacketsCount(e.target.value)}
            suffix='00'
            disabled={Object.keys(skuEditData).length > 0}
          /> 
          */}
                    <Space.Compact>
                      <Input
                        placeholder='Tax Value'
                        value={taxValue}
                        disabled
                      />
                      <Input
                        placeholder='Sub Total'
                        value={subTotal}
                        disabled
                      />
                    </Space.Compact>

                    <Space.Compact>
                      <Input
                        placeholder='00 packets'
                        value={packetsCount}
                        onKeyPress={handleNumericInput}
                        onChange={handlePacketsChange}
                        disabled={
                          isPacketsInputDisabled ||
                          Object.keys(skuEditData).length > 0
                        }
                      />
                      <Input
                        placeholder='0.0 (kg)'
                        value={kgValue}
                        onKeyPress={handleNumericInput}
                        onChange={handleKgChange}
                        disabled={
                          isKgInputDisabled ||
                          Object.keys(skuEditData).length > 0
                        }
                      />
                    </Space.Compact>
                  </div>
                )}
                {selectedStream === "Qty" &&
                  Object.keys(skuEditData).length === 0 && (
                    <div className='Add-sku'>
                      <small>You can add multiple SKU's</small>
                      <div
                        className='add-button pointer'
                        onClick={handleAddSku}>
                        <p>+</p>
                        <p>Add SKU</p>
                      </div>
                    </div>
                  )}
                {selectedStream === "Pouches" && visibleInputs && (
                  <div className='sku-inputs'>
                    <Space.Compact>
                      <Input
                        placeholder='Pieces in a packet'
                        value={quantityValue}
                        onKeyPress={handleNumericInput}
                        onChange={(e) => setQuantityValue(e.target.value)}
                        disabled={Object.keys(skuEditData).length > 0}
                        suffix='Count'
                      />
                      {/* <Select
                      defaultValue={quantityType}
                      options={options}
                      onChange={handleSelectChange}
                      disabled={Object.keys(skuEditData).length > 0}
                    /> */}
                    </Space.Compact>
                    <Input
                      placeholder='Single Piece Price'
                      value={singlePiecePrice}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value)) {
                          setSinglePiecePrice(value);
                          setBasePrice(value * quantityValue);
                          setPriceValue(value * quantityValue);
                        }
                      }}
                      suffix='Rs'
                    />
                    <Input
                      placeholder='Base Price'
                      value={basePrice}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => setBasePrice(e.target.value)}
                      suffix='Rs'
                    />
                    <Input
                      placeholder='Sale Price'
                      value={priceValue}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => setPriceValue(e.target.value)}
                      suffix='Rs'
                    />
                    <Select
                      value={taxPercent}
                      placeholder='Select Tax'
                      onChange={(value) => setTaxPercent(value)}>
                      <Option value={0}>0%</Option>
                      <Option value={5}>5%</Option>
                      <Option value={12}>12%</Option>
                      <Option value={18}>18%</Option>
                    </Select>
                    <Space.Compact>
                      <Input
                        placeholder='Tax Value'
                        value={taxValue}
                        disabled
                      />
                      <Input
                        placeholder='Sub Total'
                        value={subTotal}
                        disabled
                      />
                    </Space.Compact>
                    <Input
                      placeholder='000 packets'
                      value={packetsCount}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => setPacketsCount(e.target.value)}
                      suffix='00'
                      disabled={Object.keys(skuEditData).length > 0}
                    />
                  </div>
                )}
                {selectedStream === "Pouches" &&
                  Object.keys(skuEditData).length === 0 && (
                    <div className='Add-sku'>
                      <small>You can add multiple SKU's</small>
                      <div
                        className='add-button pointer'
                        onClick={handlePiecesAddSku}>
                        <p>+</p>
                        <p>Add SKU</p>
                      </div>
                    </div>
                  )}
              </>
            )}

            <Form.Item
              name='description'
              label='Description'
              style={{ marginTop: "24px" }}
              rules={[
                {
                  required: false,
                  message: "Please input!",
                },
              ]}>
              <TextArea
                rows={4}
                placeholder='Add description for product'
                maxLength={150}
                showCount
                disabled={
                  Object.keys(skuEditData).length > 0 || addSkuData?.length > 0
                }
              />
            </Form.Item>
            {Object.keys(skuEditData).length > 0 || addSkuData?.length > 0 ? (
              ""
            ) : (
              <div {...getRootProps()} class='upload-container'>
                <input {...getInputProps()} />
                {file && categoryImage ? (
                  <div className='uploaded-image-section'>
                    <div className='fileUpload-image-div'>
                      <img
                        src={file.preview || categoryImage}
                        alt={file.name}
                      />
                    </div>
                    <ImCross
                      className='text-md font-light cursor-pointer'
                      onClick={() => {
                        setFile(null);
                        setCategoryImage("");
                      }}
                    />
                  </div>
                ) : (
                  <CloudUploadOutlined className='cloud-icon' />
                )}
                <p class='upload-text'>
                  Browse/drag to {file && categoryImage ? "change" : "upload"}{" "}
                  File
                </p>
                <p class='file-size-text'>File Size must be below 5 Mb</p>
              </div>
            )}
            <Form.Item>
              <div className='Addcategory-submit'>
                <button
                  type='primary'
                  htmlType='submit'
                  className='Addcategory-submit-button pointer'>
                  {productId ? (
                    <p>Update Product</p>
                  ) : Object.keys(skuEditData)?.length > 0 ? (
                    <p>Update SKU</p>
                  ) : addSkuData?.length > 0 ? (
                    <p>Add Sku</p>
                  ) : (
                    <p>Add Product</p>
                  )}
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
);

export default AddProduct;
