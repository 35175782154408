import { message } from "antd";
import axios from "axios";

export const handleFileUpload = async (file) => {
  console.log(file);
  if (!file) return message.error("Please upload the file");
  try {
    const formData = new FormData();
    const token = localStorage.getItem("jpf_token");
    formData.append("file", file);
    // const response = await postData("/new_Part_File_Upload", formData);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/uploadImage`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response);
    const imageUrl = response?.data?.imagePath1;
    console.log(imageUrl);
    message.success("Uploaded Successfully");
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image:", error);
    return error;
  }
};
