import React, { useState } from "react";
import { Avatar, Switch, Pagination } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import CategoryImage from "./CategoryImage.png";
import { postAuthApiData } from "../../api/apiService";
import { message } from "antd";
import useLogout from "../../utils/authUtils";

const CategoryList = ({
  setLoading,
  setError,
  categoryData,
  fetchCategoryList,
  setCategoryId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const logout = useLogout();
  const pageSize = 10;

  const toggleStatus = async (id) => {
    const Endpoint = "toggleCategoryStatus";
    try {
      setLoading(true);
      const userData = {
        categoryId: id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      setLoading(postLoading);
      if (data.responseCode === 200) {
        message.success(data?.message);
        fetchCategoryList();
      } else if (data.responseCode === 400) {
        message.error(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      message.error(error?.error?.error?.message);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = categoryData?.slice(startIndex, endIndex);

  return (
    <div className='category-list-content'>
      <p className='category-list-header'>Category List</p>
      <div className='category-list-table'>
        <table>
          <thead>
            <tr>
              <th className='serial-no'>S.No</th>
              <th className='category'>Category</th>
              <th className='description'>Description</th>
              <th className='numofproducts'>No of Products</th>
              <th className='status'>Status</th>
              <th className='edit'>Edit</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((item, index) => (
              <tr key={index}>
                <td>{startIndex + index + 1}</td>
                <td>
                  <div className='executive-section'>
                    {item?.categoryImage ? (
                      <Avatar
                        src={item?.categoryImage}
                        size={40}
                        shape='square'
                        className='image'
                      />
                    ) : (
                      <Avatar
                        shape='square'
                        size={40}
                        className='image'
                        style={{
                          backgroundColor: "#6750A4",
                          fontWeight: 600,
                          fontSize: 17,
                        }}>
                        {item?.name?.length > 0 &&
                          item?.name[0]?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    )}
                    {item?.name}
                  </div>
                </td>
                <td>{item?.description}</td>
                <td>{item?.productCount}</td>
                <td>
                  <Switch
                    checked={item?.status === "active"}
                    onChange={() => toggleStatus(item?._id)}
                  />
                </td>
                <td>
                  <span
                    onClick={() => {
                      if (item?.status === "active") {
                        setCategoryId(item?._id);
                      }
                    }}
                    style={{ cursor: "pointer" }}>
                    <MdOutlineEdit className='edit-icon' />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {categoryData?.length === 0 ? (
          <div className='no-data-message'>
            <p>No data found</p>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className='Pagination-container'>
        {categoryData?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={categoryData?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryList;
