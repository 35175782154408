import React, { useState } from "react";
import "../Roles/Role.css";
import { FaPlus } from "react-icons/fa6";
import { Input, Modal } from "antd";
import { GrFormSearch } from "react-icons/gr";
import AddRole from "./AddRole";
import AddRoleReview from "./AddRoleReview";
import { SearchOutlined } from "@ant-design/icons";
import AddAddActivity from "./AddAddActvitiy";

const RoleCard = ({
  featuresData,
  fetchRolesData,
  activeCount,
  rolesData,
  filter,
  setFilter,
  inactiveCount,
  searchInput,
  setSearchInput,
  totalCount,
}) => {
  // const [RoleCard_filter, setFilter] = useState("All");
  const [products, setProducts] = useState([]);
  const [addRoleModal, setAddRoleModal] = useState(false);

  const [addRoleReviewModal, setAddRoleReviewModal] = useState(false);
  const [checkedActivities, setCheckedActivities] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [addAddActivity, setAddAddActivity] = useState(false);

  console.log(featuresData);

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const close = () => {
    setAddRoleModal(false);
    setAddAddActivity(false);
  };
  const closeReview = () => {
    setAddRoleReviewModal(false);
  };
  console.log(rolesData);

  return (
    <div className='RoleCard'>
      <div className=' RoleCard_search-bar'>
        <Input
          type='search'
          placeholder='Search Role'
          className='search-input'
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          suffix={<SearchOutlined className='search-icon' />}
        />
      </div>

      <div className='RoleCard_filter-buttons'>
        <button
          className={filter === "All" ? "active" : ""}
          onClick={() => handleFilterClick("All")}>
          ALL ({totalCount})
        </button>
        <button
          className={filter === "Active" ? "active" : ""}
          onClick={() => handleFilterClick("Active")}>
          ACTIVE ({activeCount})
        </button>
        <button
          className={filter === "Inactive" ? "active" : ""}
          onClick={() => handleFilterClick("Inactive")}>
          INACTIVE ({inactiveCount})
        </button>
      </div>

      <div className=' RoleCard_add-new-role-button'>
        <button
          className='RoleCard_add-new-Role'
          onClick={() => setAddRoleModal(true)}>
          {" "}
          <FaPlus size={11} />
          Add New Role
        </button>
      </div>
      <Modal
        visible={addRoleModal}
        onCancel={() => setAddRoleModal(false)}
        centered
        footer={null}
        className='custom-modal'>
        <AddRole
          close={close}
          setAddRoleReviewModal={setAddRoleReviewModal}
          setAddRoleModal={setAddRoleModal}
          featuresData={featuresData}
          setCheckedActivities={setCheckedActivities}
          checkedActivities={checkedActivities}
          roleName={roleName}
          setRoleName={setRoleName}
        />
      </Modal>
      <Modal
        visible={addRoleReviewModal}
        onCancel={() => setAddRoleReviewModal(false)}
        centered
        footer={null}
        className='custom-modal'>
        <AddRoleReview
          close={closeReview}
          setAddRoleModal={setAddRoleModal}
          checkedActivities={checkedActivities}
          setCheckedActivities={setCheckedActivities}
          roleName={roleName}
          fetchRolesData={fetchRolesData}
          setAddAddActivity={setAddAddActivity}
        />
      </Modal>
      <Modal
        visible={addAddActivity}
        onCancel={() => setAddAddActivity(false)}
        centered
        footer={null}
        className='custom-modal'>
        <AddAddActivity
          close={close}
          setAddRoleReviewModal={setAddRoleReviewModal}
          setAddRoleModal={setAddRoleModal}
          featuresData={featuresData}
          setCheckedActivities={setCheckedActivities}
          checkedActivities={checkedActivities}
          roleName={roleName}
          setRoleName={setRoleName}
        />
      </Modal>
    </div>
  );
};

export default RoleCard;
