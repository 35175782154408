import moment from "moment";
import React, { useState, useEffect } from "react";

const OnlineSalesSpotPopup = (installmentsData) => {
  console.log(installmentsData);

  return (
    <div>
      <div className='popup-header'>
        <h5>Paid</h5>
      </div>
      <p className='credit-pay_model_Header'>
        Total Payable: ₹{" "}
        {installmentsData?.installmentsData?.totalAmount?.toFixed(2) || "-"}
      </p>
      <table className='credit-pay_model_table'>
        <thead className='credit-pay_model_table_head'>
          <tr>
            <th>Installments</th>
            <th>Date</th>
            <th style={{ textAlign: "center" }}>Amount</th>
          </tr>
        </thead>
        <tbody className='credit-pay_model_table_body'>
          {installmentsData?.installmentsData?.installmentData?.map(
            (installment, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {installment?.date
                    ? moment(installment.date).format("DD-MM-YYYY")
                    : "--"}
                </td>
                <td style={{ textAlign: "center" }}>
                  ₹ {installment.amount || "0000"}
                </td>
              </tr>
            )
          )}
          <tr>
            <td colSpan='2' className='paid-balance'>
              Balance
            </td>
            <td className='paid-balance-amount' style={{ textAlign: "center" }}>
              ₹ {installmentsData?.installmentsData?.dueAmount?.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OnlineSalesSpotPopup;
