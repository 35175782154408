import React, { useEffect, useRef, useState } from "react";
import AddProduct from "./AddProduct";
import ProductList from "./ProductList";
import "./Products.css";
import { getApiData } from "../../api/apiService";
import { Spin, message } from "antd";
import useLogout from "../../utils/authUtils";

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [productData, setProductData] = useState([]);
  const [productId, setProductId] = useState("");
  const [skuProductId, setSkuProductId] = useState("");
  const [skuEditData, setSkuEditData] = useState({});
  const [addSkuData, setAddSkuData] = useState([]);
  const addProductRef = useRef(null);
  const logout = useLogout();

  const fetchProductList = async () => {
    const Endpoint = "getListOfProducts";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("Product List fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setProductData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Product List fetching failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(skuProductId);

  useEffect(() => {
    fetchProductList();
  }, []);

  const handleSkuEdit = (data) => {
    console.log(data);
    setSkuEditData(data);
  };

  const triggerClear = () => {
    console.log("Triggered");
    if (addProductRef.current) {
      addProductRef.current.handleClear();
    }
  };

  return (
    <Spin spinning={loading}>
      <div className='products-container'>
        <div className='products-content'>
          <AddProduct
            fetchProductList={fetchProductList}
            productId={productId}
            setLoading={setLoading}
            setError={setError}
            setProductId={setProductId}
            skuEditData={skuEditData}
            setSkuEditData={setSkuEditData}
            addSkuData={addSkuData}
            setAddSkuData={setAddSkuData}
            setSkuProductId={setSkuProductId}
            ref={addProductRef}
          />
          <div className='vertical-line'></div>
          <ProductList
            productData={productData}
            setLoading={setLoading}
            setError={setError}
            fetchProductList={fetchProductList}
            setProductId={setProductId}
            skuProductId={skuProductId}
            setSkuProductId={setSkuProductId}
            handleSkuEdit={handleSkuEdit}
            setAddSkuData={setAddSkuData}
            loading={loading}
            triggerClear={triggerClear}
          />
        </div>
      </div>
    </Spin>
  );
};

export default Products;
