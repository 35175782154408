import React, { useEffect, useState } from "react";
import "../OfflineSales/OfflineSales.css";
import {
  AutoComplete,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import AddProductOfflineSale from "./AddProductSale";
import { getApiData, postAuthApiData } from "../../api/apiService";
import { useDispatch, useSelector } from "react-redux";
import {
  OfflineAddCustomer,
  UpdatingSelectedProducts,
  resetSelectedProducts,
} from "../../redux/action";
import useLogout from "../../utils/authUtils";

const AddOfflineSale = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [customers, setCustomers] = useState([]);
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [visibleButton, setVisibleButton] = useState(true);
  const [ProductsData, setProductsData] = useState([]);
  const logout = useLogout();

  const handlePhoneNumberChange = (value) => {
    const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
    form.setFieldsValue({ phone: cleanedValue });
    setPhoneNumber(cleanedValue);
    if (cleanedValue) {
      const filteredSuggestions = customers
        .filter(
          (customer) =>
            customer.phone.startsWith(cleanedValue) &&
            customer?.status === "active"
        )
        .map((customer) => ({
          value: customer.phone,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <div>{customer?.name}</div>
              <div>{customer?.phone}</div>
              <div className='avail-stock'>{customer?.email}</div>
            </div>
          ),
        }));
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
      form.setFieldsValue({
        name: "",
        email: "",
        address: "",
      });
      setVisibleButton(true);
    }
  };

  const handlePhoneNumberSelect = async (value) => {
    const selectedCustomer = customers?.find(
      (customer) => customer.phone === value
    );
    if (selectedCustomer) {
      form.setFieldsValue({
        name: selectedCustomer?.name,
        email: selectedCustomer?.email,
        address: selectedCustomer?.address,
      });
      try {
        setLoading(true);
        const userData = {
          name: form.getFieldValue("name"),
          email: form.getFieldValue("email"),
          phone: form.getFieldValue("phone"),
          address: form.getFieldValue("address"),
        };
        const Endpoint = "offlineOrderBookingNew";
        const { data, loading: postLoading } = await postAuthApiData(
          Endpoint,
          userData
        );
        console.log("Customer Added successful:", data);
        setLoading(postLoading);
        if (data.responseCode === 200) {
          console.log(data?.message);
          const Data = {
            CustomerId: data?.orderBooking?.customerId,
            OrderBookingId: data?.orderBooking?._id,
            CustomerName: form.getFieldValue("name"),
            CustomerPhone: form.getFieldValue("phone"),
            CustomerEmail: form.getFieldValue("email"),
            CustomerAddress: form.getFieldValue("address"),
          };
          dispatch(OfflineAddCustomer(Data));
        } else if (data.responseCode === 400) {
          console.log(data?.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
        // message.success(data?.message);
      } catch (error) {
        console.log("Customer Adding failed:", error);
        message.error(error);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
      setVisibleButton(false);
    }
  };

  const fetchCustomerList = async () => {
    const endpoint = "getListofCoustmers";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(endpoint, true);
      console.log("Customer List fetched successfully:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setCustomers(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Customer List fetching failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const onFinish = async (values) => {
    console.log(values);
    const Endpoint = "offlineOrderBookingNew";
    try {
      setLoading(true);
      const userData = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        address: values.address,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Customer Added successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data?.message);
        const Data = {
          CustomerId: data?.orderBooking?.customerId,
          OrderBookingId: data?.orderBooking?._id,
          CustomerName: values.name,
          CustomerPhone: values.phone,
          CustomerAddress: values.address,
          CustomerEmail: values.email,
        };
        dispatch(OfflineAddCustomer(Data));
      } else if (data.responseCode === 400) {
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
      message.success("Customer/ShopKeeper Added");
    } catch (error) {
      console.log("Customer Adding failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  let hasShownPhoneWarning = false; // to track if the warning has been shown

  const validatePhoneNumber = (_, value) => {
    if (value && /[^\d]/.test(value)) {
      if (!hasShownPhoneWarning) {
        message.warning("Phone number must contain only numbers");
        hasShownPhoneWarning = true; // set the flag to true after showing the warning
      }
      return Promise.reject(
        new Error("Phone number must contain only numbers")
      );
    }

    if (value && !/^[9876]/.test(value)) {
      if (!hasShownPhoneWarning) {
        message.warning("Phone number must start with 9, 8, 7, or 6");
        hasShownPhoneWarning = true;
      }
      return Promise.reject(
        new Error("Phone number must start with 9, 8, 7, or 6")
      );
    }

    if (value && value.replace(/\D/g, "").length !== 10) {
      hasShownPhoneWarning = false; // reset the flag if the input is valid or incomplete
      return Promise.reject("Phone number must be 10 digits");
    }

    // Reset the warning flag if the input is valid (only numbers and exactly 10 digits)
    hasShownPhoneWarning = false;
    return Promise.resolve();
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;

    // Clean the input to remove non-numeric characters and limit to 10 digits
    const cleanedValue = value.replace(/\D/g, "").slice(0, 10);

    // Check if the first digit is allowed (9, 8, 7, or 6)
    if (cleanedValue && /^[012345]/.test(cleanedValue)) {
      form.setFieldsValue({ phone: cleanedValue.slice(1) });
    } else {
      // Allow clearing the input
      form.setFieldsValue({ phone: cleanedValue });
    }
  };

  const id = localStorage.getItem("orderBookingId");
  useEffect(() => {
    if (id) {
      fetchProductsInfo();
    }
  }, []);

  const fetchProductsInfo = async () => {
    const id = localStorage.getItem("orderBookingId");
    const Endpoint = "getOfflineListOfinfoswithCustmer";
    try {
      setLoading(true);
      const userData = {
        orderBookingId: id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("products Info data fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setProductsData(data?.customerData);
        const CustomerData = {
          name: data?.customerData?.name,
          email: data?.customerData?.email,
          address: data?.customerData?.address,
          phone: data?.customerData?.phone,
          OrderBookingId: id,
          CustomerId: data?.customerData?._id,
        };
        form.setFieldsValue({
          name: data?.customerData?.name,
          email: data?.customerData?.email,
          address: data?.customerData?.address,
          phone: data?.customerData?.phone,
        });
        dispatch(resetSelectedProducts());
        dispatch(OfflineAddCustomer(CustomerData));
        data?.productInfos?.forEach((productInfo) => {
          const {
            booking,
            contact,
            date,
            shopkeeperName,
            orderId,
            ...filteredProductInfo
          } = productInfo;
          dispatch(UpdatingSelectedProducts(filteredProductInfo));
        });
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("products Info data fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(id);

  const CustomerDetails = useSelector((state) => state.offlineAddCustomer);
  console.log(CustomerDetails);

  let nameWarningShown = false; // Flag to track if the warning has been shown

  const validateCutomer = (_, value) => {
    // Check if the value contains invalid characters (anything other than letters and spaces)
    if (value && !/^[a-zA-Z\s]+$/.test(value)) {
      if (!nameWarningShown) {
        message.warning("Name must contain only letters and spaces");
        nameWarningShown = true; // Set the flag after showing the warning
      }
      return Promise.reject(
        new Error("Name must contain only letters and spaces")
      );
    }

    // Reset the flag if the input becomes valid
    nameWarningShown = false;

    // Check if the length is less than 5 characters
    if (value && value.length < 5) {
      return Promise.reject(new Error("Name must be at least 5 characters"));
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(new Error("Name must be at most 16 characters"));
    }

    return Promise.resolve();
  };

  const handleCustomerChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 17);
    form.setFieldsValue({ name: cleanedValue });
  };
  const validateAddress = (_, value) => {
    if (value && value.length < 5) {
      // message.warning("Name must be at least 5 characters");
      return Promise.reject(new Error("Address must be at least 5 characters"));
    }

    if (value && value.length > 16) {
      // message.warning("Name must be at most 16 characters");
      return Promise.reject(new Error("Address must be at most 16 characters"));
    }

    return Promise.resolve();
  };

  const handleAddressChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 17);
    form.setFieldsValue({ address: cleanedValue });
  };
  return (
    <Spin spinning={loading}>
      <div className='AddOfflineSales_New-sale'>
        <h2 className='AddOfflineSales_customer_Details'>
          Customer/Shop Keeper Details
        </h2>
        <Form
          form={form}
          className='addofflinseSales-form'
          layout='vertical'
          autoComplete='off'
          onFinish={onFinish}>
          <div className='AddOfflineSales_Details'>
            <Row gutter={8}>
              <Col lg={5} md={5} sm={5}>
                <Form.Item
                  name='name'
                  label='Name'
                  initialValue={CustomerDetails?.CustomerName}
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                    { validator: validateCutomer },
                  ]}>
                  <Input
                    type='text'
                    placeholder='Name'
                    size='large'
                    onChange={handleCustomerChange}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <Form.Item
                  name='phone'
                  label='Phone'
                  initialValue={CustomerDetails?.CustomerPhone}
                  rules={[
                    {
                      type: "text",
                      message: "The input is not valid!",
                    },
                    { validator: validatePhoneNumber },
                    {
                      required: true,
                      message: "Please enter Phone number!",
                    },
                  ]}>
                  <AutoComplete
                    options={suggestions}
                    onSearch={handlePhoneNumberChange}
                    value={phoneNumber}
                    popupMatchSelectWidth={400}
                    disabled={id}
                    onSelect={handlePhoneNumberSelect}
                    onChange={handlePhoneNumberChange}
                    style={{ width: "100%", marginBottom: "2px" }}>
                    <Input
                      type='text'
                      placeholder='Phone Number'
                      size='large'
                      onChange={handlePhoneChange}
                      style={{ paddingBlock: "8px" }}
                    />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col lg={5} md={5} sm={5}>
                <Form.Item
                  name='email'
                  label='Email'
                  initialValue={CustomerDetails?.CustomerEmail}
                  rules={[
                    {
                      required: false,
                      message: "Please enter email",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}>
                  <Input
                    type='email'
                    placeholder='Enter mail address'
                    size='large'
                  />
                </Form.Item>
              </Col>
              <Col lg={5} md={5} sm={5}>
                <Form.Item
                  name='address'
                  label='Address'
                  initialValue={CustomerDetails?.CustomerAddress}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Address",
                    },
                    { validator: validateAddress },
                  ]}>
                  <Input
                    type='text'
                    placeholder='Enter Address'
                    size='large'
                    onChange={handleAddressChange}
                  />
                </Form.Item>
              </Col>
              <Col lg={3} md={3} sm={3}>
                <Form.Item label=' '>
                  {visibleButton && id === null && (
                    <button
                      className='AddOfflineSales-addCustomer-btn'
                      type='primary'
                      htmlType='submit'>
                      Add
                    </button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>

        <AddProductOfflineSale
          setLoading={setLoading}
          setError={setError}
          error={error}
        />
      </div>
    </Spin>
  );
};

export default AddOfflineSale;
