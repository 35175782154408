import React, { useEffect, useState } from "react";
import CustomerCard from "./CustomersCard";
import "../Customers/Customers.css";
import CustomerListBody from "./CustomerListBody";
import { getApiData } from "../../api/apiService";
import { Spin, message } from "antd";
import useLogout from "../../utils/authUtils";

const Customers = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [customers, setCustomers] = useState([]);
  const [customerId, setcustomerId] = useState([]);
  const [filter, setFilter] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const [activeCount, setActiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const logout = useLogout();

  const fetchCustomerList = async () => {
    const endpoint = "getListofCoustmers";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(endpoint, true);
      console.log("Customer List fetched successfully:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setCustomers(data?.data);
        const activeCustomers = data?.data.filter(
          (customer) => customer.status === "active"
        );
        const inactiveCustomers = data?.data.filter(
          (customer) => customer.status === "inactive"
        );
        setTotalCount(data?.data?.length);
        setActiveCount(activeCustomers.length);
        setInactiveCount(inactiveCustomers.length);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Customer List fetching failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const filterCustomersBySearchInput = (customers, searchInput) => {
    if (!searchInput) return customers;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return customers.filter(
      (customer) =>
        customer?.name?.toLowerCase().includes(lowerCaseSearchInput) ||
        customer?.email?.toLowerCase().includes(lowerCaseSearchInput) ||
        customer?.phone?.includes(searchInput) ||
        customer?.address?.toLowerCase().includes(lowerCaseSearchInput)
    );
  };

  const filteredCustomers = filterCustomersBySearchInput(
    customers?.filter((customer) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Active") {
        return customer.status === "active";
      } else if (filter === "Inactive") {
        return customer.status === "inactive";
      }
    }),
    searchInput
  );

  useEffect(() => {
    const activeCustomers = filteredCustomers?.filter(
      (customer) => customer.status === "active"
    );
    const inactiveCustomers = filteredCustomers?.filter(
      (customer) => customer.status === "inactive"
    );
    setTotalCount(filteredCustomers?.length);
    setActiveCount(activeCustomers.length);
    setInactiveCount(inactiveCustomers.length);
  }, [searchInput]);

  return (
    <Spin spinning={loading}>
      <div>
        <CustomerCard
          fetchCustomerList={fetchCustomerList}
          customerId={customerId}
          setFilter={setFilter}
          filter={filter}
          customers={filteredCustomers}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          totalCount={totalCount}
        />
        <CustomerListBody
          customers={filteredCustomers}
          setcustomerId={setcustomerId}
          fetchCustomerList={fetchCustomerList}
          setLoading={setLoading}
          setError={setError}
        />
      </div>
    </Spin>
  );
};

export default Customers;
