import React, { useState } from "react";
import { BsFilter } from "react-icons/bs";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { ImPrinter } from "react-icons/im";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Dropdown, Checkbox, Menu, Button } from "antd";
import { useDispatch } from "react-redux";
import { OfflineAddCustomer, resetSelectedProducts } from "../../redux/action";
import * as XLSX from "xlsx";
// import { useReactToPrint } from "react-to-print";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

const OnlineSalesCard = ({
  searchInput,
  setSearchInput,
  onlineSalesData,
  checkedFilter,
  setCheckedFilter,
  fetchOfflineSalesList,
  filterDate,
  setFilterDate,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterVisible, setFilterVisible] = useState(false);

  const handleAddScaleClick = () => {
    dispatch(OfflineAddCustomer({}));
    dispatch(resetSelectedProducts());
    navigate("/add-onlineSale");
  };

  const onExportLocal = () => {
    // Check if the file has already been downloaded in this session
    if (localStorage.getItem("onlineSalesDataDownloaded")) {
      const downloadAgain = confirm(
        "The file has already been downloaded. Do you want to download it again?"
      );
      if (!downloadAgain) return; // Exit if the user doesn't want to re-download
    }

    const formattedData = onlineSalesData.map((item, index) => ({
      "S.No": index + 1,
      "Order ID": item.orderId,
      "Quantity (kg)": item.total_quantity,
      Price: item.total_price,
      "Executive Name": item.executiveName,
      Contact: item.shopkeeperContact,
      "Shop Keeper Details": item.shopkeeperName,
      Date: item.date,
      "Payment Status": item.paymentStatus,
      "Order Status": item.orderStatus,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "onlineSalesData");

    // Set column widths
    const wscols = [
      { wch: 5 }, // S.No
      { wch: 25 }, // Order ID
      { wch: 15 }, // Quantity (kg)
      { wch: 10 }, // Price
      { wch: 30 }, // Executive Name
      { wch: 25 }, // Contact
      { wch: 30 }, // Shop Keeper Details
      { wch: 15 }, // Date
      { wch: 15 }, // Payment Status
      { wch: 15 }, // Order Status
    ];
    worksheet["!cols"] = wscols;

    // Save the file
    XLSX.writeFile(workbook, "onlineSalesData.xlsx");

    // Set a flag in localStorage to mark the file as downloaded
    localStorage.setItem("onlineSalesDataDownloaded", "true");
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const printData = onlineSalesData
      .map(
        (item, index) => `
      <tr>
        <td>${index + 1}</td>
        <td>${item.orderId}</td>
        <td>${item.total_quantity}</td>
        <td>${item.total_price}</td>
        <td>${item.executiveName}</td>
        <td>${item.shopkeeperContact}</td>
        <td>${item.shopkeeperName}</td>
        <td>${item.date}</td>
        <td>${item.paymentStatus}</td>
        <td>${item.orderStatus}</td>
      </tr>
    `
      )
      .join("");

    printWindow.document.write(`
      <html>
      <head>
        <title>Online Sales Data</title>
        <style>
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
          td:nth-child(1), th:nth-child(1) { /* S.No column */
            width: 5%;
          }
          td:nth-child(2), th:nth-child(2) { /* Order ID column */
            width: 15%;
          }
          td:nth-child(3), th:nth-child(3) { /* Quantity (kg) column */
            width: 15%;
          }
          td:nth-child(4), th:nth-child(4) { /* Price column */
            width: 10%;
          }
          td:nth-child(5), th:nth-child(5) { /* Executive Name column */
            width: 15%;
          }
          td:nth-child(6), th:nth-child(6) { /* Contact column */
            width: 10%;
          }
          td:nth-child(7), th:nth-child(7) { /* Shop Keeper Details column */
            width: 15%;
          }
          td:nth-child(8), th:nth-child(8) { /* Date column */
            width: 10%;
          }
          td:nth-child(9), th:nth-child(9) { /* Payment Status column */
            width: 10%;
          }
          td:nth-child(10), th:nth-child(10) { /* Order Status column */
            width: 10%;
          }
        </style>
      </head>
      <body>
        <h1>Online Sales Data</h1>
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Order ID</th>
              <th>Quantity (kg)</th>
              <th>Price</th>
              <th>Executive Name</th>
              <th>Contact</th>
              <th>Shop Keeper Details</th>
              <th>Date</th>
              <th>Payment Status</th>
              <th>Order Status</th>
            </tr>
          </thead>
          <tbody>
            ${printData}
          </tbody>
        </table>
      </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  const handleFilterChange = (e) => {
    if (e.target.checked === true) {
      setCheckedFilter(e.target.name);
    } else {
      setCheckedFilter("");
    }
    console.log(e);
    setFilterVisible(false);
  };

  const onDateChange = (dates, dateStrings) => {
    console.log("Selected dates: ", dates);
    console.log("Formatted dates: ", dateStrings);
    setFilterDate(dateStrings);
    fetchOfflineSalesList(dateStrings);
  };

  const filterMenu = (
    <Menu>
      <Menu.Item key='1'>
        <Checkbox
          name='quantity'
          checked={checkedFilter === "quantity"}
          onChange={handleFilterChange}>
          Quantity
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='2'>
        <Checkbox
          name='paid'
          checked={checkedFilter === "paid"}
          onChange={handleFilterChange}>
          Paid
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='3'>
        <Checkbox
          name='credit'
          checked={checkedFilter === "credit"}
          onChange={handleFilterChange}>
          Credit
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='4'>
        <Checkbox
          name='price'
          checked={checkedFilter === "price"}
          onChange={handleFilterChange}>
          Price
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='5'>
        <Checkbox
          name='Inprogress'
          checked={checkedFilter === "Inprogress"}
          onChange={handleFilterChange}>
          In Progress
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='6'>
        <Checkbox
          name='Delivered'
          checked={checkedFilter === "Delivered"}
          onChange={handleFilterChange}>
          Delivered
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='7'>
        <Checkbox
          name='Packed'
          checked={checkedFilter === "Packed"}
          onChange={handleFilterChange}>
          Packing Completed
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='8'>
        <Checkbox
          name='Return'
          checked={checkedFilter === "Return"}
          onChange={handleFilterChange}>
          Return
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='9'>
        <Checkbox
          name='Damaged'
          checked={checkedFilter === "Damaged"}
          onChange={handleFilterChange}>
          Damaged
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='onlineSalesCard_header-container'>
      <div className='onlineSalesCard_left-side'>
        <div className='onlineSalesCard_search-bar'>
          <Input
            type='text'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder='Search by name'
            suffix={<SearchOutlined className='search-icon' />}
          />
        </div>
        <div className='onlineSalesCard_filter_b'>
          <Dropdown
            overlay={filterMenu}
            trigger={["click"]}
            visible={filterVisible}
            onVisibleChange={setFilterVisible}>
            <Button className='onlineSalesCard_filter-button capital'>
              <span> {checkedFilter ? checkedFilter : "Filter"} </span>
              <BsFilter size={17} className='onlineSalesCard_filter-icon' />
            </Button>
          </Dropdown>
        </div>
        <RangePicker
          style={{ width: "40%" }}
          format='YYYY-MM-DD'
          onChange={onDateChange}
        />
      </div>
      <div className='onlineSalesCard_right-side'>
        <div className='onlineSalesCard_PDF_B'>
          <button
            className='onlineSalesCard_pdf-button'
            onClick={onExportLocal}>
            <MdOutlinePictureAsPdf
              size={15}
              className='onlineSalesCard_pdf-icon'
            />
            Export to CSV
          </button>
        </div>
        {/* <div className='onlineSalesCard_Print_b'>
          <button className='online_print-button' onClick={handlePrint} >
            <ImPrinter size={15} className='onlineSalesCard_print-icon' />
            Print
          </button>
        </div> */}
        <div className='onlineSalesCard_Add_button'>
          <button
            className='onlineSalesCard_add-scale-button'
            onClick={handleAddScaleClick}>
            <FaPlus size={11} className='onlineSalesCard_plus-icon' />
            Add New Sale
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnlineSalesCard;
