import React, { useEffect } from "react";
import "./Header.css";
import { Row, Col, Dropdown } from "antd";
import { Avatar } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateTime } from "../../../redux/action";
import useLogout from "../../../utils/authUtils";

const Header = () => {
  const dispatch = useDispatch();
  const logout = useLogout();

  useEffect(() => {
    // Initial dispatch to set the current time
    dispatch(updateTime());

    const interval = setInterval(() => {
      dispatch(updateTime());
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);

  const selectedPuja = useSelector((state) => state.selectedPujas);
  console.log(selectedPuja);
  const profileDetails = useSelector((state) => state.userProfile);
  console.log(profileDetails);
  const timeDetails = useSelector((state) => state.time);
  console.log(timeDetails);

  const items = [
    {
      label: (
        <div
          className='logout-btn-container'
          onClick={logout}
          style={{ cursor: "pointer" }}>
          <button className='logout-btn'>Log out</button>
        </div>
      ),
      key: "1",
    },
  ];

  return (
    <header className='header'>
      <div className='header-content'>
        <h4>{selectedPuja}</h4>
        <div className='header-right-content'>
          <div className='name'>
            <h6>
              {profileDetails?.name ? profileDetails?.name : "Name not found"}
            </h6>
            <p>
              {timeDetails?.formattedLoginDate
                ? `${timeDetails.formattedLoginDate}, ${timeDetails.formattedLoginTime}`
                : "Date and Time not found"}
            </p>
          </div>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div className='avatar-container'>
              {profileDetails?.Image ? (
                <Avatar className='' src={profileDetails?.Image} size={50} />
              ) : (
                <Avatar
                  shape='circle'
                  size={40}
                  style={{
                    backgroundColor: "#6750A4",
                    fontWeight: 600,
                    fontSize: 17,
                  }}>
                  {profileDetails?.name?.length > 0 &&
                    profileDetails?.name[0]?.charAt(0)?.toUpperCase()}
                </Avatar>
              )}
            </div>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
