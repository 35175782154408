import React, { useState } from "react";
import "./login.css";
import { Button, Checkbox, Col, Form, Input, Row, Spin, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getApiData, postApiData } from "../../api/apiService";
import { useDispatch } from "react-redux";
import { updateToken, updatingUserProfile } from "../../redux/action";
import useLogout from "../../utils/authUtils";

const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = useLogout();

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    // window.location.reload();
    const registerEndpoint = "employeLogin";
    try {
      setLoading(true);
      const formattedEmail = values?.email?.toLowerCase();
      const userData = {
        email: formattedEmail,
        password: values.password,
      };
      const { data, loading: postLoading } = await postApiData(
        registerEndpoint,
        userData
      );
      console.log("User Login successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        form.resetFields();
        console.log(data?.user[0]?.token);
        const token = data?.user.length > 0 && data?.user[0]?.token;
        localStorage.setItem("jpf_token", token);
        dispatch(updateToken(token));
        localStorage.setItem("roleId", data?.user[0]?.role_Id);
        fetchUserProfile();
        if (data?.user[0]?.roleName === "Admin") {
          navigate("/dashboard");
        } else {
          navigate("/notification");
        }
        // window.location.reload();
        message.success(data.message);
      } else if (data.responseCode === 400) {
        console.log(data);
        message.error(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
      }
    } catch (error) {
      console.log("User Login failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserProfile = async () => {
    const Endpoint = "getUserProfile";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("User Details fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        const loginTime = new Date();
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const date = loginTime.getDate().toString().padStart(2, "0");
        const month = months[loginTime.getMonth()];
        const year = loginTime.getFullYear();
        const hours = loginTime.getHours().toString().padStart(2, "0");
        const minutes = loginTime.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "pm" : "am";
        const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
        const formattedLoginTime = `${date},${month} ${year}-${formattedHours}:${minutes} ${ampm}`;
        console.log(formattedLoginTime);
        const userData = {
          ...data?.user,
          formattedLoginTime: formattedLoginTime,
        };
        dispatch(updatingUserProfile(userData));
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("User Details fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    const passwordCriteria = [
      { regex: /[a-z]/, message: "at least one lowercase letter" },
      { regex: /[A-Z]/, message: "at least one uppercase letter" },
      { regex: /[0-9]/, message: "at least one digit" },
      { regex: /[\W_]/, message: "at least one special character" },
    ];
    for (const criterion of passwordCriteria) {
      if (!criterion.regex.test(value)) {
        return Promise.reject(`Password has contain ${criterion.message}`);
      }
    }
    return Promise.resolve();
  };

  return (
    <Spin spinning={loading}>
      <div className='login-container'>
        <div className='login-content'>
          <div className='login-left-section'>
            <div className="login-image-container">
              <img src='assets/images/login-image.png' />
            </div>
            <h3 className='white-color'>Welcome to JPF</h3>
          </div>
          <div className='login-right-section'>
            <div className='login-form-content'>
              <h5>Hello! JPF Welcomes You.</h5>
              <p className='login-text-color'>
                Please log in using the credentials provided to you by the JPF
                team
              </p>
              <div>
                <Form
                  form={form}
                  name='normal_login'
                  className='login-form'
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}>
                  <label>
                    <p style={{ marginBottom: "8px" }}>Email Address</p>
                  </label>

                  <Form.Item
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email address",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}>
                    <Input
                      size='large'
                      placeholder='Email Address'
                      allowClear
                      type='email'
                    />
                  </Form.Item>
                  <div className='forgot-section'>
                    <label>
                      <p>Password</p>
                    </label>
                    <p
                      className='forgot-password'
                      onClick={() => navigate("/forgotpassword")}>
                      Forgot password
                    </p>
                  </div>
                  <Form.Item
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                      {
                        min: 8,
                        message: "Password contain at least 8 characters",
                      },
                      {
                        max: 32,
                        message: "Password contain at most 32 characters",
                      },
                      { validator: validatePassword },
                    ]}>
                    <Input.Password
                      size='large'
                      placeholder='Password'
                      allowClear
                      type='password'
                    />
                  </Form.Item>
                  <Form.Item>
                    <button
                      type='primary'
                      htmlType='submit'
                      className='login-submit-button'>
                      <p>Login</p>
                    </button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Login;
