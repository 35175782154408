import React, { useEffect, useState } from "react";
import AddCategory from "./AddCategory";
import CategoryList from "./CategoryList";
import "./Categories.css";
import { Spin, message } from "antd";
import { getApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";

const Categories = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const logout = useLogout();

  const fetchCategoryList = async () => {
    const Endpoint = "listOfCategories";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("Category Details fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setCategoryData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Category Details fetching failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className='categories-container'>
        <div className='categories-content'>
          <AddCategory
            setLoading={setLoading}
            setError={setError}
            fetchCategoryList={fetchCategoryList}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
          />
          <div className='vertical-line'></div>
          <CategoryList
            setLoading={setLoading}
            setError={setError}
            categoryData={categoryData}
            fetchCategoryList={fetchCategoryList}
            setCategoryId={setCategoryId}
          />
        </div>
      </div>
    </Spin>
  );
};

export default Categories;
