import React, { useEffect, useState } from "react";
import EmployeeCard from "./EmployeeCard";
import "../Employees/Employee.css";
import EmployeeListBody from "./EmployeeListBody";
import { getApiData, postAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";
import { message } from "antd";

const Employees = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("All");
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rolesData, setRolesData] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const [CardData, setCardData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [reportsDate, setReportsDate] = useState([]);
  const logout = useLogout();

  const fetchAdminRolesData = async () => {
    const Endpoint = "listOfRoles";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("Features data fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setRolesData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Features data fetching failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployees = async (date) => {
    const Endpoint = "listOfEmployes";
    try {
      setLoading(true);
      const userData = {
        startDate: date?.length > 0 && date[0],
        endDate: date?.length > 0 && date[1],
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Sales Details fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        // setEmployeesData(data?.data);
        setEmployeesData(data?.data);
        const activeEmployee = data?.data.filter(
          (Employee) => Employee.status === "active"
        );
        const inactiveEmployee = data?.data.filter(
          (Employee) => Employee.status === "blocked"
        );
        setTotalCount(data?.data?.length);
        setActiveCount(activeEmployee.length);
        setInactiveCount(inactiveEmployee.length);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Sales Details fetching failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchcardsData = async (date) => {
  //   const Endpoint = "listOfEmployes";
  //   try {
  //     setLoading(true);
  //     const userData = {
  //       startDate: date?.length > 0 && date[0],
  //       endDate: date?.length > 0 && date[1],
  //     };
  //     const { data, loading: postLoading } = await postAuthApiData(
  //       Endpoint,
  //       userData
  //     );
  //     console.log("Sales Details fetched successful:", data);
  //     setLoading(postLoading);
  //     if (data.responseCode === 200) {
  //       console.log(data.message);
  //       setCardData(data?.data);
  //     } else if (data.responseCode === 400) {
  //       console.log(data);
  //       console.log(data.message);
  //     }
  //   } catch (error) {
  //     console.log("Sales Details fetching failed:", error);
  //     setLoading(false);
  //     setError(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    fetchAdminRolesData();
    fetchEmployees(reportsDate);
  }, []);

  const filterEmployeesBySearchInput = (employees, searchInput) => {
    if (!searchInput) return employees;
    const lowerCaseSearchInput = searchInput?.toLowerCase();
    return employees?.filter((employee) => {
      const lowerCaseAddress = String(employee?.address)?.toLowerCase();
      return (
        employee?.username?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        employee?.email?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        String(employee?.phoneNumber)?.includes(searchInput) ||
        lowerCaseAddress?.includes(lowerCaseSearchInput) ||
        employee?.roleName?.toLowerCase()?.includes(lowerCaseSearchInput)
      );
    });
  };

  const filteredEmployee = filterEmployeesBySearchInput(
    employeesData?.filter((Employee) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Active") {
        return Employee?.status === "active";
      } else if (filter === "Inactive") {
        return Employee?.status === "blocked";
      }
    }),
    searchInput
  );

  useEffect(() => {
    const activeEmployee = filteredEmployee?.filter(
      (Employee) => Employee.status === "active"
    );
    const inactiveEmployee = filteredEmployee?.filter(
      (Employee) => Employee.status === "blocked"
    );
    setTotalCount(filteredEmployee?.length);
    setActiveCount(activeEmployee.length);
    setInactiveCount(inactiveEmployee.length);
  }, [searchInput]);
  return (
    <div>
      <EmployeeCard
        rolesData={rolesData}
        filter={filter}
        setFilter={setFilter}
        activeCount={activeCount}
        inactiveCount={inactiveCount}
        employeesData={filteredEmployee}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setReportsDate={setReportsDate}
        reportsDate={reportsDate}
        fetchEmployees={fetchEmployees}
        totalCount={totalCount}
      />
      <EmployeeListBody
        employeesData={filteredEmployee}
        fetchEmployeeList={fetchEmployees}
        loading={loading}
        setLoading={setLoading}
        error={error}
        setError={setError}
        rolesData={rolesData}
      />
    </div>
  );
};

export default Employees;
