import React, { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup as LeafletPopup,
} from "react-leaflet";
import { Modal } from "antd";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const customIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png", // Replace with the path to your custom icon image
  iconSize: [32, 32], // Size of the icon
  iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -30], // Point from which the popup should open relative to the iconAnchor
});

const MapModal = ({ visible, onClose, latitude, longitude }) => {
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 0);
    }
  }, [visible]);

  return (
    <Modal
      title='Employee location'
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      centered
      bodyStyle={{ padding: 0 }}>
      <div style={{ height: "400px", width: "100%" }}>
        <MapContainer
          center={[latitude, longitude]}
          zoom={13}
          style={{ height: "100%", width: "100%" }}>
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution=''
          />
          <Marker position={[latitude, longitude]} icon={customIcon}>
            <LeafletPopup>
              Latitude: {latitude}, Longitude: {longitude}
            </LeafletPopup>
          </Marker>
        </MapContainer>
      </div>
    </Modal>
  );
};

export default MapModal;
