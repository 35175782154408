import React from "react";

const OnlineSaleSuccessPopup = () => {
  return (
    <div className='active-success-container'>
      <div className='active-success-content'>
        <div>
          <img src='assets/images/success_image.png' alt='' />
        </div>
        <h5>
          New Online Sales Added <br />
          Successfully
        </h5>
      </div>
    </div>
  );
};

export default OnlineSaleSuccessPopup;
