import React, { useEffect, useState } from "react";
import { Input, Form, message } from "antd";
import Image from "./Image.png";
import { postAuthApiData, putAuthApiData } from "../../api/apiService";
import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import useLogout from "../../utils/authUtils";
import { useDropzone } from "react-dropzone";
import { CloudUploadOutlined } from "@ant-design/icons";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";
import { ImCross } from "react-icons/im";

const AddCategory = ({
  setLoading,
  setError,
  fetchCategoryList,
  categoryId,
  setCategoryId,
}) => {
  const { TextArea } = Input;
  const [categoryImage, setCategoryImage] = useState("");
  const [updateCategoryId, setUpdateCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [inputError, setInputError] = useState("");
  const [form] = Form.useForm();
  const logout = useLogout();
  const [file, setFile] = useState(null);

  console.log(categoryId);

  useEffect(() => {
    if (categoryId) {
      fetchCategoryById();
    }
  }, [categoryId]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        if (file.size > 5242880) {
          message.error("File size exceeds the maximum limit of 5MB.");
          return;
        }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });

  const fetchCategoryById = async () => {
    const Endpoint = "getCategorieById";
    try {
      setLoading(true);
      const userData = {
        categoryId: categoryId,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Category By Id fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        form.setFieldsValue({
          name: data?.data?.name,
          Description: data?.data?.description,
        });
        setCategoryName(data?.data?.name);
        setCategoryImage(data?.data?.categoryImage);
        setFile(data?.data?.categoryImage);
        setUpdateCategoryId(data?.data?._id);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Category By Id fetched failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    console.log(values);
    if (updateCategoryId) {
      const Endpoint = "updateCategories";
      try {
        setLoading(true);
        const userData = {
          name: values.name,
          description: values.Description,
          categoryId: updateCategoryId,
          categoryImage: categoryImage,
        };
        const { data, loading: postLoading } = await putAuthApiData(
          Endpoint,
          userData
        );
        console.log("Category Updated successful:", data);
        setLoading(postLoading);
        if (data?.responseCode === 200) {
          console.log(data.message);
          fetchCategoryList();
          message.success(data?.message);
          setUpdateCategoryId("");
          setCategoryImage("");
          setFile(null);
          setCategoryId("");
          form.resetFields();
        } else if (data?.responseCode === 400) {
          message.error(data?.message);
          console.log(data?.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } catch (error) {
        console.log("Category Adding failed:", error);
        message.error(error?.error?.error?.message);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    } else {
      const Endpoint = "addcategorie";
      try {
        setLoading(true);
        const userData = {
          name: values.name,
          description: values.Description,
          categoryImage: categoryImage,
        };
        const { data, loading: postLoading } = await postAuthApiData(
          Endpoint,
          userData
        );
        console.log("Category Added successful:", data);
        setLoading(postLoading);
        if (data.responseCode === 200) {
          console.log(data.message);
          fetchCategoryList();
          form.resetFields();
          setCategoryImage("");
          setFile(null);
          message.success(data?.message);
        } else if (data.responseCode === 400) {
          console.log(data.message);
          message.error(data.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } catch (error) {
        console.log("Category Adding failed:", error);
        message.error(error?.error?.error?.message);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  let categoryWarningShown = false; // Flag to track if warning has been shown

  const validateCategory = (_, value) => {
    // Check if the category name starts with a letter
    if (value && !/^[a-zA-Z]/.test(value)) {
      if (!categoryWarningShown) {
        message.warning("Category must start with a letter");
        categoryWarningShown = true; // Set flag after showing the warning
      }
      return Promise.reject(new Error("Category must start with a letter"));
    }

    categoryWarningShown = false;

    // Check if the category name contains only letters, numbers, and spaces
    if (value && !/^[a-zA-Z][a-zA-Z0-9\s]*$/.test(value)) {
      if (!categoryWarningShown) {
        message.warning(
          "Category must contain only letters, numbers, and spaces"
        );
        categoryWarningShown = true; // Set flag after showing the warning
      }
      return Promise.reject(
        new Error("Category must contain only letters, numbers, and spaces")
      );
    }

    // Reset the warning flag if input becomes valid
    categoryWarningShown = false;

    // Check if the length is less than 5 characters
    if (value && value.length < 2) {
      return Promise.reject(
        new Error("Category must be at least 2 characters")
      );
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(
        new Error("Category must be at most 16 characters")
      );
    }

    return Promise.resolve();
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 17);

    // Prevent category from starting with a number or space
    if (/^[0-9\s]/.test(cleanedValue)) {
      form.setFieldsValue({ name: cleanedValue.slice(1) });
    } else {
      form.setFieldsValue({ name: cleanedValue });
    } 
  };

  return (
    <div className='addcategory-content'>
      <div>
        <Form
          form={form}
          className='addcategory-form'
          layout='vertical'
          autoComplete='off'
          onFinish={onFinish}>
          <Form.Item
            name='name'
            label='Add Category'
            rules={[
              {
                required: true,
                message: "Please enter category name",
              },
              { validator: validateCategory },
            ]}>
            <Input
              placeholder='Name of the Category'
              onChange={handleCategoryChange}
            />
          </Form.Item>
          <Form.Item
            name='Description'
            label='Description'
            rules={[
              {
                required: false,
                message: "Please enter description",
              },
            ]}>
            <TextArea
              rows={4}
              placeholder='Description'
              maxLength={200}
              showCount
            />
          </Form.Item>
          {/* {categoryImage ? (
            <div className='FileChange'>
              <div className='image-section'>
                <img src={categoryImage} />
              </div>
              <p className='pointer'>
                <label htmlFor='fileInput' className='primary-text pointer'>
                  Change Image
                </label>
                <div className='document-attach'>
                  <input
                    type='file'
                    id='fileInput'
                    onChange={handleAadharImage}
                    style={{ display: "none" }}
                  />
                </div>
              </p>
            </div>
          ) : (
            <div className='FileUpload'>
              <img src={Image} className='file-upload' />
              <p>
                Drop your files here or{" "}
                <label htmlFor='fileInput' className='primary-text pointer'>
                  browse
                </label>
                <div className='document-attach'>
                  <input
                    type='file'
                    id='fileInput'
                    onChange={handleAadharImage}
                    style={{ display: "none" }}
                  />
                </div>
              </p>
              <p className='secondary-text'>Maximum size: 5MB</p>
            </div>
          )} */}
          <div {...getRootProps()} class='upload-container'>
            <input {...getInputProps()} />
            {file && categoryImage ? (
              <div className='uploaded-image-section'>
                <div className='fileUpload-image-div'>
                  <img src={file.preview || categoryImage} alt={file.name} />
                </div>
                <ImCross
                  className='text-md font-light cursor-pointer'
                  onClick={() => {
                    setFile(null);
                    setCategoryImage("");
                  }}
                />
              </div>
            ) : (
              <CloudUploadOutlined className='cloud-icon' />
            )}
            <p class='upload-text'>
              Browse/drag to {file && categoryImage ? "change" : "upload"} File
            </p>
            <p class='file-size-text'>File Size must be below 5 Mb</p>
          </div>
          <Form.Item>
            <div className='Addcategory-submit'>
              <button
                type='primary'
                htmlType='submit'
                className='Addcategory-submit-button pointer'>
                {updateCategoryId ? (
                  <p>Update Category</p>
                ) : (
                  <p>Add Category</p>
                )}
              </button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddCategory;
