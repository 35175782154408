import { Avatar, Input, Select, Modal, Spin, message } from "antd";
import React, { useState } from "react";
import { IoFilter, IoSearchOutline } from "react-icons/io5";
import Executive_avatar from "./Executive_avatar.png";
import { SearchOutlined } from "@ant-design/icons";
import { postAuthApiData } from "../../api/apiService";
import { DatePicker, Space } from "antd";
import useLogout from "../../utils/authUtils";
import { Pagination } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const DailyReports = ({
  reportsData,
  fetchReportsData,
  dailyReportsDate,
  setDailyReportsDate,
  apiData,
  setCurrentPage,
  searchInput,
  setSearchInput,
  currentPage,
}) => {
  const [statusChange, setStatusChange] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [orderId, setOdrerId] = useState("");
  const logout = useLogout();
  const pageSize = 10;

  const handleChangePage = (page) => {
    setCurrentPage(page);
    console.log(page);
    fetchReportsData(dailyReportsDate, page, searchInput);
  };

  console.log(apiData?.totalPages);

  console.log(reportsData);

  // const filteredPandits = reportsData?.filter(
  //   (item) =>
  //     item?.executiveName
  //       ?.toLowerCase()
  //       ?.includes(searchInput?.toLowerCase()) ||
  //     item?.shopkeeperName
  //       ?.toLowerCase()
  //       ?.includes(searchInput?.toLowerCase()) ||
  //     item?.customerName?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
  //     item?.orderStatus?.toLowerCase()?.includes(searchInput?.toLowerCase())
  // );

  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = startIndex + pageSize;
  // const paginatedData = filteredPandits?.slice(startIndex, endIndex);
  // console.log(paginatedData);

  const { Option } = Select;

  const getBackgroundColor = (status) => {
    switch (status) {
      case "Delivered":
        return "#14B8A6";
      case "Packing Completed":
        return "#14B8A6";
      case "InProgress":
        return "#FFC727";
      case "Return":
        return "#EF4444";
      case "Damaged":
        return "#EF4444";
      default:
        return "white";
    }
  };

  const handleStatusChange = (value, id) => {
    console.log(value, id);
    setOdrerId(id);
    setSelectedStatus(value);
    setStatusChange(true);
  };

  const statusUpdate = async () => {
    const Endpoint = "OnlineOrderStatus";
    try {
      setLoading(true);
      const userData = { orderBookingId: orderId, newStatus: selectedStatus };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Order Status Changed successful:", data);
      setLoading(postLoading);
      if (data?.responseCode === 200) {
        console.log(data.message);
        message.success("Status Changed Successfully");
        fetchReportsData();
        setStatusChange(false);
      } else if (data?.responseCode === 400) {
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Order Status Changing failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const onDateChange = (dates, dateStrings) => {
    console.log("Selected dates: ", dates);
    console.log("Formatted dates: ", dateStrings);
    setDailyReportsDate(dateStrings);
    fetchReportsData(dateStrings, currentPage, searchInput);
  };

  return (
    <div className='daily-reports'>
      <div className='daily-reports-header'>
        <h5>Daily Reports</h5>
        <div className='reports-header-right'>
          <Input
            type='text'
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              fetchReportsData(dailyReportsDate, currentPage, e.target.value);
            }}
            suffix={<SearchOutlined className='search-icon' />}
            placeholder='Search Report'
            className='customerCard_search-input'
          />
          {/* <RangePicker
            style={{ width: "100%" }}
            format='YYYY-MM-DD'
            onChange={onDateChange}
          /> */}
        </div>
      </div>
      <div class='dashboard-table-container'>
        <table>
          <thead>
            <tr>
              <th className='executive'>Executive Name</th>
              {/* <th className='quantity'>Quantity/Pouches</th> */}
              <th className='quantity'>Units/Packets</th>
              <th className='price'>Price ₹</th>
              <th className='date'>Date</th>
              <th className='shopkeeper'>Shop Keeper Name</th>
              <th className='order'>Order Status</th>
            </tr>
          </thead>
          {reportsData?.length > 0 ? (
            <tbody>
              {reportsData?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className='executive-section capital'>
                      {item?.image ? (
                        <Avatar src={item?.image} size={40} />
                      ) : (
                        <Avatar
                          shape='circle'
                          size={40}
                          style={{
                            backgroundColor: "#6750A4",
                            fontWeight: 600,
                            fontSize: 17,
                          }}>
                          {item?.executiveName?.length > 0 &&
                            item?.executiveName[0]?.charAt(0)?.toUpperCase()}
                        </Avatar>
                      )}
                      {item?.executiveName || " - "}
                    </div>
                  </td>
                  <td>
                    <div className='quantity-section capital'>
                      {item?.totalQuantity === 0
                        ? `${item?.totalPackets} Pkt`
                        : `${item?.totalQuantity?.toFixed(
                            2
                          )} Kg [${item?.totalPackets?.toFixed(1)} Pkt]`}

                      {/* {`${item?.totalPackets} Pkt`} */}

                      <small
                        className={`online-status ${
                          item?.source === "online"
                            ? "online-status"
                            : "offline-status"
                        }`}>
                        {item?.source}
                      </small>
                    </div>
                  </td>
                  <td>{item?.totalAmount && item?.totalAmount}</td>
                  <td>
                    {item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"}
                  </td>
                  <td className='capital'>
                    {item?.shopkeeperName
                      ? item?.shopkeeperName
                      : item?.customerName}
                  </td>

                  {/* <td>
                    <div
                      style={{
                        borderRadius: "20px",
                        color: "white",
                        width: 120,
                        backgroundColor: getBackgroundColor(item?.orderStatus),
                        borderRadius: "16px",
                      }}>
                      <span className='orderStatus-class'>
                        {item?.orderStatus}
                      </span>
                    </div>
                  </td> */}

                  <td>
                    <div
                      className='capital order-status'
                      style={{
                        backgroundColor: getBackgroundColor(item?.orderStatus),
                      }}>
                      {item?.orderStatus}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            ""
          )}
        </table>

        {reportsData?.length === 0 ? (
          <div className='no-data-message'>
            <p>No data found</p>
          </div>
        ) : (
          ""
        )}
        <div className='Pagination-container'>
          <Pagination
            simple
            total={apiData?.orderCount}
            onChange={handleChangePage}
          />
        </div>
      </div>
      {/* <div className='view-all'>
        <p>View All</p>
      </div> */}
      <Modal
        visible={statusChange}
        onCancel={() => setStatusChange(false)}
        centered
        footer={null}
        className='custom-modal'>
        <Spin spinning={loading}>
          <div className='role-active-container'>
            <div className='role-active-content'>
              <h5 style={{ textAlign: "center" }}>
                Are you want to Change the Status of this Sale
              </h5>
              <div className='role-active-actions'>
                <button
                  className='role-active-no'
                  onClick={() => setStatusChange(false)}>
                  <p>No</p>
                </button>
                <button
                  className='role-active-delete'
                  onClick={() => statusUpdate()}>
                  <p>Yes</p>
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default DailyReports;
