import React from "react";
import App from "./App";
import { Routes, Route } from "react-router-dom";
import Login from "./containers/Login";
import ForgotPassword from "./containers/ForgetPassword";
import { useSelector } from "react-redux";
import NewPassword from "./containers/New Password";

const NextApp = () => {
  const token = useSelector((state) => state.token.token);
  console.log(token);
  return (
    <div>
      {token === null ? (
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/newpassword' element={<NewPassword />} />
        </Routes>
      ) : (
        <App />
      )}
    </div>
  );
};

export default NextApp;
