import React, { useState, useEffect } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { VscCheck, VscCircleFilled } from "react-icons/vsc";
import { Modal, Pagination, Spin } from "antd";
import moment from "moment";

const HistoryTable = ({ shopKeeperData, fetchSingleShopKeeper }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const pageSize = 10;

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [shopKeeperData]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = shopKeeperData?.slice(startIndex, endIndex);

  console.log(shopKeeperData);

  const getBackgroundColor = (status) => {
    switch (status) {
      case "Delivered":
        return "#14B8A6";
      case "Packing Completed":
        return "#14B8A6";
      case "InProgress":
        return "#FFC727";
      case "Return":
        return "#EF4444";
      case "Damaged":
        return "#EF4444";
      default:
        return "white";
    }
  };
  const handleInvoice = async () => {
    await setInvoiceModal(true);
  };

  return (
    <>
      <div className='HistoryTable_div'>
        <table className='HistoryTable_table'>
          <thead className='HistoryTable_thead'>
            <tr className='HistoryTable_tableHead-row'>
              <th className='HistoryTable_Serial-No'>S.No</th>
              <th className='HistoryTable_Date'>Date</th>
              <th className='HistoryTable_BookingId'>Booking ID</th>
              {/* <th className='HistoryTable_Quantity'>Quantity</th> */}
              <th className='HistoryTable_NumPackets'>Number of Packets</th>
              <th className='HistoryTable_Price'>Price</th>
              <th className='HistoryTable_payment-Status'>Payment Status</th>
              <th className='HistoryTable_Order-Status'>Order Status</th>
              <th className='HistoryTable_Invoice'>Invoice</th>
            </tr>
          </thead>
          <tbody className='HistoryTable_tbody'>
            {paginatedData?.length > 0
              ? paginatedData?.map((item, index) => (
                  <tr className='HistoryTable_tableData-row' key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {" "}
                      {item?.Date
                        ? moment(item.Date).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                    <td>{item?.bookingId}</td>
                    {/* <td>{item?.quantity?.toFixed(1)}</td> */}
                    <td>
                      {item?.quantity !== 0
                        ? `${item?.quantity?.toFixed(1)} Kg [${
                            item?.numberOfProducts
                          } Pkt]`
                        : `${item?.numberOfProducts} Pkt`}
                    </td>
                    <td>{item?.price?.toFixed(2)}</td>
                    <td
                      className={
                        item?.paymentStatus === "paid" ? "paid" : "credit"
                      }>
                      <div className='HistoryTable_Paymentstatus capital'>
                        {item?.paymentStatus}
                        {item?.paymentStatus === "paid" && (
                          <VscCheck
                            className='HistoryTable_Paid-Icon'
                            size={12}
                          />
                        )}
                        {item?.paymentStatus === "credit" && (
                          <VscCheck
                            className='HistoryTable_Paid-Icon'
                            size={12}
                          />
                        )}
                      </div>
                    </td>
                    <td className='HistoryTable_OrderStatus'>
                      <div
                        className='capital order-status'
                        style={{
                          backgroundColor: getBackgroundColor(
                            item?.orderStatus
                          ),
                        }}>
                        {item.orderStatus}
                      </div>
                    </td>
                    <td>
                      <IoEyeOutline
                        size={18}
                        onClick={() => {
                          setInvoiceUrl(item?.invoice);
                          handleInvoice();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {shopKeeperData?.length === 0 ? (
          <div className='no-data-message'>
            <h6>No data found</h6>
          </div>
        ) : (
          ""
        )}

        <Modal
          visible={invoiceModal}
          onCancel={() => setInvoiceModal(false)}
          closeIcon={false}
          centered
          footer={null}>
          <div className='invoice-container'>
            <div>
              <iframe src={invoiceUrl} width='100%' height='550px' />
            </div>
            <div style={{ textAlign: "end" }}>
              <button
                onClick={() => {
                  setInvoiceModal(false);
                  setInvoiceUrl("");
                }}
                className='close-btn'>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div className='HistoryTable_div-Pagination-container'>
        {shopKeeperData?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={shopKeeperData?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </>
  );
};

export default HistoryTable;
