import {
  Table,
  Form,
  Input,
  message,
  Spin,
  Checkbox,
  Button,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { postAuthApiData } from "../../api/apiService";
import "./ReturnModel.css";
import useLogout from "../../utils/authUtils";

const ReturnModel = ({
  orderId,
  setOdrerId,
  setReturnModel,
  selectedStatus,
  statusUpdate,
  productsData,
  loading,
  setLoading,
}) => {
  const [reason, setReason] = useState("");
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [totalFinalPrice, setTotalFinalPrice] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [form] = Form.useForm();
  const logout = useLogout();

  console.log(loading);

  const bookingId =
    productsData?.length > 0 && productsData[0]?.newOrderBookingId;

  useEffect(() => {
    // Calculate the total final price for checked items
    const total = checkedList.reduce((sum, itemId) => {
      const item = productsData.find((product) => product._id === itemId);
      return sum + (item ? item.final_price : 0);
    }, 0);
    setTotalFinalPrice(total);
  }, [checkedList, productsData]);

  const checkAll = productsData?.length === checkedList?.length;
  const indeterminate =
    checkedList?.length > 0 && checkedList?.length < productsData?.length;

  const onChange = (itemId) => {
    setCheckedList((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId]
    );
  };

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked ? productsData?.map((item) => item._id) : []
    );
  };

  const handleOk = () => {
    // Handle the confirm logic here
    console.log("Confirmed reason:", reason);
    setReturnModel(false);
  };

  console.log(totalFinalPrice);
  console.log(selectedStatus);
  console.log(checkedList);

  const onFinish = async (values) => {
    console.log(values);
    const Endpoint = "WebReturnProudctInfos";
    if (checkedList?.length > 0) {
      try {
        setLoading(true);
        const userData = {
          newOrderBookingId: bookingId,
          productinfos: checkedList,
          //   totalAmount: totalFinalPrice,
          isDamaged: selectedStatus === "Return" ? false : true,
          returnReason: values.reason,
        };
        const { data, loading: postLoading } = await postAuthApiData(
          Endpoint,
          userData
        );
        console.log("Returning products fetched successful:", data);
        setLoading(postLoading);
        if (data.responseCode === 200) {
          console.log(data.message);
          statusUpdate();
          setReturnModel(false);
          form.resetFields();
          setCheckedList([]);
          setSuccessModal(true);
          setOdrerId("");
          //   message.success(data?.message);
        } else if (data.responseCode === 400) {
          console.log(data);
          console.log(data?.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } catch (error) {
        console.log("Returning products fetching failed:", error);
        message.error(error);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    } else {
      message.warning("Please Select atleast one product");
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className='return-container'>
          <div className='return-content'>
            <div className='return-left-container'>
              <h6>Ordered</h6>
              <div className='return-list-table'>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                          indeterminate={indeterminate}
                          onChange={onCheckAllChange}
                          checked={checkAll}></Checkbox>
                      </th>
                      <th>S.No</th>
                      {/* <th>Order Id</th> */}
                      <th>Product Name</th>
                      <th>SKU</th>
                      <th>Price</th>
                      <th>Packets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsData?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Checkbox
                            checked={checkedList?.includes(item._id)}
                            onChange={() => onChange(item._id)}
                          />
                        </td>
                        <td>{index + 1}</td>
                        {/* <td>{item?.bookingId}</td> */}
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.productName}
                        </td>
                        <td>
                          {item?.productStream === "Qty"
                            ? `${item?.quantityValue} ${item?.quantityType}`
                            : `${item?.piecesInaPack} pouches/pack @${item?.singlePiecePrice}`}
                        </td>
                        <td>{item?.salePrice}</td>
                        <td style={{ textAlign: "center" }}>
                          {item?.packets?.toFixed(1)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='separator'>
              <p></p>
            </div>
            <div className='return-right-container'>
              <h6 className='red'>
                {selectedStatus === "Return" ? "Returned" : "Damaged"}
              </h6>
              <div className='return-list-table'>
                <table>
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>S.No</th>
                      {/* <th>Order Id</th> */}
                      <th>Product Name</th>
                      <th>SKU</th>
                      <th>Price</th>
                      <th>Packets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkedList?.map((bookingId, index) => {
                      const item = productsData.find(
                        (product) => product._id === bookingId
                      );
                      return (
                        <tr key={index}>
                          {/* <td></td> */}
                          <td>{index + 1}</td>
                          {/* <td>{item?.bookingId}</td> */}
                          <td>{item?.productName}</td>
                          <td>
                            {item?.productStream === "Qty"
                              ? `${item?.quantityValue} ${item?.quantityType}`
                              : `${item?.piecesInaPack} pouches/pack @${item?.singlePiecePrice}`}
                          </td>
                          <td>{item?.salePrice}</td>
                          <td style={{ textAlign: "center" }}>
                            {item?.packets?.toFixed(1)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Form
                form={form}
                className='addofflinseSales-form'
                layout='vertical'
                autoComplete='off'
                style={{ marginTop: "1rem" }}
                onFinish={onFinish}>
                <Form.Item
                  name='reason'
                  label='Add Reason'
                  rules={[
                    {
                      required: true,
                      message: "Please enter reason",
                    },
                  ]}>
                  <Input.TextArea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    maxLength={75}
                    showCount
                    placeholder='Add Reason for this'
                  />
                </Form.Item>
                <Form.Item>
                  {/* <div>Total Final Price: {totalFinalPrice}</div> */}
                  <div className='submit-btn-section'>
                    <button
                      style={{ cursor: "pointer" }}
                      type='primary'
                      htmlType='submit'
                      className='submit-btn'>
                      Confirm
                    </button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
          <Modal
            visible={successModal}
            onCancel={() => setSuccessModal(false)}
            centered
            footer={null}
            className='custom-modal'>
            <div className='active-success-container'>
              <div className='active-success-content'>
                <div>
                  <img src='assets/images/success_image.png' alt='' />
                </div>
                <h5>
                  Product Updated <br />
                  Successfully
                </h5>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>
    </>
  );
};

export default ReturnModel;
