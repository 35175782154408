import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Cards from "./Cards";
import profileimage from "./Profile.png";
import DailyReports from "./DailyReports";
import { getApiData, postAuthApiData } from "../../api/apiService";
import { message, Spin } from "antd";
import useLogout from "../../utils/authUtils";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [reportsDate, setReportsDate] = useState([]);
  const [dailyReportsDate, setDailyReportsDate] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiData, setApiData] = useState({});
  const logout = useLogout();
  const [searchInput, setSearchInput] = useState("");

  const fetchReportsData = async (date, page, keyword) => {
    const Endpoint = "combinedDashboardAPI";
    try {
      setLoading(true);
      const userData = {
        startDate: date?.length > 0 && date[0],
        endDate: date?.length > 0 && date[1],
        limit: 10,
        page: page,
        userName: keyword,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Sales Details fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setReportsData(data?.data?.orderList);
        setSalesData(data?.data?.counts);
        setApiData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Sales Details fetching failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReportsData(dailyReportsDate, currentPage, searchInput);
  }, []);
  console.log(reportsData);
  console.log(dailyReportsDate);

  return (
    <Spin spinning={loading}>
      <div className='dashboard-container'>
        <Cards
          salesData={salesData}
          setReportsDate={setDailyReportsDate}
          reportsDate={dailyReportsDate}
          fetchReportCardsData={fetchReportsData}
          apiData={apiData}
          currentPage={currentPage}
        />
        <DailyReports
          reportsData={reportsData}
          fetchReportsData={fetchReportsData}
          dailyReportsDate={dailyReportsDate}
          setDailyReportsDate={setDailyReportsDate}
          setCurrentPage={setCurrentPage}
          apiData={apiData}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          currentPage={currentPage}
        />
      </div>
    </Spin>
  );
};

export default Dashboard;
