import React, { useEffect, useState } from "react";
import "./CustomerBookingHis.css";
import CustomerBookingProfileCard from "./CustomerBookingCard";
import CustomerBookingProfile from "./CustomerBookingProfile";
import CustomerHistoryTable from "./CustomerBookingHisTable";
import { useLocation } from "react-router-dom";
import { postAuthApiData } from "../../api/apiService";
import { message, Spin } from "antd";
import useLogout from "../../utils/authUtils";

const CustomerBookingHistory = () => {
  const location = useLocation();
  console.log(location.state);
  const id = location?.state?.id;
  const details = location?.state?.item;
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [error, setError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState("All");
  const [checkedFilter, setCheckedFilter] = useState("");
  const logout = useLogout();

  useEffect(() => {
    fetchCustomer();
  }, []);

  const fetchCustomer = async () => {
    const Endpoint = "CustomerHistory";
    try {
      setLoading(true);
      const userData = { customerId: id };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Single Customer Details fetched successful:", data);
      setLoading(postLoading);
      if (data?.responseCode === 200) {
        console.log(data.message);
        setCustomerData(data?.data?.orders);
      } else if (data?.responseCode === 400) {
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Single Customer Details fetched failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(customerData);

  const filterShopkeepersBySearchInput = (shopkeepers, searchInput) => {
    if (!searchInput) return shopkeepers;

    const lowerCaseSearchInput = searchInput
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, ""); // Remove special characters

    return shopkeepers.filter((shopkeeper) => {
      // Normalize orderId by removing non-alphanumeric characters for comparison
      const normalizedOrderId = shopkeeper?.bookingId
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, "");

      const orderIdMatch = normalizedOrderId?.includes(lowerCaseSearchInput); // Match order ID

      const priceMatch = String(shopkeeper?.price).includes(searchInput); // Convert price to string

      const paymentStatusMatch = shopkeeper?.paymentStatus
        ?.toLowerCase()
        .includes(lowerCaseSearchInput); // Match payment status

      const orderStatusMatch = shopkeeper?.orderStatus
        ?.toLowerCase()
        .includes(lowerCaseSearchInput); // Match order status

      return (
        orderIdMatch || priceMatch || paymentStatusMatch || orderStatusMatch
      );
    });
  };

  const filterAndSortShopkeepers = () => {
    let filteredData = customerData?.filter((shopkeeper) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Active") {
        return shopkeeper?.status === "active";
      } else if (filter === "Inactive") {
        return shopkeeper?.status === "inactive";
      }
    });

    filteredData = filterShopkeepersBySearchInput(filteredData, searchInput);

    if (checkedFilter === "quantity") {
      filteredData = filteredData?.sort((a, b) => a.quantity - b.quantity);
    } else if (checkedFilter === "paid") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.paymentStatus === "paid"
      );
    } else if (checkedFilter === "credit") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.paymentStatus === "credit"
      );
    } else if (checkedFilter === "Inprogress") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "InProgress"
      );
    } else if (checkedFilter === "Packed") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Packing Completed"
      );
    } else if (checkedFilter === "Delivered") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Delivered"
      );
    } else if (checkedFilter === "Damaged") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Damaged"
      );
    } else if (checkedFilter === "Return") {
      filteredData = filteredData.filter(
        (shopkeeper) => shopkeeper.orderStatus === "Return"
      );
    }
    console.log(filteredData);

    return filteredData;
  };

  const filteredShopkeeper = filterAndSortShopkeepers();
  console.log(filteredShopkeeper);

  return (
    <Spin spinning={loading}>
      <div>
        <CustomerBookingProfile details={details} />
        <CustomerBookingProfileCard
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          checkedFilter={checkedFilter}
          setCheckedFilter={setCheckedFilter}
        />
        <CustomerHistoryTable
          customerData={filteredShopkeeper}
          fetchCustomer={fetchCustomer}
        />
      </div>
    </Spin>
  );
};
// CustomerBookingHistory
export default CustomerBookingHistory;
